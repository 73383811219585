export default Object.freeze({
  "green-dark": "#31655A",
  "green-blue": "#009BA4",

  green: {
    base: "#1AC8AA",
    darken1: "#0C994A",
  },
  "blue-dark": "#003766",
  blue: {
    base: "#02549C",
    lighten1: "#0383B4",
    lighten2: "#05B1CC",
    neon: "#06DCE3",
  },
  creamy: {
    base: "#fafafa",
    darken1: "#F2F2F2",
  },

  grey: "#DEDEDE",
  "grey-dark": "#798190",
  "black-light": "#424242",
  "black-dark": "#303030",
  "oxford-blue": "#313949",

  background: "#F5F5F5",
  whitesmoke: "#F5F5F5",
  "ice-blue": "#eef2f6",
  "marine-blue": "#00305b",
  "dark-indigo": "#081124",
  steel: "#798190",
  "very-light-pink": "#fbfafa",
  // Examples
  // primary: '#ee44aa',
  // secondary: '#424242',
  // accent: '#82B1FF',
  // error: '#FF5252',
  // info: '#2196F3',
  // success: '#4CAF50',
  // warning: '#FFC107'
})
