<script>
import CompanyLogic from "@/logics/CompanyLogic"

export default {
  name: "company-customization-edition",
  props: {
    data: {
      default: null,
    },
  },
  data: function() {
    return {
      localData: null,
      appJSONCustomization: null,
      learningJSONCustomization: null,
    }
  },
  async mounted() {
    await this.init()
  },
  watch: {
    data(newValue, oldValue) {
      this.init()
    },
  },
  methods: {
    async init() {
      this.localData = JSON.parse(JSON.stringify(this.data))

      if (!this.localData.customization) {
        this.localData.customization = {}
      }

      await this.getCompanyCustomization()
    },
    async getCompanyCustomization() {

      if (!this.localData.uuid) {
        return
      }

      this.localData.customization = await CompanyLogic.getCustomization(
        this.localData.uuid,
      )

      if (!this.localData.customization["app"] || this.localData.customization["app"].length === 0) {
        this.localData.customization["app"] = {}
      }

      if (!this.localData.customization["learning"] || this.localData.customization["learning"].length === 0) {
        this.localData.customization["learning"] = {}
      }

      this.appJSONCustomization = JSON.stringify(this.localData.customization.app, null, 2)
      this.learningJSONCustomization = JSON.stringify(this.localData.customization.learning, null, 2)
    },

    async resetCustomizationJson(type) {
      let data = await CompanyLogic.getCustomization(null, false)

       //this.localData.customization[type] = data[type]
      //this.$set(this.localData.customization, type, data[type]);
      //JSON.stringify(localData.customization.app, null, 2)

      if (type == "app") {
        console.log("app values reseted");
        this.appJSONCustomization = null
        this.appJSONCustomization = JSON.stringify(this.localData.customization.app, null, 2)
        console.log("appJSONCustomization", this.appJSONCustomization)
      } else if (type == "learning") {
        console.log("learning values reseted");
        this.learningJSONCustomization = null
        this.learningJSONCustomization = JSON.stringify(this.localData.customization.learning, null, 2)
      }
    },

    updateCustomization(event, type) {
      console.log("updateCustomization for " . type)
      console.log(event)
      if(type == "app") {
        console.error(this.appJSONCustomization)
        this.localData.customization["app"] = JSON.parse(event);
      } else if(type == "learning") {
        console.error(this.learningJSONCustomization)
        this.localData.customization["app"] = JSON.parse(event);
      }
    },
    async validate() {
      return true
    },
    getCompanyData() {
      return {
        customization: this.localData.customization,
      }
    },
  },
}
</script>

<template>
  <!-- Customization APP / LEARNING -->
  <div class="flex-container" v-if="localData && localData.uuid">
    <div class="bloc text-center">
      <v-row>
        <v-col cols="12">
          <div class="bloc-title">
            {{ $t("Customization FRONT") | titlecase }}
          </div>
        </v-col>

        <v-col cols="12">
          <div>
            <v-card-text>
              <v-textarea
                :label="$t('Customization in JSON format')"
                :value="appJSONCustomization"
                @input="updateCustomization($event, 'app')"
                :rows="18"
              ></v-textarea>

              <v-btn
                elevation="2"
                @click="resetCustomizationJson('app')"
                dark
                color="orange"
                class="text-none rounded-lg"
              >{{ $t("Reset to default values") }}
              </v-btn>
            </v-card-text>
          </div>
        </v-col>
      </v-row>
    </div>

    <div class="bloc text-center">
      <v-row>
        <v-col cols="12">
          <div class="bloc-title">
            {{ $t("Customization LEARNING") | titlecase }}
          </div>
        </v-col>

        <v-col cols="12">
          <div>
            <v-card-text>
              <v-textarea
                :label="$t('Customization in JSON format')"
                :value="JSON.stringify(localData.customization.learning, null, 2)"
                @input="updateCustomization($event, 'learning')"
                :rows="18"
              ></v-textarea>

              <v-btn
                elevation="2"
                @click="resetCustomizationJson('learning')"
                dark
                color="orange"
                class="text-none rounded-lg"
              >{{ $t("Reset to default values") }}
              </v-btn>
            </v-card-text>
          </div>
        </v-col>
      </v-row>
    </div>
  </div>
</template>


<style scoped>
.flex-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}


.bloc {
  border: 1px solid #efefef;
  border-radius: 5px;
  flex: 1 1 auto;
  width: 250px;
  padding: 20px;
  margin: 10px;

  .bloc-title {
    font-weight: bold;
    text-align: center;
    font-size: 1.5em;
    color: #2a2929;
  }
}
</style>
