<template>
  <div v-if="localData">
            <v-card class="mt-4" outlined>
          <v-card-title>{{
            $t("announcement.description_syntax")
          }}</v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="12" md="6">
                <v-list dense>
                  <v-list-item>
                    <v-list-item-avatar>
                      <v-icon color="red">mdi-palette</v-icon>
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title>
                        <strong>{"en":"Annonce EN","fr":"Annonce FR"}</strong>
                      </v-list-item-title>
                      <v-list-item-subtitle>{{
                        $t("announcement.exemple")
                      }}</v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-btn
                        icon
                        @click='copyToClipboard("{\"en\":\"Annonce EN\",\"fr\":\"Annonce FR\"}", "JSON Copied")'
                      >
                        <v-icon v-if="!colorCopied">mdi-content-copy</v-icon>
                        <v-icon v-else color="green">mdi-check</v-icon>
                      </v-btn>
                    </v-list-item-action>
                  </v-list-item>
                </v-list>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>

    <v-card elevation="0" outlined color="#fafafa" class="ma-1">
      <v-card-text>
        <v-textarea
          :label="$t('announcement.description')"
          v-model="localData.metadata"
          class="required"
        />

        <v-select
          :label="$t('announcement.type')"
          v-model="localData.type"
          :items="announcementTypes"
          item-text="text"
          item-value="value"
          class="required"
          outlined
          dense
        />

        <v-row class="ma-0" align="center">
          <div>{{$t('announcement.active')}} :</div>
          <v-switch
            small
            v-model="localData.is_active"
            color="green"
            class="ms-4"
          ></v-switch>

        </v-row>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn elevation="2" @click="submit()" dark color="green" class="text-none rounded-lg">
          {{ $t('Save') }}
        </v-btn>
        <v-btn elevation="2" @click="close()" class="text-none rounded-lg">{{ $t('Close') }}</v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import { showSnackbar } from "@/services/GlobalActions"

export default {
  name: "system-announcement-edition",
  props: {
    data: {
      default: null,
    }
  },
  data() {
    return {
      localData: null,
      announcementTypes: [
        { text: "normal", value: 'normal' },
        { text: "warning", value: 'warning' },
      ],
    }
  },
  mounted() {
    this.localData = JSON.parse(JSON.stringify(this.data))
  },
  computed: {
    form() {
      return this.$refs.form
    },
  },
  methods: {
        copyToClipboard(text, stateProp) {
      navigator.clipboard
        .writeText(text)
        .then(() => {
          this[stateProp] = true

          // Show the confirmation snackbar
          showSnackbar(this.$t("view.learning_phishing.copy_success"))

          // Reset the state after 2 seconds
          setTimeout(() => {
            this[stateProp] = false
          }, 2000)
        })
        .catch((err) => {
          console.error("Erreur lors de la copie : ", err)
        })
    },
    async submit() {
      this.$emit("save", this.localData)
    },
    close() {
      this.$emit("close")
    },
  },
}
</script>
