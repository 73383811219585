import { log } from "async"
import CompanyLogic from "../logics/CompanyLogic"
import moment from "moment"
import utilsMixin from "./utils.mixin"

export default {
  mixins: [utilsMixin],
  data: () => ({
    employees: null,
    employeesBackup: null,
    employeeGroups: null,
    selectedEmployeeGroups: [],
  }),
  methods: {
    mapEmployee(employee) {
      const e = { ...employee.informations }
      e.updated_at_date = moment(e.updated_at).format("MMMM D, YYYY h:mm")
      return e
    },

    async getAllEmployees(params = {}) {
      await this._withLoading(async () => {
        const data = await CompanyLogic.getAllEmployees(this.companyUuid, params)
        this.employees = data.map((e) => this.mapEmployee(e))
        this.employeesBackup = JSON.parse(JSON.stringify(this.employees))
      })
    },

    saveNewEmployees(employeeList) {
      return this._withLoading(async () => {
        await CompanyLogic.pushNewEmployees(this.companyUuid, employeeList)
        this.loading = false
        return this.refresh()
      })
    },

    deleteEmployee(employee) {
      return this._withLoading(async () => {
        await CompanyLogic.deleteEmployee(this.companyUuid, employee.id)
        this.loading = false
        this.refresh()
      })
    },

    deactivateEmployee(employee) {
      return this._withLoading(() =>
        CompanyLogic.deactivateEmployee(this.companyUuid, employee)
      )
    },

    activateEmployee(employee) {
      return this._withLoading(() =>
        CompanyLogic.activateEmployee(this.companyUuid, employee)
      )
    },

    //--------------------------- employee filters ---------------------------
    getEmployeeFilters() {
      return this._withLoading(() =>
        CompanyLogic.getEmployeeFilters(this.companyUuid)
      )
    },

    //--------------------------- employee groups ---------------------------
    getAllEmployeeGroups() {
      return this._withLoading(() =>
        CompanyLogic.getAllEmployeeGroups(this.companyUuid)
      )
    },

    saveEmployeeGroup(data) {
      return this._withLoading(async () => {
        await CompanyLogic.pushEmployeeGroup(this.companyUuid, data)
        this.loading = false
        this.refresh()
      })
    },

    suppressEmployeeGroup(groupId) {
      return this._withLoading(async () => {
        await CompanyLogic.deleteEmployeeGroup(this.companyUuid, groupId)
        this.loading = false
        this.refresh()
      })
    },

    createMultipleEmployeeGroups(rules) {
      return this._withLoading(() =>
        CompanyLogic.createMultipleEmployeeGroup(this.companyUuid, rules)
      )
    },

    createSmartGroup(rules) {
      return this._withLoading(() =>
        CompanyLogic.createOneSmartEmployeeGroup(this.companyUuid, rules)
      )
    },

    updateSmartGroup(groupId, rules) {
      return this._withLoading(async () => {
        await CompanyLogic.updateOneSmartEmployeeGroup(
          this.companyUuid,
          groupId,
          rules
        )
        this.loading = false
        this.refresh()
      })
    },

    refreshSmartGroup(group) {
      return this._withLoading(async () => {
        await CompanyLogic.refreshOneSmartEmployeeGroup(this.companyUuid, group)
        this.loading = false
        this.refresh()
      })
    },

    deleteOneSmartGroupEmployee(groupId, employeeId) {
      return this._withLoading(() =>
        CompanyLogic.deleteOneEmployeeFromOneSmartEmployeeGroup(
          this.companyUuid,
          groupId,
          employeeId
        )
      )
    },

    //----------------------------- One employee --------------------------

    saveOneNewEmployee(companyUuid, employee) {
      return this._withLoading(async () => {
        await CompanyLogic.pushOneNewEmployee(companyUuid, employee)
        this.loading = false
        this.refresh()
      })
    },

    editOneEmployee(companyUuid, employee) {
      return this._withLoading(async () => {
        const data = await CompanyLogic.editOneEmployee(companyUuid, employee)
        this.loading = false
        this.refresh()
        return data
      })
    },
  },
}
