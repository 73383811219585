<template>
  <div>
    <router-view />
    <v-snackbar v-model="snackbar" color="blue-grey">
      {{ snackbarText }}
    </v-snackbar>
  </div>
</template>

<script>
import { isEmpty } from "lodash"
import AuthLogic from "@/logics/AuthLogic"
import { EventBus, ACTIONS } from "./bus"

import _ from "lodash"

export default {
  name: "App",
  async beforeMount() {
    EventBus.$on(ACTIONS.UNAUTHORIZED, () => {
      this.redirectToLogin()
    })

    EventBus.$on(ACTIONS.SNACKBAR, (message) => {
      this.snackbarText = message
      this.snackbar = true
    })

    if (
      isEmpty(process.env.VUE_APP_CLIENT_ID) ||
      isEmpty(process.env.VUE_APP_CLIENT_SECRET)
    ) {
      console.warn("Client secrets are not properly configured")
    }

    const accessToken = AuthLogic.getTokens()

    if (accessToken?.access_token) {
      await this.$authStore.sync()
    }
  },


  data: () => ({
    snackbar: false,
    snackbarText: null,
    fpOptions: {
      excludeAdBlock: true,
    },
  }),
  mounted() {
    console.log(`
  ██████  ██████  ██████  ███████ ███    ██ ███████
 ██    ██ ██   ██ ██   ██ ██      ████   ██ ██
 ██    ██ ██████  ██████  █████   ██ ██  ██ ███████
 ██    ██ ██      ██      ██      ██  ██ ██      ██
  ██████  ██      ██      ███████ ██   ████ ███████
`);

  },

  methods: {
    redirectToLogin: _.debounce(function () {
      if (this.$route.name !== "login") {
        this.$router.push({
          name: "login",
          query: { redirect: this.$route.fullPath },
        })
      }
    }, 300),
  },

  metaInfo: {
    // if no subcomponents specify a metaInfo.title, this title will be used
    title: "Administration d'Oppens",
    // all titles will be injected into this template
    titleTemplate: "%s | Oppens Admin",
    meta: [
      {
        name: "description",
        content: "Application d'adimnistration d'Oppens",
      },
    ],
  },
}
</script>
