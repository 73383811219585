import Request from "../services/Request"
import { API_VERSION } from "./ApiVersion"

export default class MonitoringRepository {
  static getAllEntities() {
    return Request.make("GET", `/${API_VERSION}/admin/monitoring/servers`)
  }

  static deleteOneMonitoring(monitoringId) {
    return Request.make(
      "DELETE",
      `/${API_VERSION}/admin/monitoring/servers/${monitoringId}`
    )
  }
  static patchOneMonitoring(data) {
    return Request.make(
      "PATCH",
      `/${API_VERSION}/admin/monitoring/servers/${data.id}`,
      data
    )
  }

  static createOneMonitoring(data) {
    return Request.make(
      "POST",
      `/${API_VERSION}/admin/monitoring/servers`,
      data
    )
  }
  static createNotificationSlack() {
    return Request.make(
      "POST",
      `/${API_VERSION}/admin/monitoring/servers/notification/slack`
    )
  }

  static getOneMonitoring(monitoringId) {
    return Request.make(
      "GET",
      `/${API_VERSION}/admin/monitoring/servers/${monitoringId}`
    )
  }

  static getOneServerStatus(serverId) {
    return Request.make(
      "GET",
      `${API_VERSION}/admin/monitoring/servers/status/${serverId}`
    )
  }
}
