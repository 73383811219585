<template>
  <v-container v-if="role">
    <v-card>
      <v-card-title class="d-flex justify-space-between mb-6">
        <span>{{ role.name }}</span>
      </v-card-title>
      <v-card-subtitle> {{ role.slug }}</v-card-subtitle>

      <v-card-text>
        <div><i>Description: </i> {{ role.description }} </div>
        <div class="code"><pre>{{ role.permissions }}</pre></div>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
export default {
  name: "oppens-role",
  props: {
    role: {
      default: null,
    },
  },
  data: () => ({}),
}
</script>
<style scoped>
.code {
  background-color: #f5f5f5;
  border: 1px solid #e8e8e8;
  border-radius: 3px;
  padding: 10px;
  margin: 10px 0;
}
</style>
