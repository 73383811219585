import FileRepository from "../repositories/FileRepository"

export default class FileLogic {
  static async getUserFiles(user) {
    let res = await FileRepository.getUserFiles(user.uuid)

    if (res.response && res.response.data && res.response.data.files) {
      return res.response.data.files
    }

    return []
  }

  static async downloadUserFile(user, file) {
    let res = await FileRepository.downloadUserFile(user.uuid, {
      file: file.name,
    })

    const url = window.URL.createObjectURL(new Blob([res.data]))

    const link = document.createElement("a")
    link.href = url
    link.download = file.name
    document.body.appendChild(link)
    link.click()
    setTimeout(function () {
      // For Firefox it is necessary to delay revoking the ObjectURL
      window.URL.revokeObjectURL(url)
      link.remove()
    }, 100)
  }

  static async uploadUserFiles(user, files) {
    let data = new FormData()

    data.append("uuid", user.uuid)

    for (const file of files) {
      data.append("userfiles[]", file)
    }

    return await FileRepository.uploadUserFiles(user.uuid, data)
  }

  static async deleteUserFile(user, file) {
    return await FileRepository.deleteUserFile(user.uuid, {
      uuid: user.uuid,
      file: file.name,
    })
  }

  static async getCompanyFiles(company) {
    let res = await FileRepository.getCompanyFiles(company.uuid)

    if (res.response && res.response.data && res.response.data.files) {
      return res.response.data.files
    }

    return []
  }

  static async downloadCompanyFile(company, file) {
    let res = await FileRepository.downloadCompanyFile(company.uuid, {
      file: file.name,
    })

    const url = window.URL.createObjectURL(new Blob([res.data]))

    const link = document.createElement("a")
    link.href = url
    link.download = file.name
    document.body.appendChild(link)
    link.click()

    setTimeout(function () {
      window.URL.revokeObjectURL(url)
      link.remove()
    }, 100)
  }

  static async uploadCompanyFiles(company, files) {
    let data = new FormData()

    data.append("uuid", company.uuid)

    for (const file of files) {
      data.append("files[]", file)
    }

    return await FileRepository.uploadCompanyFiles(company.uuid, data)
  }

  static async deleteCompanyFile(company, file) {
    return await FileRepository.deleteCompanyFile(company.uuid, {
      uuid: company.uuid,
      file: file.name,
    })
  }
}
