<template>
  <div class="d-flex align-center justify-center">
    <img
      :src="icons.px100"
      :srcset="`${icons.px100}, ${icons.px300} 3x`"
      class="theme-icon mr-6"
      :class="{ 'no-score': score === 0 }"
    />
    <div class="flex-grow-1">
      <div class="themeLabel">{{ $t(`humanCyberscore.themes.${theme}`) }}</div>
      <div class="d-flex align-center">
        <v-progress-linear
          class="rounded-progress"
          height="14"
          background-color="#dbdbdb"
          :value="score"
          color="#1AC8AA"
        />
        <div class="font-weight-medium score">
          {{ displayScore }}
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {padStart} from "lodash"

export default {
  name: "op-cyberscore-by-theme",
  props: {
    theme: {
      type: String,
    },
    score: {
      type: Number,
      default() {
        return 0
      },
    },
    icons: {
      type: Object,
    },
  },
  computed: {
    displayScore() {
      if (this.score === 0) {
        return "⏤"
      } else {
        return padStart(Math.round(this.score), 2, "\u00A0")
      }
    },
  },
}
</script>
<style scoped lang="scss">
.themeLabel {
  font-family: "Montserrat", sans-serif;
  text-align: start;
}

.theme-icon {
  width: 18px;
  height: 20px;

  &.no-score {
    filter: grayscale(1);
  }
}

.score {
  width: 2rem;
  text-align: end;
}

.rounded-progress {
  border-radius: 10px;
  width: 200px;
}
</style>
