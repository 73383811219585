import _ from "lodash"
import AuthStore from "../stores/AuthStore"
import UserRepository from "@/repositories/UserRepository"

export default class UserLogic {
  static async getMe() {
    let me = AuthStore.state.me
    if (me) {
      return me
    }
    return null
  }

  static hasRoles(user, neededRoles) {
    const roles = user.getRelation("roles")

    if (user && roles) {
      if (typeof neededRoles === "string") {
        return !!_.find(roles, (role) => {
          return role.getAttribute("name") === neededRoles
        })
      } else if (Array.isArray(neededRoles)) {
        return !!neededRoles.find((neededRole) => {
          return _.some(roles, (role) => {
            return role.getAttribute("name") === neededRole
          })
        })
      }
    } else {
      return false
    }
  }

  static async getNotifications(unread = true) {
    const response = await UserRepository.getNotifications(unread)
    return response.responseObject().data
  }

  static async markNotificationAsRead(notificationId) {
    const response = await UserRepository.markNotificationAsRead(notificationId)
    return response.responseObject()
  }

  static async markAllAsRead() {
    const response = await UserRepository.markAllAsRead()
    return response.responseObject()
  }
}
