<template>
  <div class="mainbox">
    <div class="cyberscore" @mouseenter="showDetails" @mouseleave="hideDetails">
      <div class="big-number">{{ Math.round(cyberScore) }}</div>
      <div v-if="showBreakdown && showDetailsFlag" class="overlay">
        <div class="details">
          <div v-for="(score, theme) in thematicScores" :key="theme">
            {{ theme }}: {{ score }}
          </div>
          <div>{{ $t('Number of Employees') }} : {{ nbEmployees }}</div>
          <div>{{ $t('Date') }} : {{ date }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "op-company-cyberscore",
  props: {
    gamificationInfo: {
      type: Object,
      required: true,
    },
    showBreakdown: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      showDetailsFlag: false,
    }
  },
  computed: {
    scoreChartData() {
      return {
        title: "CyberScore",
        centerTxtColor: "#4573c4",
        datasets: [
          {
            label: "label ?",
            data: [this.cyberScore, 100 - this.cyberScore],
            backgroundColor: ["#009688", "#CCC"],
            hoverOffset: 4,
          },
        ],
      }
    },

    cyberScore() {
      return this.gamificationInfo.global_cyber_score.toFixed(2)
    },

    thematicScores() {
      return this.gamificationInfo.thematic_scores
    },

    nbEmployees() {
      return this.gamificationInfo.nbEmployees
    },

    date() {
      return this.gamificationInfo.date
    },
  },

  methods: {
    showDetails() {
      this.showDetailsFlag = true
    },

    hideDetails() {
      this.hideTimeout = setTimeout(() => {
        this.showDetailsFlag = false
      }, 200) // Adjust the delay time as needed (in milliseconds)
    },
  },
}
</script>

<style scoped lang="scss">
.mainbox {
  width: 50px;
  border: 1px dashed #cfcfcf;
  text-align: center;
  border-radius: 10px;
}

.cyberscore {
  cursor: pointer;
  position: relative;
  width: 100%;
  text-align: center;

  .big-number {
    font-family: monospace !important;
    font-size: 25px;
    cursor: pointer;
    text-transform: uppercase;
  }

  .header {
    font-family: monospace !important;
    text-transform: uppercase;
    font-weight: 600;
    color: #868181;
  }
}

.overlay {
  position: absolute;
  border-radius: 10px;
  top: 0;
  left: 0;
  padding: 5px;
  width: 250px;
  height: auto;
  background-color: #1cc8aa; // rgba(255, 255, 255, 0.8);
  z-index: 1;
  justify-content: center;
  align-items: center;
  font-family: monospace !important;
  font-size: 12px;
  color: white;

  .details div {
    margin-bottom: 2px;
    border-bottom: 1px dashed #cfcfcf;
  }
}
</style>
