import Vue from "vue"
import App from "./App.vue"

import "@mdi/font/css/materialdesignicons.css"
import "@/sass/global.scss"

// vuetify
import Vuetify from "vuetify/lib"
import colors from "./plugins/colors"

import {i18n} from "./plugins/i18n"
import fr from "vuetify/es5/locale/fr"
import en from "vuetify/es5/locale/en"
import LocaleLogic from "./logics/LocaleLogic"
import VuePapaParse from "vue-papa-parse"
import "./plugins/vee-validate"
import browserDetect from "vue-browser-detect-plugin"
import AuthStore from "./stores/AuthStore"
import router from "./router/index"
import "@/globalComponents"

const vuetify = new Vuetify({
  theme: {
    options: {
      customProperties: true,
    },
    dark: false,
    themes: {
      light: colors,
      dark: colors,
    },
  },
  lang: {
    locales: LocaleLogic.getDefaultLanguage() === "fr" ? {fr} : {en},
    current: "fr",
  },
})

import VueHtml2pdf from "vue-html2pdf"

Vue.use(VueHtml2pdf)
Vue.use(Vuetify)
Vue.use(VuePapaParse)

Vue.config.productionTip = false

Vue.prototype.$authStore = AuthStore

Vue.use(browserDetect)

Vue.filter("uppercase", function (value) {
  if (!value) return ""
  value = value.toString()
  return value.toUpperCase()
})

Vue.filter("titlecase", function (value) {
  if (!value) return ""
  value = value.toString()
  return value.charAt(0).toUpperCase() + value.slice(1)
})

Vue.filter("monetize", function (value) {
  let monetizedValue = ""
  if (value != null) {
    if (value.toString() == "0") {
      monetizedValue = i18n.t("store.product.free")
    } else {
      monetizedValue = i18n.n(value / 100, "currency")
    }
  }
  return monetizedValue
})

Vue.filter("date", function (value) {
  return new Date(value).toLocaleString(i18n.locale, {
    day: "numeric",
    month: "numeric",
    year: "numeric",
  })
})

Vue.filter("datetime", function (value) {
  return new Date(value).toLocaleString(i18n.locale, {
    day: "numeric",
    month: "numeric",
    year: "numeric",
    hour: "numeric",
    minute: "numeric",
  })
})

router.beforeEach((to, from, next) => {
  let titlePrefix = '';

  if (process.env.NODE_ENV === 'local' || process.env.NODE_ENV === 'development') {
    titlePrefix = '[LOCAL] - ';
  } else if (process.env.NODE_ENV === 'staging' || process.env.NODE_ENV === 'preprod') {
    titlePrefix = '[STAGING] - ';
  }

  document.title = `${titlePrefix}ONE | Oppens`;
  next();
});

new Vue({
  router,
  vuetify,
  i18n,
  render: (h) => h(App),
}).$mount("#app")
