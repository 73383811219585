import DoubleAuthRepository from "../repositories/DoubleAuthRepository"
import AuthLogic from "./AuthLogic"

export default class DoubleAuthLogic {
  /**
   * @param body
   * @returns {Promise}
   */
  static activate() {
    return DoubleAuthRepository.activate()
  }

  /**
   * @returns {Promise}
   */
  static deactivate() {
    return DoubleAuthRepository.deactivate()
  }

  /**
   * @returns {Promise}
   */
  static async getQrCode() {
    const res = await DoubleAuthRepository.getQrCode()
    return res?.response?.data
  }

  /**
   * @returns {Promise}
   */
  static async getRecoveryCodes() {
    const res = await DoubleAuthRepository.getRecoveryCodes()
    return res?.response?.data
  }

  /**
   * @returns {Promise}
   */
  static generateRecoveryCodes() {
    return DoubleAuthRepository.generateRecoveryCodes()
  }

  /**
   * @param code
   * @returns {Promise}
   */
  static confirmActivation(code) {
    return DoubleAuthRepository.confirmActivation(code)
  }

  /**
   * @param code
   * @returns {Promise<Model>}
   */
  static async postChallenge(code) {
    const response = await DoubleAuthRepository.postChallenge(code)
    if (response.status_code === 200) {
      AuthLogic.set2FAIsNeeded(false)
      AuthLogic.setTokens(response.responseObject())
      return await AuthLogic.loadMe()
    } else {
      throw new Error("Le code n'a pas été accepté")
    }
  }

  /**
   * @param code
   * @returns {Promise}
   */
  static async postRecoveryCode(code) {
    const response = await DoubleAuthRepository.postRecoveryCode(code)
    if (response.status_code === 200) {
      AuthLogic.set2FAIsNeeded(false)
      AuthLogic.setTokens(response.responseObject())
      return await AuthLogic.loadMe()
    } else {
      throw new Error("Le code n'a pas été accepté")
    }
  }
}
