import MonitoringRepository from "../repositories/MonitoringRepository"

export default class monitoringLogic {
  static async getAllEntities() {
    const response = await MonitoringRepository.getAllEntities()
    let list = response.responseObject()
    return list.data
  }

  static async deleteMonitoring(monitoringId) {
    const response = await MonitoringRepository.deleteOneMonitoring(
      monitoringId
    )

    return response.responseObject()
  }

  static async pushMonitoring(data) {
    let response
    if (data.id) {
      response = await MonitoringRepository.patchOneMonitoring(data)
    } else {
      response = await MonitoringRepository.createOneMonitoring(data)
    }
    let list = response.responseObject()
    return list.data
  }

  static async pushNotificationSlack() {
    let response
    response = await MonitoringRepository.createNotificationSlack()
  }

  static async getOneMonitoring(monitoringId) {
    const response = await MonitoringRepository.getOneMonitoring(monitoringId)
    return response.responseObject().data
  }

  static async getOneServerStatus(serverId) {
    const response = await MonitoringRepository.getOneServerStatus(serverId)
    return response.responseObject().data
  }
}
