<script>
import {Bar} from "vue-chartjs"

export default {
  extends: Bar,

  name: "bar-chart",

  props: {
    chartData: {
      default: null,
    },
  },

  data: () => ({
    options: {
      responsive: true,
      maintainAspectRatio: false,
    },
  }),

  mounted() {
    this.renderChart(this.chartdata, this.options)
  },
}
</script>
