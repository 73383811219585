<template>
  <v-card outlined elevation="0">
    <v-data-table
      :server-items-length="totalEmployees"
      @update:options="changeListOptions"
      :page.sync="currentPage"
      :multi-sort="true"
      :items="employees.data"
      item-key="id"
      :headers="headers"
      dense
      fixed-header
      :footer-props="{ 'items-per-page-options': itemsPerPageOptions }"
      :items-per-page="itemsPerPage"
      :loading="employeeTableIsLoading"
      show-select
      class="px-4"
      v-model="selectedEmployees"
    >
      <template v-slot:top>
        <div>
          <v-toolbar flat>
            <v-toolbar-title class="text-h2">{{ title }}</v-toolbar-title>
            <v-spacer></v-spacer>
            <div>
              <v-dialog
                v-if="displayAdd"
                v-model="dialogIsOpened"
                max-width="720px"
                persistent
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    :disabled="azureSynchro"
                    color="green"
                    class="text-none rounded-lg mr-3"
                    dark
                    elevation="0"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon dark left size="22">mdi-plus </v-icon>
                    {{ $t("employees.addEmployee") }}
                  </v-btn>
                </template>
                <v-card class="pa-12" width="720">
                  <v-card-title class="mx-4 mb-4">{{
                    dialogTitle
                  }}</v-card-title>
                  <v-card-text>
                    <v-container style="background-color: white">
                      <v-row>
                        <v-col cols="6">
                          <op-text-field
                            name="last_name"
                            type="text"
                            :label="$t('employees.lastName')"
                            v-model="editedItem.informations.last_name"
                            rounded
                            outlined
                            hide-details="auto"
                          ></op-text-field>
                        </v-col>
                        <v-col cols="6">
                          <op-text-field
                            name="first_name"
                            type="text"
                            v-model="editedItem.informations.first_name"
                            :label="$t('employees.firstName')"
                            rounded
                            outlined
                            hide-details="auto"
                          >
                          </op-text-field>
                        </v-col>
                        <v-col cols="6">
                          <op-text-field
                            name="email"
                            type="email"
                            rules="required|email"
                            v-model="editedItem.informations.email"
                            :label="$t('employees.emailAddress')"
                            rounded
                            outlined
                            hide-details="auto"
                            :disabled="!isNewEmployee"
                          >
                          </op-text-field>
                        </v-col>
                        <v-col cols="6">
                          <op-text-field
                            name="telephone"
                            type="text"
                            v-model="editedItem.informations.telephone"
                            :label="$t('employees.telephone')"
                            rounded
                            outlined
                            hide-details="auto"
                          >
                          </op-text-field>
                        </v-col>
                        <v-col cols="6">
                          <v-combobox
                            clearable
                            hide-selected
                            :label="$t('employees.entity')"
                            v-model="editedItem.informations.entity"
                            :items="entityList"
                            rounded
                            outlined
                            dense
                            hide-details="auto"
                            ref="entitySelectRef"
                          ></v-combobox>
                        </v-col>
                        <v-col cols="6">
                          <v-combobox
                            clearable
                            hide-selected
                            :label="$t('employees.job')"
                            v-model="editedItem.informations.job"
                            :items="jobList"
                            rounded
                            outlined
                            dense
                            hide-details="auto"
                            ref="jobSelectRef"
                          ></v-combobox>
                        </v-col>
                        <v-col cols="6">
                          <op-text-field
                            name="manager_email"
                            type="email"
                            rules="email"
                            :label="$t('employees.manager_email')"
                            v-model="editedItem.informations.manager_email"
                            rounded
                            outlined
                            hide-details="auto"
                          ></op-text-field>
                        </v-col>
                        <v-col cols="6">
                          <op-text-field
                            name="department"
                            type="text"
                            :label="$t('employees.department')"
                            v-model="editedItem.informations.department"
                            rounded
                            outlined
                            hide-details="auto"
                          ></op-text-field>
                        </v-col>
                        <v-col cols="6">
                          <op-text-field
                            name="city"
                            type="text"
                            :label="$t('employees.city')"
                            v-model="editedItem.informations.city"
                            rounded
                            outlined
                            hide-details="auto"
                          ></op-text-field>
                        </v-col>
                        <v-col cols="6">
                          <op-text-field
                            name="country"
                            type="text"
                            :label="$t('employees.country')"
                            v-model="editedItem.informations.country"
                            rounded
                            outlined
                            hide-details="auto"
                          ></op-text-field>
                        </v-col>
                        <v-col cols="6">
                          <v-select
                            v-model="editedItem.informations.lang"
                            :items="countryLanguages"
                            :label="$t('employees.lang')"
                            rounded
                            outlined
                            dense
                            hide-details
                          >
                            <template v-slot:selection="data"
                              >{{ data.item.toLocaleUpperCase() }}
                            </template>
                            <template v-slot:item="{ item, on, attrs }">
                              <v-list-item v-on="on" v-bind="attrs"
                                >{{ item.toLocaleUpperCase() }}
                              </v-list-item>
                            </template>
                          </v-select>
                        </v-col>
                        <v-col cols="12">
                          <v-switch
                            name="active"
                            v-model="
                              editedItem.informations.is_generic_email_address
                            "
                            :label="$t('employees.isGenericEmailAddress')"
                          />
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>
                  <v-card-actions class="mx-4">
                    <v-spacer></v-spacer>
                    <v-btn
                      class="text-none rounded-lg"
                      outlined
                      elevation="0"
                      @click="close"
                      :disabled="isSaving"
                    >
                      {{ $t("view.button.close") }}
                    </v-btn>
                    <v-btn
                      color="blue"
                      class="text-none rounded-lg"
                      dark
                      elevation="0"
                      @click="save"
                      :loading="isSaving"
                    >
                      {{ employeeSaveLabel }}
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </div>
            <OpEmployeeImport
              v-if="displayImport"
              :companyUuid="companyUuid"
              :currentEmployees="employees.data"
              :azureSynchro="azureSynchro"
              v-on:refresh-employees="refreshEmployees"
            />
          </v-toolbar>
          <op-employee-table-filters
            :employeeTableIsLoading="employeeTableIsLoading"
            :employees="employees.data"
            :filteringOptions="employees.meta?.filtering_options"
            :totalEmployees="totalEmployees"
            @updateFilters="updateFilters"
            :hideableColumns="hideableColumns"
            @updateColumnSelection="updateColumnSelection"
            :selectedColumnCount="6"
          ></op-employee-table-filters>
          <v-toolbar flat>
            <v-text-field
              v-model="search"
              @input="debouncedSearchUpdate"
              prepend-icon="mdi-magnify"
              :label="$t('employees.searchLabel')"
              single-line
              hide-details
              solo
              flat
              clearable
              dense
              class="text-body-1"
            />
            <div v-if="selectedEmployeeCount > 0" class="d-flex align-center">
              <div class="very-light-grey--text text-body-2">
                {{ $tc("employees.selectedEmployees", selectedEmployeeCount) }}
              </div>
              <v-btn
                color="black"
                class="text-none rounded-lg ml-3"
                dark
                outlined
                small
                :disabled="selectedEmployeesStatus === undefined"
                @click="batchActivate"
                :loading="isSaving"
                >{{
                  selectedEmployeesStatus === true
                    ? $t("employees.deactivateSelected")
                    : selectedEmployeesStatus === false
                    ? $t("employees.activateSelected")
                    : ""
                }}
              </v-btn>
            </div>
          </v-toolbar>
        </div>
      </template>
      <template
        v-slot:[`item.informations.is_generic_email_address`]="{ item }"
      >
        <v-icon
          v-if="!item.informations.is_generic_email_address"
          color="success"
          >mdi-check
        </v-icon>
        <v-icon color="error" v-else>mdi-close</v-icon>
      </template>
      <template v-slot:[`item.informations.last_name`]="{ item }">
        {{ item.informations.last_name | uppercase }}
      </template>
      <template v-slot:[`item.informations.first_name`]="{ item }"
        >{{ item.informations.first_name | titlecase }}
      </template>
      <template v-slot:[`item.informations.position`]="{ item }"
        >{{ item.informations.position | titlecase }}
      </template>
      <template v-slot:[`item.informations.job`]="{ item }"
        >{{ item.informations.job | titlecase }}
      </template>
      <template v-slot:[`item.informations.entity`]="{ item }"
        >{{ item.informations.entity | titlecase }}
      </template>
      <template v-slot:[`item.informations.lang`]="{ item }"
        >{{ item.informations.lang | uppercase }}
      </template>
      <template v-slot:[`item.informations.cyberscore`]="{ item }">
        <v-btn text @click="selectEmployee(item)">
          {{
            item.informations.cyberscore ? item.informations.cyberscore : "--"
          }}
        </v-btn>
      </template>
      <template v-slot:[`item.informations.active`]="{ item }">
        <img :src="getActiveIcon(item.informations.active)" />
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-menu>
          <template v-slot:activator="{ on, attrs }">
            <v-btn text v-bind="attrs" v-on="on">
              <v-icon class="very-light-grey--text"
                >mdi-dots-horizontal
              </v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item
              @click="toggleActivate([item], !item.informations.active)"
            >
              <v-list-item-title
                >{{
                  item.informations.active
                    ? $t("employees.deactivate")
                    : $t("employees.activate")
                }}
              </v-list-item-title>
            </v-list-item>
            <v-list-item @click="editItem(item)">
              <v-list-item-title>{{ $t("button.edit") }} </v-list-item-title>
            </v-list-item>
            <v-list-item @click="selectEmployee(item)">
              <v-list-item-title
                >{{ $t("employees.cyberscore") }}
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </template>
    </v-data-table>
    <v-snackbar v-model="dialogAlert" :color="dialogAlertColor"
      >{{ dialogAlertMessage }}
    </v-snackbar>
    <v-snackbar v-model="errorSnackbar" color="error"
      >{{ errorMessage }}
    </v-snackbar>
    <op-employee-cyberscore-dialog ref="employeeCyberscore" />
  </v-card>
</template>
<script>
import CountryLanguage from "country-language"
import OpEmployeeImport from "./OpEmployeeImportExport.vue"
import EmployeeTableMixin from "@/mixins/EmployeeTable.mixins.js"
import TableMixins from "@/mixins/Table.mixins.js"
import CompanyLogic from "@/logics/CompanyLogic"

export default {
  name: "op-employee-list",
  mixins: [EmployeeTableMixin, TableMixins],
  props: {
    employees: {
      type: Object,
      required: true,
      default() {
        return { data: [], meta: {} }
      },
    },
    selectedEmployeesIds: {
      type: Array,
      required: false,
      default() {
        return []
      },
    },
    title: {
      type: String,
      required: false,
      default() {
        return ""
      },
    },
    errorMessage: {
      type: String,
      required: false,
      default() {
        return ""
      },
    },
    displayCustomField: {
      type: Boolean,
      default() {
        return false
      },
    },
    displayImport: {
      type: Boolean,
      default() {
        return true
      },
    },
    displayAdd: {
      type: Boolean,
      default() {
        return true
      },
    },
    displayMenu: {
      type: Boolean,
      default() {
        return true
      },
    },
    companyUuid: {
      type: String,
    },
    totalEmployees: {
      type: Number,
      required: true,
      default() {
        return 0
      },
    },
  },
  inject: [
    "deleteOneEmployee",
    "updateOneEmployee",
    "createOneEmployee",
    "activateEmployeeList",
    "deactivateEmployeeList",
  ],
  data() {
    return {
      isSaving: false,
      deleteDialogIsOpened: false,
      dialogIsOpened: false,
      dialogAlertMessage: "",
      dialogAlertColor: "error",
      editedIndex: -1,
      editedItem: { informations: { lang: "fr" } },
      defaultItem: {},
      jobList: [],
      entityList: [],
      selectedEmployeeId: null,
      cachedSelectedEmployeesIds: [],
      company: null,
      azureSynchro: true,
    }
  },
  mounted() {
    if (this.displayCustomField) {
      this.headers.splice(4, 0, {
        text: this.$t("employees.position"),
        value: "informations.position",
      })
      this.attributesToFilter.splice(0, 0, "position")
    }
    this.initEmployeeSelectionCache()
  },
  async created() {
    if (this.companyUuid) {
      this.company = await CompanyLogic.getOne(this.companyUuid, {
        with_active_directory_credentials: 1,
      })
      this.azureSynchro = this.company.active_directory_credentials.active
    }
    this.refreshEmployees()
    // Here we define headers that are always displayed
    this.STATIC_HEADERS = [
      {
        text: this.$t("employees.active"),
        value: "informations.active",
        cellClass: "text-body-1",
        class: this.headerClass,
        sortable: false,
        align: "center",
      },
      {
        text: this.$t("employees.emailAddress"),
        value: "informations.email",
        sortable: true,
        cellClass: "text-body-1",
        class: this.headerClass,
      },
    ]

    // Here we define headers that can be hidden
    this["informations.is_generic_email_address"] = {
      text: this.$t("employees.is_generic_email_address"),
      value: "informations.is_generic_email_address",
      cellClass: "text-body-1",
      class: this.headerClass,
      sortable: false,
    }
    this["informations.last_name"] = {
      text: this.$t("employees.lastName"),
      value: "informations.last_name",
      cellClass: "text-body-1",
      class: this.headerClass,
    }
    this["informations.first_name"] = {
      text: this.$t("employees.firstName"),
      value: "informations.first_name",
      cellClass: "text-body-1",
      class: this.headerClass,
    }
    this["informations.job"] = {
      text: this.$t("employees.job"),
      value: "informations.job",
      cellClass: "text-body-1",
      class: this.headerClass,
    }
    this["informations.entity"] = {
      text: this.$t("employees.entity"),
      value: "informations.entity",
      cellClass: "text-body-1",
      class: this.headerClass,
    }
    this["informations.department"] = {
      text: this.$t("employees.department"),
      value: "informations.department",
      cellClass: "text-body-1",
      class: this.headerClass,
      sortable: false,
    }
    this["informations.city"] = {
      text: this.$t("employees.city"),
      value: "informations.city",
      cellClass: "text-body-1",
      class: this.headerClass,
      sortable: false,
    }
    this["informations.country"] = {
      text: this.$t("employees.country"),
      value: "informations.country",
      cellClass: "text-body-1",
      class: this.headerClass,
      sortable: false,
    }
    this["informations.lang"] = {
      text: this.$t("employees.lang"),
      value: "informations.lang",
      cellClass: "text-body-1",
      class: this.headerClass,
    }
    this["informations.cyberscore"] = {
      text: this.$t("employees.cyberscore"),
      value: "informations.cyberscore",
      cellClass: "text-body-1",
      align: "right",
      class: this.headerClass,
      sortable: false,
    }
    this["informations.telephone"] = {
      text: this.$t("employees.telephone"),
      value: "informations.telephone",
      cellClass: "text-body-1",
      class: this.headerClass,
      sortable: false,
    }
    this["informations.manager_email"] = {
      text: this.$t("employees.manager_email"),
      value: "informations.manager_email",
      cellClass: "text-body-1",
      class: this.headerClass,
      sortable: false,
    }
    this["informations.updated_at"] = {
      text: this.$t("employees.last_update"),
      value: "informations.updated_at",
      cellClass: "text-body-1",
      class: this.headerClass,
      sortable: true,
    }
    this["informations.created_at"] = {
      text: this.$t("employees.creation_date"),
      value: "informations.created_at",
      cellClass: "text-body-1",
      class: this.headerClass,
      sortable: true,
    }
    this.hideableColumns = [
      { ...this["informations.is_generic_email_address"], default: true },
      { ...this["informations.last_name"], default: true },
      { ...this["informations.first_name"], default: true },
      //{ ...this["informations.job"], default: true },
      { ...this["informations.entity"], default: false },
      { ...this["informations.department"], default: false },
      { ...this["informations.city"], default: false },
      { ...this["informations.country"], default: false },
      { ...this["informations.lang"], default: true },
      //{ ...this["informations.cyberscore"], default: true },
      { ...this["informations.telephone"], default: false },
      { ...this["informations.manager_email"], default: false },
      { ...this["informations.updated_at"], default: true },
      { ...this["informations.created_at"], default: false },
    ]
    // The action header is always displayed
    this.ACTION_HEADERS = [
      {
        text: "",
        value: "actions",
        sortable: false,
        class: this.headerClass,
      },
    ]
    this.headers = this.STATIC_HEADERS.concat([this["informations.last_name"]])
      .concat([this["informations.first_name"]])
      //.concat([this["informations.job"]])
      .concat([this["informations.lang"]])
      .concat([this["informations.updated_at"]])
      .concat([this["informations.is_generic_email_address"]])
    //.concat([this["informations.cyberscore"]])
    //.concat(this.ACTION_HEADERS)

    if (this.displayMenu) {
      this.headers = this.headers.concat(this.ACTION_HEADERS)
    }
  },
  watch: {
    /*
     * When the selected employees change, we update the cached selected employees ids
     * we do not remove the employees that are not in the list of employees (because they can be in another page)
     */
    selectedEmployees(newList, oldList) {
      const newIdsList = newList.map((e) => e.id)
      const oldIdsList = oldList.map((e) => e.id)

      newIdsList.sort()
      oldIdsList.sort()

      if (
        newIdsList.length === oldIdsList.length &&
        newIdsList.every((id, index) => id === oldIdsList[index])
      ) {
        return
      }
      const addedIds = newIdsList.filter((id) => !oldIdsList.includes(id))
      let removedIds = oldIdsList.filter((id) => !newIdsList.includes(id))

      //!! on ne doit pas enlever les employés qui sont dans le cache mais pas dans la liste des employés..
      // cela veut dire qu'ils ne sont pas affichés c'est tout
      const currentEmployeeIds = this.employees.data.map((e) => e.id)
      const removedIdsNotInEmployeeList = removedIds.filter((id) =>
        currentEmployeeIds.includes(id)
      )

      this.cachedSelectedEmployeesIds = [
        ...this.cachedSelectedEmployeesIds.filter(
          (id) => !removedIdsNotInEmployeeList.includes(id)
        ),
        ...addedIds,
      ]

      //remove duplicates
      this.cachedSelectedEmployeesIds = [
        ...new Set(this.cachedSelectedEmployeesIds),
      ]
    },
    dialogIsOpened(val) {
      val || this.close()
    },
    dialogDelete(val) {
      val || this.closeDelete()
    },
    employees(newEmployees) {
      if (newEmployees?.meta?.filtering_options) {
        this.jobList = newEmployees.meta.filtering_options.jobs
        this.entityList = newEmployees.meta.filtering_options.entities
      } else {
        this.jobList = []
        this.entityList = []
      }
      this.updateEmployeeSelection()
    },
  },
  methods: {
    initEmployeeSelectionCache() {
      this.cachedSelectedEmployeesIds = [...this.selectedEmployeesIds]
    },
    updateEmployeeSelection() {
      if (
        this.employees.data.length > 0 &&
        this.cachedSelectedEmployeesIds.length > 0
      ) {
        this.selectedEmployees = this.employees.data.filter((e) =>
          this.cachedSelectedEmployeesIds.includes(e.id)
        )
      }
    },
    refreshEmployees(pageNumber) {
      const params = this.getParams(pageNumber)
      if (pageNumber) {
        this.currentPage = pageNumber
      }
      this.$emit("refresh-employees", { ...params, ...this.sortOptions })
    },
    closeDeleteDialog() {
      this.deleteDialogIsOpened = false
    },
    async deleteEmployee(item) {
      this.isSaving = true
      this.dialogAlertMessage = ""
      try {
        await this.deleteOneEmployee(this.companyUuid, item.id)
        this.dialogAlertMessage = this.$t("employees.employeeDeleted")
        this.dialogAlertColor = "success"
        this.closeDeleteDialog()
        this.refreshEmployees()
      } catch (error) {
        this.dialogAlertColor = "error"
        this.dialogAlertMessage =
          error?.response?.data?.error?.message || this.$t("error.default")
      } finally {
        this.isSaving = false
      }
    },
    close() {
      this.dialogIsOpened = false
      this.$nextTick(() => {
        this.editedItem = Object.assign(
          { informations: { lang: "fr" } },
          this.defaultItem
        )
        this.editedIndex = -1
      })
    },
    async save() {
      this.isSaving = true
      this.dialogAlertMessage = ""
      this.$refs.jobSelectRef.blur() // To validate job entry after typing the job and directly clicking save
      this.$refs.entitySelectRef.blur() // To validate entity entry after typing the entity and directly clicking save
      try {
        if (this.editedIndex > -1) {
          await this.updateOneEmployee(
            this.companyUuid,
            this.editedItem.informations
          )
          this.dialogAlertMessage = this.$t("employees.employeeSaved")
        } else {
          await this.createOneEmployee(
            this.companyUuid,
            this.editedItem.informations
          )
          this.dialogAlertMessage = this.$t("employees.employeeAdded")
        }
        this.refreshEmployees()
        this.dialogAlertColor = "success"
        this.close()
      } catch (error) {
        this.dialogAlertColor = "error"
        this.dialogAlertMessage =
          error?.response?.data?.error?.message || this.$t("error.default")
      } finally {
        this.isSaving = false
      }
    },
    editItem(item) {
      this.editedIndex = this.employees.data.indexOf(item)
      this.editedItem = Object.assign({ informations: {} }, item)
      this.dialogIsOpened = true
    },
    batchActivate() {
      if (this.selectedEmployees.length > 0) {
        this.toggleActivate(
          this.selectedEmployees,
          !this.selectedEmployees[0].informations.active
        )
      }
    },
    async toggleActivate(employees, activate) {
      this.isSaving = true
      this.dialogAlertMessage = ""
      try {
        if (activate) {
          await this.activateEmployeeList(this.companyUuid, employees)
        } else {
          await this.deactivateEmployeeList(this.companyUuid, employees)
        }
        this.refreshEmployees()
      } catch (error) {
        this.dialogAlertColor = "error"
        this.dialogAlertMessage =
          error?.response?.data?.error?.message || this.$t("error.default")
      } finally {
        this.isSaving = false
      }
    },
    displayName(item) {
      if (item.informations.first_name && item.informations.last_name) {
        return `${item.informations.first_name} ${item.informations.last_name}`
      } else {
        return item.informations.email
      }
    },
    async selectEmployee(employee) {
      this.selectedEmployeeId = employee.id
      await this.$refs.employeeCyberscore.open(employee, this.companyUuid)
    },
  },
  computed: {
    errorSnackbar: {
      get: function () {
        return this.errorMessage !== ""
      },
      set: function (newValue) {
        return newValue
      },
    },
    dialogAlert: {
      get: function () {
        return this.dialogAlertMessage !== ""
      },
      set: function (newValue) {
        return newValue
      },
    },
    countryLanguages() {
      return CountryLanguage.getLanguageCodes(1)
    },
    dialogTitle() {
      return this.editedIndex === -1
        ? this.$t("employees.quickAdd")
        : this.$t("employees.quickEdit")
    },
    employeeSaveLabel() {
      return this.editedIndex === -1 ? this.$t("view.button.add") : this.$t("view.button.save")
    },
    isNewEmployee() {
      return this.editedIndex === -1
    },
  },
  components: { OpEmployeeImport },
}
</script>
<style lang="scss">
.no-border.v-text-field > .v-input__control > .v-input__slot:before {
  border-style: none;
}

.no-border.v-text-field > .v-input__control > .v-input__slot:after {
  border-style: none;
}

.v-data-table > .v-data-table__wrapper > table > tbody > tr > td,
.v-data-table > .v-data-table__wrapper > table > tbody > tr > th,
.v-data-table > .v-data-table__wrapper > table > thead > tr > td,
.v-data-table > .v-data-table__wrapper > table > thead > tr > th,
.v-data-table > .v-data-table__wrapper > table > tfoot > tr > td,
.v-data-table > .v-data-table__wrapper > table > tfoot > tr > th {
  padding: 0 10px;
}

.v-text-field
  > .v-input__control
  > .v-input__slot
  > .v-select__slot
  > .v-select__selections
  > input::placeholder {
  color: #44444f;
}
</style>
