import Request from "../services/Request"

export default class AccessControlRepository {
  static baseRoute = "/admin/access-control"

  // ------------------ Perimeters ---------------
  static getAllPerimeters() {
    return Request.make(
      "GET",
      `${AccessControlRepository.baseRoute}/perimeters`
    )
  }

  static createPerimeter(body) {
    return Request.make(
      "POST",
      `${AccessControlRepository.baseRoute}/perimeters`,
      body
    )
  }

  static updatePerimeter(body) {
    return Request.make(
      "PATCH",
      `${AccessControlRepository.baseRoute}/perimeters/${body.id}`,
      body
    )
  }

  static deletePerimeter(id) {
    return Request.make(
      "DELETE",
      `${AccessControlRepository.baseRoute}/perimeters/${id}`,
      {}
    )
  }

  // ------------------ Roles ---------------
  static getAllRoles() {
    return Request.make("GET", `${AccessControlRepository.baseRoute}/roles`)
  }

  static createRole(body) {
    return Request.make(
      "POST",
      `${AccessControlRepository.baseRoute}/roles`,
      body
    )
  }

  static updateRole(body) {
    return Request.make(
      "PATCH",
      `${AccessControlRepository.baseRoute}/roles/${body.id}`,
      body
    )
  }

  static deleteRole(id) {
    return Request.make(
      "DELETE",
      `${AccessControlRepository.baseRoute}/roles/${id}`,
      {}
    )
  }

  static async addUserRolesInPerimeter(roleIds, roleSlugs, perimeterId, uuid) {
    const body = {
      role_ids: roleIds,
      role_slugs: roleSlugs,
    }

    return Request.make(
      "POST",
      `${AccessControlRepository.baseRoute}/roles/perimeters/${perimeterId}/users/${uuid}`,
      body
    )
  }

  static deleteUserRolesInPerimeter(roleIds, roleSlugs, perimeterId, uuid) {
    const params = {
      data: {
        role_ids: roleIds,
        role_slugs: roleSlugs,
      },
    }

    return Request.make(
      "DELETE",
      `${AccessControlRepository.baseRoute}/roles/perimeters/${perimeterId}/users/${uuid}`,
      params
    )
  }

  static async addUserRolesInCompany(roleIds, roleSlugs, companyUuid, uuid) {
    const body = {
      role_ids: roleIds,
      role_slugs: roleSlugs,
    }

    return Request.make(
      "POST",
      `${AccessControlRepository.baseRoute}/roles/companies/${companyUuid}/users/${uuid}`,
      body
    )
  }

  static deleteUserRolesInCompany(roleIds, roleSlugs, companyUuid, uuid) {
    const params = {
      data: {
        role_ids: roleIds,
        role_slugs: roleSlugs,
      },
    }

    return Request.make(
      "DELETE",
      `${AccessControlRepository.baseRoute}/roles/companies/${companyUuid}/users/${uuid}`,
      params
    )
  }

  // ------------------ Users ---------------

  static getAllUsersRolesWithPagination(params) {
    return Request.make(
      "GET",
      `${AccessControlRepository.baseRoute}/users?search=${params.search}&page_number=${params.page}&page_size=${params.perPage}`
    )
  }

  static getUserRoles(uuid) {
    return Request.make(
      "GET",
      `${AccessControlRepository.baseRoute}/users/${uuid}/roles`
    )
  }

  static removeCompanyRole(uuid, roleId, companyUuid) {}

  static addCompanyRole(uuid, roleId, companyUuid) {}

  static addPerimeterRole(uuid, roleId, perimeterId) {}

  static removePerimeterRole(uuid, roleId, perimeterId) {}
}
