<!-- CompanyCourseForm.vue -->
<template>
  <v-expansion-panels
    v-if="localCourse"
    class="ma-1 program-preset-expansion-panels"
    v-model="panel"
    multiple
  >
    <v-expansion-panel class="program-preset-expansion-panel">
      <div class="header-content">
        <h4>
          {{ localCourse.name }}
          <i>({{ $t("view.learning_phishing.hat_courses") }} {{ localCourse.hat_course_id }})</i>
        </h4>

        <div class="d-flex justify-center justify-space-between">
          <v-text-field
            v-model="localCourse.name"
            :label="$t('view.learning_phishing.course_name')"
            class="ma-2"
          ></v-text-field>
          <v-text-field
            v-model="localCourse.description"
            :label="$t('view.learning_phishing.description_learning')"
            class="ma-2"
          ></v-text-field>
        </div>
      </div>

      <v-expansion-panel-header color="green">
        {{ $t("view.companies.more_information") }}

        <template v-slot:actions>
          <v-icon color="white">
            mdi-arrow-down-drop-circle-outline
          </v-icon>
        </template>
      </v-expansion-panel-header>

      <v-expansion-panel-content class="panel-content-border">
        <div class="d-flex justify-center justify-space-between">
          <v-text-field
            v-model="localCourse.configuration.start_days_from_campaign_start"
            :label="$t('view.learning_phishing.start_days_from_campaign_start')"
            type="number"
            class="ma-2"
          ></v-text-field>
          <div v-if="localCourse.automation.automatic_launch">
            <v-menu
              ref="menu1"
              v-model="menu1"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="localCourse.configuration.start_date"
                  :label="$t('view.dashboard.start_date')"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="localCourse.configuration.start_date"
                no-title
              ></v-date-picker>
            </v-menu>
          </div>
        </div>
        <div class="d-flex justify-center justify-space-between">
          <v-checkbox
            v-model="localCourse.automation.is_live"
            label="Is Live"
          ></v-checkbox>

          <v-checkbox
            v-model="localCourse.automation.automatic_launch"
            :label="$t('view.companies.automatic_launch')"
          ></v-checkbox>

          <v-checkbox
            v-model="localCourse.automation.automatic_stop"
            :label="$t('view.companies.automatic_stop')"
          ></v-checkbox>

          <v-checkbox
            v-model="localCourse.automation.automatic_invites"
            :label="$t('view.companies.automatic_invitation')"
          ></v-checkbox>

          <v-checkbox
            v-model="localCourse.automation.automatic_reminders"
            :label="$t('view.companies.automatic_reminders')"
          ></v-checkbox>
          <v-checkbox
            v-model="localCourse.automation.automatic_duration_days"
            :label="$t('view.companies.automatic_duration_days')"
          ></v-checkbox>
        </div>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
import _ from "lodash"

export default {
  name: "CompanyCourseForm",
  props: {
    value: {
      type: Object,
      required: true,
    },
    hatCourses: {
      type: Array,
      default: null,
    },
    emailTypes: {
      type: Array,
      default: null,
    },
  },
  data: () => ({
    localCourse: null,
    menu1: false,
    panel: [0],
  }),
  async mounted() {
    this.localCourse = _.clone(this.value)
  },
  methods: {
    getLocalData() {
      return this.localCourse
    },
  },
}
</script>

<style lang="scss" scoped>
$color: #1876d2;

h4 {
  margin-bottom: 20px;
  border-bottom: $color dashed 1px;
  color: $color;
  text-transform: capitalize;
  width: 100%;
  display: block;

  i {
    font-size: 0.8em;
    color: #999;
  }
}
</style>
