import PhishingExerciseCatalogRepository from "../repositories/PhishingExerciseCatalogRepository"

export default class PhishingExerciseCatalogsLogic {
  //----------------------- Catalog CRUD -----------------------

  static async getAllCatalogs() {
    const response = await PhishingExerciseCatalogRepository.getAllCatalogs()
    let list = response.responseObject()
    return list.data
  }

  static async createCatalog(body) {
    const response = await PhishingExerciseCatalogRepository.createCatalog(body)
    return response.responseObject().data
  }

  static async updateCatalog(catalogId, body) {
    const response = await PhishingExerciseCatalogRepository.updateCatalog(
      catalogId,
      body
    )
    return response.responseObject().data
  }

  static async deleteCatalog(catalogId) {
    const response = await PhishingExerciseCatalogRepository.deleteCatalog(
      catalogId
    )
    return response.responseObject()
  }

  //----------------------- Exercises in Catalog -----------------------

  static async getCatalogExercises(catalogId) {
    const response =
      await PhishingExerciseCatalogRepository.getCatalogExercises(catalogId)
    return response.responseObject().data
  }

  static async addPhishingExerciseToCatalog(catalogId, exerciseId) {
    const response =
      await PhishingExerciseCatalogRepository.addPhishingExerciseToCatalog(
        catalogId,
        exerciseId
      )
    return response.responseObject().data
  }

  static async removePhishingExerciseFromCatalog(catalogId, exerciseId) {
    const response =
      await PhishingExerciseCatalogRepository.removePhishingExerciseFromCatalog(
        catalogId,
        exerciseId
      )
    return response.responseObject()
  }

  //----------------------- Company related to Catalog -----------------------

  static async getCompanyCatalogs(companyUuid) {
    const response = await PhishingExerciseCatalogRepository.getCompanyCatalogs(
      companyUuid
    )
    return response.responseObject().data
  }

  static async addCompanyToCatalog(companyUuid, catalogId) {
    const response =
      await PhishingExerciseCatalogRepository.addCompanyToCatalog(
        companyUuid,
        catalogId
      )
    return response.responseObject().data
  }

  static async removeCompanyFromCatalog(companyUuid, catalogId) {
    const response =
      await PhishingExerciseCatalogRepository.removeCompanyFromCatalog(
        companyUuid,
        catalogId
      )
    return response.responseObject()
  }
}
