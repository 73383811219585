<template>
  <v-dialog v-model="dialog" scrollable content-class="myDialog">
    <v-row v-if="display">
      <v-col cols="6">
        <iframe
          :srcdoc="compiledTemplates.email.subject"
          style="
            border-style: none;
            background-color: #fff;
            width: 100%;
            height: 10%;
          "
        ></iframe>
        <iframe
          :srcdoc="compiledTemplates.email.html"
          style="
            border-style: none;
            background-color: #fff;
            width: 100%;
            height: 90%;
          "
        ></iframe>
      </v-col>
      <v-col cols="6">
        <iframe
          :srcdoc="compiledTemplates.landingPage.html"
          style="
            border-style: none;
            background-color: #fff;
            width: 100%;
            height: 100%;
          "
        ></iframe>
      </v-col>
    </v-row>
  </v-dialog>
</template>

<script>
export default {
  name: "op-exercise-preview-modale",
  data: () => ({
    dialog: false,
    compiledTemplates: null,
  }),

  props: {
    data: {
      default: null,
    },
    display: {
      type: Boolean,
    },
  },

  watch: {
    data: {
      handler(newVal, oldVal) {
        if (newVal) {
          this.compiledTemplates = newVal
        }
      },
      deep: true,
    },

    display: {
      handler(newVal, oldVal) {
        if (newVal) {
          this.dialog = true
        }
      },
      deep: true,
    },
    dialog: {
      handler(newVal, oldVal) {
        if (!newVal) {
          this.$emit("closed")
        }
      },
      deep: true,
    },
  },

  methods: {},
}
</script>

<style>
.myIframe {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  border: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
  z-index: 999999;
}

.myDialog {
  position: relative;
  width: 80%;
  height: 80%;
}
</style>
