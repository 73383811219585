import lodash from "lodash"
import {i18n} from "@/plugins/i18n"
export default {
  data: () => ({
    employees: [],
    totalEmployees: 0,
    employeeTableIsLoading: false,
    selectedEmployees: [],
    sortOptions: {},
    currentPage: 1,
    itemsPerPage: 25,
    itemsPerPageOptions: [25, 50, 100, 250, 500],
    attributesToFilter: ["job", "entity", "lang"],
    employeeFilter: undefined,
    headerFilters: {},
    selectedHeaders: {
      position: [],
      job: [],
      entity: [],
      lang: [],
    },
    filteringOptions: {},
    search: "",
    footerProps: {
      "items-per-page-options": [25, 50, 100, 250, 500],
    },
    headers: [
      {
        text: i18n.t("view.users.profile.last_name"),
        align: "start",
        sortable: true,
        filterable: true,
        value: "last_name",
      },
      {
        text: i18n.t("view.users.profile.first_name"),
        align: "start",
        sortable: true,
        filterable: true,
        value: "first_name",
      },
      {
        text: i18n.t("view.users.profile.email"),
        align: "start",
        sortable: true,
        filterable: true,
        value: "email",
      },
      {
        text: i18n.t("view.users.profile.manager_email"),
        align: "start",
        sortable: true,
        filterable: true,
        value: "manager_email",
      },
      {
        text: i18n.t("view.companies.phone_number"),
        align: "start",
        sortable: true,
        value: "telephone",
      },
      {
        text: i18n.t("view.companies.job"),
        align: "start",
        sortable: true,
        filterable: true,
        value: "job",
      },
      {
        text: i18n.t("view.companies.entity"),
        align: "start",
        sortable: true,
        filterable: true,
        value: "entity",
      },
      {
        text: i18n.t("view.users.profile.department"),
        align: "start",
        sortable: true,
        filterable: true,
        value: "department",
      },
      {
        text: i18n.t("view.users.profile.city"),
        align: "start",
        sortable: true,
        filterable: true,
        value: "city",
      },
      {
        text: i18n.t("view.users.profile.country"),
        align: "start",
        sortable: true,
        filterable: true,
        value: "country",
      },
      {
        text: i18n.t("view.companies.updated_at"),
        align: "start",
        sortable: true,
        filterable: true,
        value: "updated_at_date",
      },
      {
        text: i18n.t("view.learning_phishing.language"),
        align: "start",
        sortable: true,
        filterable: true,
        value: "lang",
      },
      {text: i18n.t("view.companies.actions"), value: "actions", sortable: false},
    ],
    shortHeaders: [
      {
        text: i18n.t("view.users.profile.first_name"),
        align: "start",
        sortable: true,
        filterable: true,
        value: "first_name",
      },
      {
        text: i18n.t("view.users.profile.last_name"),
        align: "start",
        sortable: true,
        filterable: true,
        value: "last_name",
      },
      {
        text: i18n.t("view.users.profile.email"),
        align: "start",
        sortable: true,
        filterable: true,
        value: "email",
      },
      {
        text: i18n.t("view.learning_phishing.language"),
        align: "start",
        sortable: true,
        filterable: true,
        value: "lang",
      },
      {
        text: i18n.t("view.companies.job"),
        align: "start",
        sortable: true,
        filterable: true,
        value: "job",
      },
      {
        text: i18n.t("view.companies.job_position"),
        align: "start",
        sortable: true,
        value: "position",
      },
      {
        text: i18n.t("view.companies.entity"),
        align: "start",
        sortable: true,
        filterable: true,
        value: "entity",
      },
    ],
    shortHeadersWithActions: [
      {
        text: i18n.t("view.users.profile.first_name"),
        align: "start",
        sortable: true,
        filterable: true,
        value: "first_name",
      },
      {
        text: i18n.t("view.users.profile.last_name"),
        align: "start",
        sortable: true,
        filterable: true,
        value: "last_name",
      },
      {
        text: i18n.t("view.users.profile.manager_email"),
        align: "start",
        sortable: true,
        filterable: true,
        value: "email",
      },
      {
        text: i18n.t("view.learning_phishing.language"),
        align: "start",
        sortable: true,
        filterable: true,
        value: "lang",
      },
      {
        text: i18n.t("view.companies.job"),
        align: "start",
        sortable: true,
        filterable: true,
        value: "job",
      },
      {
        text: i18n.t("view.companies.job_position"),
        align: "start",
        sortable: true,
        value: "position",
      },
      {
        text: i18n.t("view.companies.entity"),
        align: "start",
        sortable: true,
        filterable: true,
        value: "entity",
      },
      {text: i18n.t("view.companies.actions"), value: "actions", sortable: false},
    ],
    veryShortHeaders: [
      {
        text: i18n.t("view.users.profile.first_name"),
        align: "start",
        sortable: true,
        filterable: true,
        value: "first_name",
      },
      {
        text: i18n.t("view.users.profile.last_name"),
        align: "start",
        sortable: true,
        filterable: true,
        value: "last_name",
      },
      {
        text: i18n.t("view.users.profile.manager_email"),
        align: "start",
        sortable: true,
        filterable: true,
        value: "email",
      },
      {
        text: i18n.t("view.learning_phishing.language"),
        align: "start",
        sortable: true,
        filterable: true,
        value: "lang",
      },
    ],
    shortHeadersv2: [
      {
        text: i18n.t("view.users.profile.first_name"),
        align: "start",
        sortable: true,
        filterable: true,
        value: "informations.first_name",
      },
      {
        text: i18n.t("view.users.profile.last_name"),
        align: "start",
        sortable: true,
        filterable: true,
        value: "informations.last_name",
      },
      {
        text: i18n.t("view.users.profile.manager_email"),
        align: "start",
        sortable: true,
        filterable: true,
        value: "informations.email",
      },
      {
        text: i18n.t("view.learning_phishing.language"),
        align: "start",
        sortable: true,
        filterable: true,
        value: "informations.lang",
      },
      {
        text: i18n.t("view.companies.job"),
        align: "start",
        sortable: true,
        filterable: true,
        value: "informations.job",
      },
      {
        text: i18n.t("view.companies.job_position"),
        align: "start",
        sortable: true,
        value: "informations.position",
      },
      {
        text: i18n.t("view.companies.entity"),
        align: "start",
        sortable: true,
        filterable: true,
        value: "informations.entity",
      },
    ],
  }),
  methods: {
    itemRowBackground: function (item) {
      return item.active === false ? "inactive-item" : "normal-item"
    },
    changeListOptions(options) {
      this.sortOptions = {}
      this.currentPage = options.page
      this.itemsPerPage = options.itemsPerPage
      /*
      imported from oppens-front but not used yet

      for (let sortIndex = 0; sortIndex < options.sortBy.length; sortIndex++) {
        let attributes = options.sortBy[sortIndex].split(".")
        const cleanedAttribute = attributes.pop()
        const sortName =
          attributes[0] === "informations"
            ? `sorts[${cleanedAttribute}]`
            : `sort_${attributes[0]}[${cleanedAttribute}]`
        this.sortOptions[sortName] = options.sortDesc[sortIndex]
          ? "desc"
          : "asc"
      }*/
      this.refreshEmployees()
    },
    getParams(page_number = this.currentPage) {
      let params = {
        page_size: this.itemsPerPage,
        page_number,
      }
      if (this.employeeFilter) {
        params.filter_by_results = this.employeeFilter
      }
      if (this.search !== "") {
        params.email_or_name = this.search
      }
      if (this.selectedHeaders.lang.length > 0) {
        params["filters[lang]"] = this.selectedHeaders.lang.join(",")
      }
      if (this.selectedHeaders.entity.length > 0) {
        params["filters[entity]"] = this.selectedHeaders.entity.join(",")
      }
      if (this.selectedHeaders.job.length > 0) {
        params["filters[job]"] = this.selectedHeaders.job.join(",")
      }
      if (this.selectedHeaders.position.length > 0) {
        params["filters[position]"] = this.selectedHeaders.position.join(",")
      }
      return params
    },
  },
}
