<!-- CampaignForm.vue -->
<template>
  <v-card v-if="localCampaign" class="ma-2 pa-5">
    <h3>{{ localCampaign.name }}</h3>

    <v-card-text>
      <div class="d-flex justify-center justify-space-between">
        <v-text-field
          v-model="localCampaign.name"
          :label="$t('view.companies.campaign_name')"
          class="ma-2"
        ></v-text-field>

        <v-text-field
          v-model="localCampaign.description"
          :label="$t('view.companies.campaign_description')"
          class="ma-2"
        ></v-text-field>
      </div>
    </v-card-text>

    <!-- Company Courses -->
    <div
      v-for="(course, index) in localCampaign.company_courses"
      :key="course.hat_course_id"
    >
      <company-course-form
        ref="companyCourseForms"
        :value="course"
        :hatCourses="hatCourses"
        :emailTypes="emailTypes"
        @update="(val) => $set(localCampaign.company_courses, index, val)"
      ></company-course-form>
    </div>

    <!-- Simulations -->
    <div v-for="(simulation, index) in localCampaign.simulations" :key="index">
      <simulation-form
        ref="simulationForms"
        :value="simulation"
        :exercises="exercises"
        @update="(val) => $set(localCampaign.simulations, index, val)"
      ></simulation-form>
    </div>
  </v-card>
</template>

<script>
import _ from "lodash"
import CompanyCourseForm from "./CompanyCourseForm.vue"
import SimulationForm from "./SimulationForm.vue"
import moment from "moment"

export default {
  name: "campaign-form",
  components: {
    CompanyCourseForm,
    SimulationForm,
  },
  props: {
    value: {
      type: Object,
      required: true,
    },
    emailTypes: {
      type: Array,
      default: null,
    },
    hatCourses: {
      type: Array,
      default: null,
    },
    exercises: {
      type: Array,
      default: null,
    },
  },
  data: () => ({
    localCampaign: null,
  }),
  async mounted() {
    let campaign = _.clone(this.value)

    this.initCoursesConfiguration(campaign)
    this.initSimulationsConfiguration(campaign)

    this.localCampaign = campaign
  },
  methods: {
    initSimulationsConfiguration(campaign) {
      const campaignStartMoment = moment(campaign.configuration.start_date)
      for (let i = 0; i < campaign.simulations.length; i++) {
        campaign.simulations[i].configuration.launch_date = campaignStartMoment
          .add(
            campaign.simulations[i].configuration
              .start_days_from_campaign_start,
            "days"
          )
          .format("YYYY-MM-DD")
      }
    },
    initCoursesConfiguration(campaign) {
      const campaignStartMoment = moment(campaign.configuration.start_date)
      for (let i = 0; i < campaign.company_courses.length; i++) {
        campaign.company_courses[i].configuration.start_date =
          campaignStartMoment
            .add(
              campaign.company_courses[i].configuration
                .start_days_from_campaign_start,
              "days"
            )
            .format("YYYY-MM-DD")
      }
    },
    updateStartDateFromProgramStart(isoDate) {
      this.localCampaign.configuration.start_date = moment(isoDate)
        .add(
          this.localCampaign.configuration.start_days_from_program_start,
          "days"
        )
        .format("YYYY-MM-DD")

      this.$nextTick(() => {
        this.udpateChildFormsStart()
      })
    },
    getLocal() {
      const companyCourseForms = this.$refs.companyCourseForms

      if (!companyCourseForms) {
        console.error("No companyCourse forms found")
        return
      }

      for (let i = 0; i < companyCourseForms.length; i++) {
        companyCourseForms[i].updateStartDateFromCampaignStart(
          this.localCampaign.configuration.start_date
        )
      }
    },
    getLocalData() {
      this.localCampaign.company_courses = []
      this.localCampaign.simulations = []

      const companyCourseForms = this.$refs.companyCourseForms ?? []
      const simulationForms = this.$refs.simulationForms ?? []

      for (let i = 0; i < companyCourseForms.length; i++) {
        this.localCampaign.company_courses.push(
          companyCourseForms[i].getLocalData()
        )
      }

      for (let i = 0; i < simulationForms.length; i++) {
        this.localCampaign.simulations.push(simulationForms[i].getLocalData())
      }

      return this.localCampaign
    },
  },
}
</script>
<style scoped lang="scss">
h3 {
  text-transform: capitalize;
  text-align: center;
  margin-bottom: 20px;
  color: #1876d2;
}
</style>
