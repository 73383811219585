import { render, staticRenderFns } from "./AssetItem.vue?vue&type=template&id=bb45543c&scoped=true&"
import script from "./AssetItem.vue?vue&type=script&lang=js&"
export * from "./AssetItem.vue?vue&type=script&lang=js&"
import style0 from "./AssetItem.vue?vue&type=style&index=0&id=bb45543c&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bb45543c",
  null
  
)

export default component.exports