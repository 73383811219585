import HatCourseCatalogsLogic from "../logics/HatCourseCatalogsLogic"
import utilsMixin from "./utils.mixin"

export default {
  mixins: [utilsMixin],
  methods: {
    async getAllCatalogs() {
      return this._withLoading(() => HatCourseCatalogsLogic.getAllCatalogs())
    },

    async getCatalogCourses(catalogId) {
      return this._withLoading(() =>
        HatCourseCatalogsLogic.getCatalogCourses(catalogId)
      )
    },

    async createCatalog(body) {
      return this._withLoading(() => HatCourseCatalogsLogic.createCatalog(body))
    },

    async updateCatalog(catalogId, body) {
      return this._withLoading(() =>
        HatCourseCatalogsLogic.updateCatalog(catalogId, body)
      )
    },

    async deleteCatalog(catalogId) {
      return this._withLoading(() =>
        HatCourseCatalogsLogic.deleteCatalog(catalogId)
      )
    },

    async addHatCourseToCatalog(catalogId, courseId) {
      return this._withLoading(() =>
        HatCourseCatalogsLogic.addHatCourseToCatalog(catalogId, courseId)
      )
    },

    async removeHatCourseFromCatalog(catalogId, courseId) {
      return this._withLoading(() =>
        HatCourseCatalogsLogic.removeHatCourseFromCatalog(catalogId, courseId)
      )
    },

    async getCompanyCatalogs(companyUuid) {
      return this._withLoading(() =>
        HatCourseCatalogsLogic.getCompanyCatalogs(companyUuid)
      )
    },

    async addCompanyToCatalog(companyUuid, catalogId) {
      return this._withLoading(() =>
        HatCourseCatalogsLogic.addCompanyToCatalog(companyUuid, catalogId)
      )
    },

    async removeCompanyFromCatalog(companyUuid, catalogId) {
      return this._withLoading(() =>
        HatCourseCatalogsLogic.removeCompanyFromCatalog(companyUuid, catalogId)
      )
    },
  },
}
