import CompanyLogic from "../logics/CompanyLogic"
import utilsMixin from "./utils.mixin"

export default {
  mixins: [utilsMixin],

  methods: {
    getAllCampaigns() {
      return this._withLoading(() =>
        CompanyLogic.getAllCampaigns(this.companyUuid)
      )
    },

    saveCampaign(data) {
      return this._withLoading(async () => {
        await CompanyLogic.pushCampaign(this.companyUuid, data)
        this.loading = false
        this.refresh() // Assuming this method exists elsewhere in the component or another mixin
      })
    },

    suppressCampaign(campaignId) {
      return this._withLoading(async () => {
        await CompanyLogic.deleteCampaign(this.companyUuid, campaignId)
        this.loading = false
        this.refresh()
      })
    },

    generateCampaignReport(campaignId, language, typeOfReport) {
      return this._withLoading(async () => {
        const data = await CompanyLogic.generateCampaignReport(
          this.companyUuid,
          campaignId,
          language,
          typeOfReport
        )
        this.loading = false
        this.refresh()
        return data
      })
    },
  },
}
