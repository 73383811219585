<template>
  <div v-if="data">
    <v-card elevation="0" outlined class="ma-1">
      <span>
        <v-card-title>
          <v-tooltip slot="append" top>
            <template v-slot:activator="{ on }">
              <v-chip
                v-on="on"
                class="id-icon mx-2"
                small
                label
                dark
                color="red"
              >{{ data.id }}</v-chip
              >
            </template>
            <span>{{ $t('ID') }}</span>
          </v-tooltip>
                    <v-tooltip slot="append" top>
            <template v-slot:activator="{ on }">
              <v-chip
                v-on="on"
                class="id-icon mx-2"
                small
                label
                dark
                color="red"
              >Footer</v-chip
              >
            </template>
            <span>{{ $t('Footer') }}</span>
          </v-tooltip>

          {{ data.title }}
        </v-card-title>
      </span>
      <v-card-subtitle>
        <div>{{ $t('Content') }} : {{ data.content }}</div>
        <div>
          {{ $t('Creation date') }} : {{ formatDate(data.created_at) }}
        </div>
        <div>
          {{ $t('Update date') }} : {{ formatDate(data.updated_at) }}
        </div>
      </v-card-subtitle>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn elevation="2" class="text-none rounded-lg" @click="duplicate()">{{ $t('Duplicate') }}</v-btn>
        <v-btn elevation="2" class="text-none rounded-lg" @click="edit()">{{ $t('Edit') }}</v-btn>
        <v-icon @click="suppress()" class="ml-2" elevation="2" icon
        >mdi-delete
        </v-icon
        >
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import moment from "moment"

export default {
  name: "system-footer-template",
  props: {
    data: null,
  },
  methods: {
    async suppress() {
      this.$emit("suppress", this.data)
    },
    edit() {
      this.$emit("edit", this.data)
    },
    duplicate() {
      this.$emit("duplicate", this.data)
    },
    formatDate(date) {
      if (!date) return
      return moment(date).format("DD/MM/YYYY à HH:mm")
    },
  },
}
</script>
