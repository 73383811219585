import CompanyLogic from "../logics/CompanyLogic"
import AdminLogic from "../logics/AdminLogic"
import utilsMixin from "./utils.mixin"

export default {
  mixins: [utilsMixin],
  methods: {
    getAllPrograms() {
      return this._withLoading(() =>
        CompanyLogic.getAllPrograms(this.companyUuid)
      )
    },

    getOneProgram() {
      return this._withLoading(() =>
        CompanyLogic.getOneProgram(this.companyUuid, this.programId)
      )
    },

    saveProgram(data) {
      return this._withLoading(() =>
        CompanyLogic.pushProgram(this.companyUuid, data).then(() => {
          this.loading = false
          this.refresh()
        })
      )
    },

    suppressProgram(programId) {
      return this._withLoading(async () => {
        await CompanyLogic.deleteProgram(this.companyUuid, programId)
        this.loading = false
        this.refresh()
      })
    },

    getAllProgramPresets() {
      return this._withLoading(() =>
        CompanyLogic.getAllProgramPresets(this.companyUuid)
      )
    },

    getOneProgramPresets(presetId) {
      return this._withLoading(() =>
        CompanyLogic.getOneProgramPreset(this.companyUuid, presetId)
      )
    },

    instantiateOneProgramPreset(companyUuid, presetId, body) {
      return this._withLoading(() =>
        CompanyLogic.instantiateOneProgramPreset(companyUuid, presetId, body)
      )
    },
  },
}
