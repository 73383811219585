export default {
  data: () => ({
    search: "",
    footerProps: {
      "items-per-page-options": [10, 30, 50, 100, 200, -1],
    },
    headers: [
      {
        text: "ID",
        align: "start",
        sortable: true,
        filterable: true,
        value: "id",
      },
      {
        text: "Description",
        align: "start",
        sortable: true,
        filterable: true,
        value: "description",
      },
      {
        text: "Score",
        align: "start",
        sortable: true,
        filterable: true,
        value: "score",
      },
    ],
  }),
}
