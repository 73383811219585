import AccessControlRepository from "../repositories/AccessControlRepository"
import AuthLogic from "./AuthLogic"

export default class AccessControlLogic {
  // ------------------ Perimeters ---------------
  static async getAllPerimeters() {
    const response = await AccessControlRepository.getAllPerimeters()
    return response.responseObject().data
  }

  static async savePerimeter(data) {
    if (data && data.id) {
      const response = await AccessControlRepository.updatePerimeter(data)
      return response.responseObject().data
    } else {
      const response = await AccessControlRepository.createPerimeter(data)
      return response.responseObject().data
    }
  }

  static async deletePerimeter(data) {
    const response = await AccessControlRepository.deletePerimeter(data.id)
    return response.responseObject().data
  }

  // ------------------ Roles ---------------
  static async getAllRoles() {
    const response = await AccessControlRepository.getAllRoles()
    return response.responseObject().data
  }

  static async saveRole(data) {
    if (data && data.id) {
      const response = await AccessControlRepository.updateRole(data)
      return response.responseObject().data
    } else {
      const response = await AccessControlRepository.createRole(data)
      return response.responseObject().data
    }
  }

  static async deleteRole(data) {
    const response = await AccessControlRepository.deleteRole(data.id)
    return response.responseObject().data
  }

  static async addUserRolesInPerimeter(roleIds, roleSlugs, perimeterId, uuid) {
    const response = await AccessControlRepository.addUserRolesInPerimeter(
      roleIds,
      roleSlugs,
      perimeterId,
      uuid
    )
    await AuthLogic.refreshRoles()
    return response.responseObject().data
  }

  static async deleteUserRolesInPerimeter(
    roleIds,
    roleSlugs,
    perimeterId,
    uuid
  ) {
    const response = await AccessControlRepository.deleteUserRolesInPerimeter(
      roleIds,
      roleSlugs,
      perimeterId,
      uuid
    )
    await AuthLogic.refreshRoles()
    return response.responseObject().data
  }

  static async addUserRolesInCompany(roleIds, roleSlugs, companyUuid, uuid) {
    const response = await AccessControlRepository.addUserRolesInCompany(
      roleIds,
      roleSlugs,
      companyUuid,
      uuid
    )
    await AuthLogic.refreshRoles()
    return response.responseObject().data
  }

  static async deleteUserRolesInCompany(roleIds, roleSlugs, companyUuid, uuid) {
    const response = await AccessControlRepository.deleteUserRolesInCompany(
      roleIds,
      roleSlugs,
      companyUuid,
      uuid
    )
    await AuthLogic.refreshRoles()
    return response.responseObject().data
  }

  // ------------------ Users ---------------
  static async getAllUsersRolesWithPagination(params) {
    const response =
      await AccessControlRepository.getAllUsersRolesWithPagination(params)
    return response.responseObject()
  }

  static async getUserRoles(uuid) {
    const response = await AccessControlRepository.getUserRoles(uuid)
    return response.responseObject().data
  }
}
