<template>
  <div class="gauge">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="77.5"
      height="43.5"
      viewBox="0 0 155 87"
      fill="none"
    >
      <g clip-path="url(#clip0_258_9)">
        <path
          d="M22.239 43.2939C17.434 52.4289 14.799 62.6217 14.551 73.0067H34.577C34.794 66.2436 36.499 59.6087 39.537 53.6469L22.239 43.2939Z"
          fill="#EEEEEE"
        />
        <path
          d="M133.312 43.2619L115.983 53.6149C119.052 59.6087 120.788 66.2436 121.005 73.0067H141C140.752 62.5896 138.117 52.3969 133.312 43.2619Z"
          fill="#1CC8AA"
        />
        <path
          d="M44.838 18.8378C36.282 24.2547 29.059 31.6909 23.82 40.5695L41.149 50.9225C44.621 45.1851 49.302 40.3772 54.82 36.7873L44.838 18.8378Z"
          fill="#FF5E5E"
        />
        <path
          d="M110.713 18.7416L100.731 36.627C106.28 40.2169 110.992 45.0889 114.464 50.8263L131.793 40.4733C126.523 31.5947 119.3 24.1585 110.713 18.7416Z"
          fill="#009BA4"
        />
        <path
          d="M76.21 9.222C66.166 9.47843 56.308 12.2029 47.473 17.1711L57.455 35.0564C63.252 31.9153 69.638 30.1524 76.179 29.928V9.222H76.21Z"
          fill="#FF845E"
        />
        <path
          d="M79.279 9.1579V29.8318C85.82 30.0883 92.237 31.8512 98.034 35.0244L108.047 17.107C99.212 12.1708 89.354 9.41432 79.279 9.1579Z"
          fill="#FFB55E"
        />
      </g>
    </svg>
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      class="needle"
      width="31.95"
      :style="needleStyle"
      viewBox="0 0 63.9 33.2"
      xml:space="preserve"
    >
      <path
        class="st0"
        d="M52.9,30.2c7.5-3.1,11.1-11.7,8-19.2C57.7,3.5,49.1-0.1,41.6,3c-7.5,3.1-11.1,11.7-8,19.2
	C36.7,29.8,45.4,33.3,52.9,30.2z"
      />
      <path class="st0" d="M0,17.2L55.8,7L56,26.3L0,17.2z"/>
      <path
        class="st1"
        d="M47.2,7.9c4.8,0,8.7,3.9,8.7,8.7s-3.9,8.7-8.7,8.7s-8.7-3.9-8.7-8.7S42.4,7.9,47.2,7.9z"
      />
    </svg>
  </div>
</template>
<script>
import {isFinite} from "lodash"

export default {
  name: "op-cyberscore-gauge",
  props: {
    score: {
      default() {
        return 0
      },
    },
  },
  data() {
    return {
      angle: 0,
    }
  },
  watch: {
    score(newScore, oldScore) {
      this.updateAngle(newScore)
    },
  },
  mounted() {
    this.updateAngle(this.score)
  },
  computed: {
    needleStyle() {
      return {transform: `rotate(${this.angle}deg)`}
    },
  },
  methods: {
    updateAngle(score) {
      setTimeout(() => {
        if (isFinite(score)) {
          this.angle = score * 1.8
        } else {
          this.angle = 0
        }
      }, 1000)
    },
  },
}
</script>
<style scoped lang="scss">
.st0 {
  fill: #333333;
}

.st1 {
  fill: #989898;
}

.gauge {
  position: relative;

  & .needle {
    position: absolute;
    width: 1.5rem; /* Taille réduite */
    left: 21px; /* Ajusté pour la nouvelle échelle */
    bottom: 4.5px; /* Ajusté pour la nouvelle échelle */
    transform-origin: 18px 6.5px; /* Ajusté pour la nouvelle échelle */
    transition: all 1s ease, steps(6, start),
    linear(
        0,
        0.218 2.1%,
        0.862 6.5%,
        1.114,
        1.296 10.7%,
        1.346,
        1.37 12.9%,
        1.373,
        1.364 14.5%,
        1.315 16.2%,
        1.032 21.8%,
        0.941 24%,
        0.891 25.9%,
        0.877,
        0.869 27.8%,
        0.87,
        0.882 30.7%,
        0.907 32.4%,
        0.981 36.4%,
        1.012 38.3%,
        1.036,
        1.046 42.7% 44.1%,
        1.042 45.7%,
        0.996 53.3%,
        0.988,
        0.984 57.5%,
        0.985 60.7%,
        1.001 68.1%,
        1.006 72.2%,
        0.998 86.7%,
        1
    ) 5s;
  }
}
</style>
