<script>
import {Pie} from "vue-chartjs"

export default {
  extends: Pie,
  name: "pie-chart",
  props: {
    chartData: {
      default: null,
    },
  },

  data: function () {
    return {
      options: {
        responsive: true,
        maintainAspectRatio: false,
        animation: {
          animateRotate: false,
        },
      },
    }
  },

  methods: {
    update() {
      this.$data._chart.update()
    },
  },

  mounted() {
    if (this.chartData) {
      this.renderChart(this.chartData, this.options)
    }
  },
}
</script>
