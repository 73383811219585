<template>
  <v-dialog
    v-model="dialog"
    :width="options.width"
    :max-width="options.maxWidth"
    :style="{ zIndex: options.zIndex }"
    @keydown.esc="cancel"
  >
    <v-card>
      <v-card-title class="text-center ma-4" style="display: flex; justify-content: center; align-items: center;">
        {{ $t("view.companies.creation_edition_of_smart_groups") }}
      </v-card-title>

      <v-divider></v-divider>

      <v-card-text class="pa-20 mt-4">
        <v-row>
          <v-text-field v-model="name" :label="$t('view.dashboard.name')" required outlined filled></v-text-field>
        </v-row>
        <v-row>
          <v-checkbox
            name="manualDeletion"
            v-model="manualDeletion"
            :label="$t('view.companies.manual_employee_deletion')"
          >
          </v-checkbox>

          <i class="ma-2 pa-2">
            {{ $t("view.companies.smart_group_message") }}
          </i>
        </v-row>

        <v-row>
          <v-select
            v-model="smartGroupType"
            :items="smartGroupTypes"
            chips
            :label="$t('view.companies.type_of_smart_group')"
          ></v-select>
        </v-row>

        <v-container v-if="'from_simulations' === smartGroupType">
          <v-row v-if="simulations">
            <v-select
              v-model="selectedSimulations"
              :items="simulations"
              multiple
              chips
              item-text="name"
              item-value="id"
              :label="$t('view.companies.Simulations_selection')"
            ></v-select>
          </v-row>

          <v-row v-if="simulations">
            <v-switch
              v-model="hookedInSimulation"
              hide-details
              inset
              :label="$t('view.companies.employees_phished') + ` : ${hookedInSimulation.toString()}`"
            ></v-switch>
          </v-row>
        </v-container>

        <v-container v-if="'new_employees' === smartGroupType">
          <div>
            {{ $t("view.companies.smart_group_message2") }}
          </div>
        </v-container>

        <v-container v-if="'all_staff' === smartGroupType">
          <div class="ma-2">
            <i>{{ $t("view.companies.group_creation_with_all_active_employees") }}</i>
          </div>

          <div><b>{{ $t("view.companies.filters") }}: </b></div>

          <v-row class="mt-4">
            <v-col cols="6">
              <v-select
                v-model="selectedFilters.langs"
                :items="employeeFilters.langs"
                multiple
                chips
                :label="$t('view.learning_phishing.languages')"
              ></v-select>
            </v-col>

            <v-col cols="6">
              <v-select
                v-model="selectedFilters.entities"
                :items="employeeFilters.entities"
                multiple
                chips
                :label="$t('view.companies.entities')"
              ></v-select>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="6">
              <v-select
                v-model="selectedFilters.jobs"
                :items="employeeFilters.jobs"
                multiple
                chips
                :label="$t('view.companies.jobs')"
              ></v-select>
            </v-col>

            <v-col cols="6">
              <v-select
                v-model="selectedFilters.manager_emails"
                :items="employeeFilters.manager_emails"
                multiple
                chips
                :label="$t('view.companies.manager_emails')"
              ></v-select>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="6">
              <v-select
                v-model="selectedFilters.departments"
                :items="employeeFilters.departments"
                multiple
                chips
                :label="$t('view.companies.deparments')"
              ></v-select>
            </v-col>

            <v-col cols="6">
              <v-select
                v-model="selectedFilters.countries"
                :items="employeeFilters.countries"
                multiple
                chips
                :label="$t('view.companies.countries')"
              ></v-select>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="6">
              <v-select
                v-model="selectedFilters.cities"
                :items="employeeFilters.cities"
                multiple
                chips
                :label="$t('view.companies.cities')"
              ></v-select>
            </v-col>
            <v-col cols="6"></v-col>
          </v-row>
        </v-container>
      </v-card-text>

      <v-card-actions class="pt-3">
        <v-spacer></v-spacer>

        <v-btn
          v-if="!options.noconfirm"
          color="grey"
          text
          class="body-2 font-weight-bold text-none rounded-lg"
          @click.native="cancel"
        >{{ $t("view.companies.give_up") }}
        </v-btn>

        <v-btn
          color="green"
          class="body-2 font-weight-bold text-none rounded-lg"
          outlined
          @click.native="save"
        >{{ $t("view.button.save") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "op-smart-group",

  data() {
    return {
      dialog: false,
      selectedSimulations: null,
      resolve: null,
      reject: null,
      smartGroupTypes: ["all_staff", "from_simulations", "new_employees"],
      smartGroupType: null,
      options: {
        color: "grey lighten-3",
        width: '80%',
        maxWidth: 1300,
        zIndex: 200,
        noconfirm: false,
      },
      simulations: null,
      employeeFilters: null,
      selectedFilters: null,
      manualDeletion: false,
      name: "",
      hookedInSimulation: true,
    }
  },

  methods: {
    open(simulations, employeeFilters, rules = null) {
      this.simulations = simulations

      this.employeeFilters = employeeFilters

      this.selectedFilters = {
        cities: [],
        langs: [],
        manager_emails: [],
        entities: [],
        jobs: [],
        departments: [],
        countries: [],
      }

      if (rules && rules.type) {
        this.smartGroupType = rules.type

        if ("from_simulations" === rules.type) {
          this.selectedSimulations = rules.simulations_ids
          this.hookedInSimulation =
            rules.simulations_status === "hooked" ||
            rules.simulations_status === "failed"
        } else if ("all_staff" === rules.type && rules.filters) {
          this.selectedFilters = rules.filters
        }
      } else {
        this.smartGroupType = null
      }

      if (rules && rules.manual_deletion) {
        this.manualDeletion = rules.manual_deletion
      } else {
        this.manualDeletion = false
      }

      this.name = rules && rules.name ? rules.name : ""

      this.dialog = true

      return new Promise((resolve, reject) => {
        this.resolve = resolve
        this.reject = reject
      })
    },

    save() {
      let rules = {}
      rules.name = this.name
      rules.manual_deletion = this.manualDeletion
      rules.type = this.smartGroupType

      if ("from_simulations" === this.smartGroupType) {
        if (!this.selectedSimulations || this.selectedSimulations.length <= 0) {
          this.cancel()
        }

        rules.simulations_ids = this.selectedSimulations
        rules.simulations_status = this.hookedInSimulation
          ? "hooked"
          : "not_hooked" //failed
      } else if ("all_staff" === this.smartGroupType) {
        rules.filters = this.selectedFilters
      }

      this.resolve(rules)
      this.dialog = false
    },

    cancel() {
      this.resolve(null)
      this.dialog = false
    },
  },
}
</script>
