<template>
  <!-- SRC = https://github.com/HatScripts/circle-flags/tree/gh-pages/flags -->
  <svg
    v-if="name === 'en'"
    xmlns="http://www.w3.org/2000/svg"
    class="language-flag"
    viewBox="0 0 512 512"
    preserveAspectRatio="none"
  >
    <g mask="url(#a)">
      <path
        fill="#eee"
        d="M0 0h47.4l76.4 21 65.4-21h33.4l34.2 16.6L289.4 0h33.4l70.4 22.8L464.8 0h15.8l12.2 7.3L512 0v47.3l-19.9 78 19.9 63.9v33.4l-16.4 30.6 16.4 36.2v33.4l-15.1 68.7 15.1 73.3v15.9l-7.8 10.9L512 512h-47.3l-71-17.5-70.9 17.5h-33.4l-30-19.7-36.8 19.7h-33.3l-63.7-20.2L47.3 512H31.4l-10.6-8L0 512v-47.3l22.8-79L0 322.9v-33.4l25.3-32L0 222.6v-33.4l22.2-64.6L0 47.2V31.4l8-7.8z"
      />
      <path
        fill="#0052b4"
        d="m47.4 0 141.8 142V0H47.4zm275.4 0v142l142-142h-142zM0 47.2v142h142L0 47.2zm512 .1L370.1 189.2H512V47.3zM0 322.8v141.9l141.9-141.9H0zm370 0 142 142v-142H370zM189.3 370l-142 142h142V370zm133.5.1V512h141.9L322.8 370.1z"
      />
      <path
        fill="#d80027"
        d="M222.6 0v222.6H0v66.8h222.6V512h66.8V289.4H512v-66.8H289.4V0h-66.8z"
      />
      <path
        fill="#d80027"
        d="M0 0v31.4l157.7 157.8h31.5L0 0zm480.6 0L322.8 157.7v31.5L512 0h-31.4zM189.2 322.8 0 512h31.4l157.8-157.7v-31.5zm133.6 0L511.9 512h.1v-31.3L354.3 322.8h-31.5z"
      />
    </g>
  </svg>

  <svg
    v-else-if="name === 'fr'"
    xmlns="http://www.w3.org/2000/svg"
    class="language-flag"
    viewBox="0 0 512 512"
    preserveAspectRatio="none"
  >
    <g mask="url(#a)">
      <path fill="#eee" d="M167 0h178l25.9 252.3L345 512H167l-29.8-253.4z" />
      <path fill="#0052b4" d="M0 0h167v512H0z" />
      <path fill="#d80027" d="M345 0h167v512H345z" />
    </g>
  </svg>

  <svg
    v-else-if="name === 'ro'"
    xmlns="http://www.w3.org/2000/svg"
    class="language-flag"
    viewBox="0 0 512 512"
    preserveAspectRatio="none"
  >
    <g mask="url(#a)">
      <path fill="#ffda44" d="M167 0h178l25.9 252.3L345 512H167l-29.8-253.4z" />
      <path fill="#0052b4" d="M0 0h167v512H0z" />
      <path fill="#d80027" d="M345 0h167v512H345z" />
    </g>
  </svg>

  <svg
    v-else-if="name === 'es'"
    xmlns="http://www.w3.org/2000/svg"
    class="language-flag"
    viewBox="0 0 512 512"
    preserveAspectRatio="none"
  >
    <mask id="b">
      <path
        fill="#fff"
        d="M160 272v24a24 24 0 0 0 24 24 24 24 0 0 0 24-24v-24h-24z"
      />
    </mask>
    <g mask="url(#a)">
      <path fill="#d80027" d="M0 0h512v512H0z" />
      <path fill="#ffda44" d="M0 128h512v256H0z" />
      <g fill="#eee">
        <path d="M144 304h-16v-80h16zm128 0h16v-80h-16z" />
        <ellipse cx="208" cy="296" rx="48" ry="32" />
      </g>
      <g fill="#d80027">
        <rect width="16" height="24" x="128" y="192" rx="8" />
        <rect width="16" height="24" x="272" y="192" rx="8" />
        <path d="M208 272v24a24 24 0 0 0 24 24 24 24 0 0 0 24-24v-24h-24z" />
      </g>
      <g fill="#ff9811">
        <rect width="32" height="16" x="120" y="208" ry="8" />
        <rect width="32" height="16" x="264" y="208" ry="8" />
        <rect width="32" height="16" x="120" y="304" rx="8" />
        <rect width="32" height="16" x="264" y="304" rx="8" />
        <path d="M160 272v24a24 24 0 0 0 24 24 24 24 0 0 0 24-24v-24h-24z" />
      </g>
      <path d="M122 252h172m-172 24h28m116 0h28" />
      <path
        fill="#d80027"
        d="M122 248a4 4 0 0 0-4 4 4 4 0 0 0 4 4h172a4 4 0 0 0 4-4 4 4 0 0 0-4-4zm0 24a4 4 0 0 0-4 4 4 4 0 0 0 4 4h28a4 4 0 0 0 4-4 4 4 0 0 0-4-4zm144 0a4 4 0 0 0-4 4 4 4 0 0 0 4 4h28a4 4 0 0 0 4-4 4 4 0 0 0-4-4z"
      />
      <path
        fill="#eee"
        d="M196 168c-7 0-13 5-15 11l-5-1c-9 0-16 7-16 16s7 16 16 16c7 0 13-4 15-11a16 16 0 0 0 17-4 16 16 0 0 0 17 4 16 16 0 1 0 10-20 16 16 0 0 0-27-5c-3-4-7-6-12-6zm0 8c5 0 8 4 8 8 0 5-3 8-8 8-4 0-8-3-8-8 0-4 4-8 8-8zm24 0c5 0 8 4 8 8 0 5-3 8-8 8-4 0-8-3-8-8 0-4 4-8 8-8zm-44 10 4 1 4 8c0 4-4 7-8 7s-8-3-8-8c0-4 4-8 8-8zm64 0c5 0 8 4 8 8 0 5-3 8-8 8-4 0-8-3-8-7l4-8 4-1z"
      />
      <path fill="none" d="M220 284v12c0 7 5 12 12 12s12-5 12-12v-12z" />
      <path fill="#ff9811" d="M200 160h16v32h-16z" />
      <path fill="#eee" d="M208 224h48v48h-48z" />
      <g fill="#d80027">
        <path
          d="m248 208-8 8h-64l-8-8c0-13 18-24 40-24s40 11 40 24zm-88 16h48v48h-48z"
        />
        <rect width="20" height="32" x="222" y="232" rx="10" ry="10" />
        <g mask="url(#b)">
          <path d="M170 272h10v48h-10zm19 0h10v48h-10z" />
        </g>
      </g>
      <path
        fill="#ff9811"
        d="M168 232v8h8v16h-8v8h32v-8h-8v-16h8v-8zm8-16h64v8h-64z"
      />
      <g fill="#ffda44">
        <circle cx="186" cy="202" r="6" />
        <circle cx="208" cy="202" r="6" />
        <circle cx="230" cy="202" r="6" />
      </g>
      <g fill="#338af3">
        <circle cx="208" cy="272" r="16" />
        <rect width="32" height="16" x="264" y="320" ry="8" />
        <rect width="32" height="16" x="120" y="320" ry="8" />
      </g>
    </g>
  </svg>

  <svg
    v-else-if="name === 'it'"
    xmlns="http://www.w3.org/2000/svg"
    class="language-flag"
    viewBox="0 0 512 512"
    preserveAspectRatio="none"
  >
    <g mask="url(#a)">
      <path fill="#eee" d="M167 0h178l25.9 252.3L345 512H167l-29.8-253.4z" />
      <path fill="#6da544" d="M0 0h167v512H0z" />
      <path fill="#d80027" d="M345 0h167v512H345z" />
    </g>
  </svg>

  <svg
    v-else-if="name === 'de'"
    xmlns="http://www.w3.org/2000/svg"
    class="language-flag"
    viewBox="0 0 512 512"
    preserveAspectRatio="none"
  >
    <g mask="url(#a)">
      <path fill="#ffda44" d="m0 345 256.7-25.5L512 345v167H0z" />
      <path fill="#d80027" d="m0 167 255-23 257 23v178H0z" />
      <path fill="#333" d="M0 0h512v167H0z" />
    </g>
  </svg>

  <svg
    v-else-if="name === 'nl'"
    xmlns="http://www.w3.org/2000/svg"
    class="language-flag"
    viewBox="0 0 512 512"
    preserveAspectRatio="none"
  >
    <g mask="url(#a)">
      <path fill="#eee" d="m0 167 253.8-19.3L512 167v178l-254.9 32.3L0 345z" />
      <path fill="#a2001d" d="M0 0h512v167H0z" />
      <path fill="#0052b4" d="M0 345h512v167H0z" />
    </g>
  </svg>

  <svg
    v-else-if="name === 'tr'"
    xmlns="http://www.w3.org/2000/svg"
    class="language-flag"
    viewBox="0 0 512 512"
    preserveAspectRatio="none"
  >
    <g mask="url(#a)">
      <path fill="#d80027" d="M0 0h512v512H0z" />
      <g fill="#eee">
        <path
          d="m245.5 209.2 21 29 34-11.1-21 29 21 28.9-34-11.1-21 29V267l-34-11.1 34-11z"
        />
        <path
          d="M188.2 328.3a72.3 72.3 0 1 1 34.4-136 89 89 0 1 0 0 127.3 72 72 0 0 1-34.4 8.7z"
        />
      </g>
    </g>
  </svg>

  <svg
    v-else-if="name === 'pl'"
    xmlns="http://www.w3.org/2000/svg"
    class="language-flag"
    viewBox="0 0 512 512"
    preserveAspectRatio="none"
  >
    <g mask="url(#a)">
      <path fill="#d80027" d="m0 256 256.4-44.3L512 256v256H0z" />
      <path fill="#eee" d="M0 0h512v256H0z" />
    </g>
  </svg>

  <svg
    v-else-if="name === 'pt'"
    xmlns="http://www.w3.org/2000/svg"
    class="language-flag"
    viewBox="0 0 512 512"
    preserveAspectRatio="none"
  >
    <g fill="none">
      <path fill="#6da544" d="M0 0h170.7v512H0z" />
      <path fill="#d80027" d="M170.7 0H512v512H170.7z" />
    </g>
    <circle cx="170.7" cy="256" r="85.3" fill="#ffda44" />
    <path
      fill="#d80027"
      d="M170.7 170.7c-14.1 0-25.7 11.5-25.7 25.6V256c0 14.1 11.5 25.7 25.7 25.7s25.7-11.5 25.7-25.6v-59.7c0-14.2-11.6-25.7-25.7-25.7zm-12.9 85.3v-59.7h12.9v59.7h-12.9zm25.7 0h-12.8v-59.7h12.8v59.7z"
    />
    <path
      fill="#eee"
      d="M158.4 273.5v-28.6h-11.6v-14.3h11.6v-14.3h14.2v14.3h11.6v14.3h-11.6v28.6h-14.2z"
    />
    <path
      fill="#ff9811"
      d="M158.4 201.8h28.6v14.3h-28.6zM158.4 216.1h28.6v14.3h-28.6z"
    />
  </svg>

  <svg
    v-else-if="name === 'sv'"
    xmlns="http://www.w3.org/2000/svg"
    class="language-flag"
    viewBox="0 0 512 512"
    preserveAspectRatio="none"
  >
    <path fill="#0052b4" d="M0 0h512v512H0z" />
    <path fill="#ffda44" d="M192 0h64v512h-64z" />
    <path fill="#ffda44" d="M0 192h512v64H0z" />
  </svg>
</template>

<script>
export default {
  name: "Language-flag-component",
  props: {
    name: {
      type: String,
      required: true,
    },
  },
}
</script>
<style>
.language-flag {
  width: 1.25rem;
  height: 0.9rem;
}
</style>
