<template>
  <v-card class="ma-2">
    <v-card-title>{{ asset.name }}</v-card-title>
    <v-card-text>
      <div>{{$t("view.learning_phishing.type")}}: {{ asset.type }}</div>
      <div>{{$t("view.learning_phishing.date")}}: {{ formatDate(asset.date) }}</div>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "op-asset-item",
  props: {
    asset: {
      type: Object,
      required: true,
    },
  },
  methods: {
    formatDate(timestamp) {
      return new Date(timestamp).toLocaleDateString()
    },
  },
}
</script>

<style scoped>
/* Add styles here if needed */
</style>
