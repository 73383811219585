<template>
  <div v-if="data">
    <v-card elevation="0" outlined class="ma-1">
      <span>
            <op-confirmation-dialog ref="confirm" />
        <v-card-title>
          <v-tooltip slot="append" top>
            <template v-slot:activator="{ on }">
              <v-chip v-if="data.type === 'normal'"
                      v-on="on"
                      class="id-icon mx-2"
                      small
                      label
                      dark
                      color="blue"
              >{{ data.id }}</v-chip
              >
              <v-chip v-else
                      v-on="on"
                      class="id-icon mx-2"
                      small
                      label
                      dark
                      color="red"
              >{{ data.id }}</v-chip
              >              
            </template>
            <span>{{ $t("ID") }}</span>
          </v-tooltip>
          <v-tooltip slot="append" top>
            <template v-slot:activator="{ on }">
              <v-chip v-if="data.type === 'normal'"
                      v-on="on"
                      class="id-icon mx-2"
                      small
                      label
                      dark
                      color="blue"
              >{{ data.type }}</v-chip
              >
              <v-chip v-else
                      v-on="on"
                      class="id-icon mx-2"
                      small
                      label
                      dark
                      color="red"
              >{{ data.type }}</v-chip
              >
            </template>
            <span>{{ $t("navigation.system.announcement") }}</span>
          </v-tooltip>
        </v-card-title>
      </span>
      <v-card-subtitle>
        <div>{{ $t("announcement.description") }} : {{ data.metadata }}</div>
        <div>{{ $t("announcement.type") }} : {{ data.type }}</div>
        <!-- <div>{{ $t('announcement.announcement_language') }} : {{ data.language }}</div> -->
        <div>{{ $t("announcement.active") }} :
          <v-switch
            small
            v-model="data.is_active"
            color="green"
            class="ms-4"
            @click.prevent="update()"
          ></v-switch>
        </div>
        <div>
          {{ $t("Creation date") }} : {{ formatDate(data.created_at) }}
        </div>
        <div>
          {{ $t("Update date") }} : {{ formatDate(data.updated_at) }}
        </div>
      </v-card-subtitle>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn elevation="2" class="text-none rounded-lg" @click="duplicate()">{{ $t("Duplicate") }}</v-btn>
        <v-btn elevation="2" class="text-none rounded-lg" @click="edit()">{{ $t("Edit") }}</v-btn>
        <v-icon @click="suppress()" class="ml-2" elevation="2" icon
        >mdi-delete
        </v-icon
        >
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import moment from "moment"
import { i18n } from "@/plugins/i18n"

export default {
  name: "system-annoucement-template",
  props: {
    data: null,
  },
  methods: {
    //   async updateAnnouncement(item) {
    //   let announcement = item
    //     console.log("qqqq",announcement.is_active);
    //   const confirmMessage = announcement.is_active
    //     ? i18n.t("Are you sure you want to enable this alert ?")
    //     : i18n.t(
    //         "Are you sure you want to deactivate this alert ?"
    //       )

    //   const confirmationResult = await this.$refs.confirm.open(
    //     announcement.is_active
    //       ? i18n.t("Confirmation of activation")
    //       : i18n.t("Confirmation of deactivation"),
    //     confirmMessage,
    //     i18n.t("Cancel")
    //   )

    //   if (confirmationResult) {
    //     // await this.monitorServer(monitored)
    //     // await this.refreshServerList()
    //   }
    // },
    async update() {
      this.$emit("update", this.data)
    },
    async suppress() {
      this.$emit("suppress", this.data)
    },
    edit() {
      this.$emit("edit", this.data)
    },
    duplicate() {
      this.$emit("duplicate", this.data)
    },
    formatDate(date) {
      if (!date) return
      return moment(date).format("DD/MM/YYYY à HH:mm")
    },
  },
}
</script>
