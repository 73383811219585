<template>
  <div v-if="series" class="redborde"></div>
</template>

<script>
export default {
  name: "radial-chart",

  props: {
    labels: {type: Array, default: null},
    series: {type: Array, default: null},
  },

  data: () => ({
    options: {
      chart: {
        height: 350,
        type: "radialBar",
        offsetY: -10,
      },
      plotOptions: {
        radialBar: {
          startAngle: -135,
          endAngle: 135,
          dataLabels: {
            name: {
              fontSize: "16px",
              color: "#CFCFCF",
              offsetY: 10,
            },
            value: {
              offsetY: 20,
              fontSize: "22px",
              color: "#000",
              formatter: function (val) {
                return val + "%"
              },
            },
          },
        },
      },
      fill: {
        type: "gradient",
        colors: ["#1cc8aa", "#09db2f"],
        gradient: {
          shade: "dark",
          shadeIntensity: 0.15,
          inverseColors: false,
          opacityFrom: 1,
          opacityTo: 1,
          stops: [0, 50, 65, 91],
        },
      },
      stroke: {
        dashArray: 4,
      },
    },
  }),
  created() {
    if (this.labels) {
      this.options["labels"] = this.labels
    }
  },
}
</script>

<style lang="scss" scoped>
.redborder {
  border: 1px solid red;
}

.apexcharts-datalabel-label tspan:nth-child(2) {
  fill: #888;
}
</style>
