<template>
  <div v-if="localData">
    <op-modale
      :display="show"
      :data="html"
      v-on:closed="closedPreview"
    ></op-modale>
    <v-card elevation="0" outlined color="#fafafa" class="ma-1">
      <v-card-text>
        <v-text-field
          :label="$t('Title of template')"
          v-model="localData.title"
          class="required"
        />

        <v-text-field
          :label="$t('Type of template')"
          v-model="localData.type"
          class="required"
        />

        <v-text-field
          :label="$t('Email subject')"
          v-model="localData.subject"
          class="required"
        />

        <v-row class="ma-0" align="center">
          <v-select
            :label="$t('Header ID')"
            v-model="localData.email_header_id"
            :items="headers_list"
            item-text="id"
            item-value="id"
            class="required pr-2"
            @change="onHeaderSelect"
          ></v-select>

          <v-select
            :label="$t('Footer ID')"
            v-model="localData.email_footer_id"
            :items="footers_list"
            item-text="id"
            item-value="id"
            class="required"
            @change="onFooterSelect"
          ></v-select>
        </v-row>

        <custom-editor
          :value="localData.content"
          v-model="localData.content"
          :isSlide="false"
        />

        <v-select
          :label="$t('Language')"
          v-model="localData.lang"
          :items="languages"
          item-text="name"
          item-value="id"
          class="required"
        ></v-select>

        <v-select
          :label="$t('Company')"
          v-model="localData.company_id"
          :items="companies"
          item-text="business_name"
          item-value="id"
        ></v-select>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn elevation="2" @click="submit()" dark color="green" class="text-none rounded-lg"
        >{{ $t('Save') }}
        </v-btn
        >
        <v-btn elevation="2" @click="close()" class="text-none rounded-lg">{{ $t('Close') }}</v-btn>
        <v-btn elevation="2" @click="openPreview()" class="text-none rounded-lg">{{ $t('Preview') }}</v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import CustomEditor from "../../learning/edition/CustomEditor.vue"

export default {
  name: "system-notifications-template-edition",
  components: {
    CustomEditor,
  },
  props: {
    data: {
      default: null,
    },
    languages: {
      default: null,
    },
    headers_list: {
      default: null,
    },
    footers_list: {
      default: null,
    },
    companies: {
      default: null,
    },
  },
  data: function () {
    return {
      localData: null,
      show: false,
      html: null,
      selectedHeader: {},
      selectedFooter: {},
    }
  },
  mounted() {
    this.localData = JSON.parse(JSON.stringify(this.data))
    this.initHeaderAndFooter()
  },
  computed: {
    form() {
      return this.$refs.form
    },
  },
  methods: {
    async submit() {
      this.$emit("save", this.localData)
    },
    close() {
      this.$emit("close")
    },
    openPreview() {
      this.html =
        this.selectedHeader.attributes.content +
        this.localData.content +
        this.selectedFooter.attributes.content

      this.show = true
    },
    closedPreview() {
      this.html = null
      this.show = false
    },
    getIdsFooterAndHeader() {
      if (this.headers_list.length > 0 && !this.localData.email_header) {
        this.localData.email_header_id = this.headers_list[0].id
      } else {
        this.localData.email_header_id = this.localData.email_header.id
      }

      if (this.footers_list.length > 0 && !this.localData.email_footer) {
        this.localData.email_footer_id = this.footers_list[0].id
      } else {
        this.localData.email_footer_id = this.localData.email_footer.id
      }
    },
    onHeaderSelect() {
      this.selectedHeader = this.headers_list.find(
        (header) => header.id === this.localData.email_header_id
      )
    },
    onFooterSelect() {
      this.selectedFooter = this.footers_list.find(
        (footer) => footer.id === this.localData.email_footer_id
      )
    },
    initHeaderAndFooter() {
      this.getIdsFooterAndHeader()
      this.onHeaderSelect()
      this.onFooterSelect()
    },
  },
}
</script>
