import CompanyLogic from "../logics/CompanyLogic"
import utilsMixin from "@/mixins/utils.mixin"

export default {
  mixins: [utilsMixin],
  methods: {
    getAvailableEmailTypes(lang) {
      return this._withLoading(() =>
        CompanyLogic.getAvailableEmailTypes(this.companyUuid,lang),
      )
    },
    getEmailTypes(emailType,lang, hatcourseId) {
      return this._withLoading(() =>
        CompanyLogic.getEmailTypes(this.companyUuid,emailType,lang, hatcourseId)
      )
    },
  },
}
