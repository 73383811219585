<template>
  <v-card elevation="0" outlined class="ma-1 pa-2" v-if="company">
    <v-card-title>
      <v-row>
        <span class="mainHead">
          <span> {{ company.business_name }}</span>
          <v-icon
            class="ml-2"
            elevation="2"
            icon
            v-if="company.verified"
            color="#03A9F4"
            small
          >mdi-star</v-icon
          >
          <v-icon
            class="ml-2"
            elevation="2"
            icon
            v-if="company.is_msp"
            color="#FF9800"
          >mdi-hexagon-multiple</v-icon
          >
        </span>
        <op-company-cyberscore
          v-if="company.gamification"
          :show-breakdown="false"
          :gamification-info="company.gamification"
        ></op-company-cyberscore>
      </v-row>

      <div>
        <v-spacer></v-spacer>

        <v-chip
          :to="'company-management/' + company.uuid + '/programs'"
          class="text-none rounded-lg"
          label
          outlined
          dark
          color="#727272"
        >{{ $t("profile.manage") }}
        </v-chip
        >

        <v-tooltip slot="append" bottom>
          <template v-slot:activator="{ on }">
            <v-btn
              class="ml-2"
              small
              @click="toggleFull()"
              style="float: right"
              v-on="on"
            >
              <v-icon dark small v-if="partialMode">mdi-fullscreen</v-icon>
              <v-icon dark small v-if="!partialMode">mdi-fullscreen-exit</v-icon>
            </v-btn>
          </template>

          <span>{{ $t("profile.overview") }}</span>
        </v-tooltip>

        <v-tooltip slot="append" bottom>
          <template v-slot:activator="{ on }">
            <v-btn
              class="ml-2"
              small
              @click="toggleFiles()"
              style="float: right"
              v-on="on"
            >
              <v-icon dark small>mdi-cloud
              </v-icon
              >
            </v-btn>
          </template>

          <span>{{ $t("view.companies.files") }}</span>
        </v-tooltip>

        <v-tooltip slot="append" bottom>
          <template v-slot:activator="{ on }">
            <v-btn
              class="ml-2"
              small
              @click="edit()"
              style="float: right"
              v-on="on"
            >
              <v-icon dark small>mdi-pencil</v-icon>
            </v-btn>
          </template>

          <span>{{ $t("profile.edition") }}</span>
        </v-tooltip>
      </div>
    </v-card-title>

    <v-card-text v-if="!partialMode">
      <div>
        <div class="line">
          <span class="_title"> {{ $t("profile.business_name") }} : </span>
          {{ company.business_name }}
        </div>

        <div class="line">
          <span class="_title"> {{ $t("profile.email_adress") }} : </span>
          {{ company.email_address }}
        </div>

        <div class="line">
          <span class="_title"> {{ $t("profile.trade_name") }} : </span> {{ company.trade_name }}
        </div>

        <div class="line">
          <span class="_title"> {{ $t("profile.siren_number") }} : </span>{{ company.siren_number }}
        </div>

        <div class="line">
          <span class="_title"> {{ $t("profile.employees_auth_method") }} : </span>
          {{ company.employees_auth_method }}
        </div>
      </div>
    </v-card-text>

    <v-card-text v-if="viewBlocFiles">
      <div>
        <ViewFiles :company="company" class="mt-2"/>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import ViewFiles from "../companies/ViewCompanyFiles.vue"

export default {
  name: "oppens-company",

  components: {
    ViewFiles,
  },

  props: {
    company: {
      default: null,
    },
    partialMode: {
      default: false,
    },
  },

  data: () => ({
    viewBlocFiles: false,
  }),

  mounted() {
  },

  methods: {
    toggleFull() {
      this.partialMode = !this.partialMode
    },

    toggleFiles() {
      this.viewBlocFiles = !this.viewBlocFiles
    },

    async suppress() {
      this.$emit("suppress", this.company)
    },

    edit() {
      this.$emit("edit", this.company)
    },
  },
}
</script>

<style scoped lang="scss">
.box {
  width: 100%;
}

.mainHead {
  min-width: 250px;
}

.line {
  margin-bottom: 5px;

  ._title {
    font-weight: bold;
  }
}

.edit-btn {
  font-size: 21px;
  padding: 3px 5px 3px 10px;
}

.search-btn {
  font-size: 25px;
  padding: 3px 5px 3px 10px;
}
</style>
