<template>
  <div>
    <v-card elevation="0" outlined color="white" class="ma-1">
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn elevation="2" @click="submit()" dark color="green" class="text-none rounded-lg"
        >{{ $t("view.button.save") }}
        </v-btn>
        <v-btn elevation="2" @click="close()" class="text-none rounded-lg">{{ $t("view.button.close") }}</v-btn>
      </v-card-actions>

      <v-card-text>
        <v-row v-if="localData">
          <pre>{{ localData }}</pre>
        </v-row>

        <v-row>
          <v-file-input
            chips
            show-size
            counter
            accept="image/*"
            truncate-length="15"
            v-model="image"
            @change="onFileChange"
            :label="$t('Upload image')"
          ></v-file-input>
        </v-row>
        <v-row>
          <h3>{{ $t("view.learning_phishing.preview") }}</h3>
          <v-img
            style="border: 1px dashed #ccc; min-height: 250px"
            :src="imageUrl"
          />
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import oppensLearningMixin from "@/mixins/oppens.learning.mixin.js"

export default {
  mixins: [oppensLearningMixin],
  name: "oppens-learning-asset-edition",
  props: {
    data: {
      default: null,
    },
  },

  data: function () {
    return {
      localData: null,
      image: null,
      imageUrl: "",
      progress: 0,
      message: "",
      imageInfos: [],
    }
  },

  async mounted() {
    this.localData = JSON.parse(JSON.stringify(this.data))
  },

  computed: {
    form() {
      return this.$refs.form
    },
  },

  methods: {
    createImage(file) {
      const reader = new FileReader()
      reader.onload = (e) => {
        this.imageUrl = e.target.result
      }
      reader.readAsDataURL(file)
    },

    onFileChange(file) {
      if (!file) {
        return
      }
      this.createImage(file)
    },

    async submit() {
      if (!this.image) {
        return
      }

      let formData = new FormData()
      formData.append("file", this.image)

      this.$emit("save", formData)
    },

    close() {
      this.$emit("close")
    },
  },
}
</script>
