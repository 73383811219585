import PhishingExerciseCatalogsLogic from "../logics/PhishingExerciseCatalogsLogic"
import utilsMixin from "./utils.mixin"

export default {
  mixins: [utilsMixin],

  methods: {
    async getAllCatalogs() {
      return this._withLoading(() =>
        PhishingExerciseCatalogsLogic.getAllCatalogs()
      )
    },

    async getCatalogExercises(catalogId) {
      return this._withLoading(() =>
        PhishingExerciseCatalogsLogic.getCatalogExercises(catalogId)
      )
    },

    async createCatalog(body) {
      return this._withLoading(() =>
        PhishingExerciseCatalogsLogic.createCatalog(body)
      )
    },

    async updateCatalog(catalogId, body) {
      return this._withLoading(() =>
        PhishingExerciseCatalogsLogic.updateCatalog(catalogId, body)
      )
    },

    async deleteCatalog(catalogId) {
      return this._withLoading(() =>
        PhishingExerciseCatalogsLogic.deleteCatalog(catalogId)
      )
    },

    async addPhishingExerciseToCatalog(catalogId, exerciseId) {
      return this._withLoading(() =>
        PhishingExerciseCatalogsLogic.addPhishingExerciseToCatalog(
          catalogId,
          exerciseId
        )
      )
    },

    async removePhishingExerciseFromCatalog(catalogId, exerciseId) {
      return this._withLoading(() =>
        PhishingExerciseCatalogsLogic.removePhishingExerciseFromCatalog(
          catalogId,
          exerciseId
        )
      )
    },

    async getCompanyCatalogs(companyUuid) {
      return this._withLoading(() =>
        PhishingExerciseCatalogsLogic.getCompanyCatalogs(companyUuid)
      )
    },

    async addCompanyToCatalog(companyUuid, catalogId) {
      return this._withLoading(() =>
        PhishingExerciseCatalogsLogic.addCompanyToCatalog(
          companyUuid,
          catalogId
        )
      )
    },

    async removeCompanyFromCatalog(companyUuid, catalogId) {
      return this._withLoading(() =>
        PhishingExerciseCatalogsLogic.removeCompanyFromCatalog(
          companyUuid,
          catalogId
        )
      )
    },
  },
}
