<template>
  <v-alert
    tile
    ref="opAlert"
    :type="alertData.type"
    :value="alertData.show"
    dismissible
  >
    <div class="title">{{ alertData.title }}</div>
    <div>
      {{ alertData.message }}
    </div>
  </v-alert>
</template>

<script>
export default {
  name: "op-browser-alert",
  computed: {},
  data() {
    return {
      alertData: {
        show: false,
        title: "",
        message: "",
        type: "info",
      },
    }
  },
  mounted() {
    let version = (typeof this.$browserDetect.meta !== "undefined" && typeof this.$browserDetect.meta.version !== "undefined") ? this.$browserDetect.meta.version : null;

    if (this.$browserDetect.isIE || (this.$browserDetect.isEdge && version < 116)) {
      this.alertData.title = this.$i18n.t("browserAlert.title")
      this.alertData.message = this.$i18n.t("browserAlert.message")
      this.alertData.type = "error"
      this.alertData.show = true
    }
  },
}
</script>
