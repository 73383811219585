<template>
  <v-menu rounded="lg" offset-y>
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        color="green"
        class="text-none rounded-lg"
        dark
        elevation="0"
        small
        v-bind="attrs"
        v-on="on"
      >
        <v-icon>mdi-eye-outline</v-icon>
        <!-- Preview -->
      </v-btn>
    </template>
    <v-list>
      <v-list-item
        v-for="(item, index) in options"
        :key="index"
        @click="onOptionSelected(item)"
      >
        <v-list-item-title>{{ item }}</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
export default {
  name: "op-preview-button",
  props: {
    value: {
      type: String,
      required: false,
    },
    options: {
      type: Array,
      required: true,
    },
  },
  methods: {
    onOptionSelected(value) {
      this.$emit('update', value);
    },
  },
}
</script>
