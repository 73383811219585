<template>
  <v-card class="ma-2" v-if="preset">
    <div class="d-flex justify-center justify-space-between">
      <div class="mt-2 ml-5">
        <div class="title">{{ preset.name }}</div>
        <div class="subtitle">{{ preset.description }}</div>
      </div>
      <div class="mt-2 mr-2">
        <v-icon class="mx-2" @click="instantiate()"> mdi-plus</v-icon>
      </div>
    </div>

    <v-card-text>
      <div
        v-for="(campaign, key, index) in preset.campaigns"
        :key="index"
        class="ma-2 campaign"
      >
        <div class="name">{{ campaign.name }}</div>
        <div class="desc">{{ campaign.description }}</div>

        <div class="ma-5">
          <div
            v-for="(course, key, index) in campaign.company_courses"
            :key="index"
            class="ma-2"
          >
            <h4>
              {{ course.name }}
              <i>{{ $t('Hat Course ID') }} : {{ course.hat_course_id }}</i>
            </h4>
            <div class="desc">{{ course.description }}</div>
          </div>

          <div
            v-for="(simulation, key, index) in campaign.simulations"
            :key="index"
            class="ma-2"
          >
            <h4>
              {{ simulation.name }}
              <i>
                {{ $t('Phishing exercise ID') }} : {{ simulation.phishing_exercise_id }}</i
              >
            </h4>
          </div>
        </div>
      </div>
    </v-card-text>
  </v-card>
</template>
<script>
import _ from "lodash"

export default {
  name: "program-preset",
  props: {
    data: {
      default: null,
    },
  },
  data: () => ({
    preset: null,
  }),
  async mounted() {
    this.preset = _.clone(this.data)
  },
  computed: {},
  methods: {
    async refresh() {
      this.programPresets = await this.getAllProgramPresets()
    },
    instantiate() {
      this.$emit("instantiate", this.preset)
    },
  },
}
</script>
<style lang="scss" scoped>
$color: #1876d2;

h4 {
  margin-bottom: 10px;
  border-bottom: $color dashed 1px;
  color: $color;
  text-transform: capitalize;

  i {
    font-size: 0.8em;
    color: #999;
  }
}

.title {
  text-transform: capitalize;
  font-size: 1.25rem;
  font-weight: 500;
  letter-spacing: 0.0125em;
  line-height: 2rem;
  word-break: break-all;
}

.subtitle {
  font-style: italic;
  color: #959595;
}

.section {
  font-weight: bold;
  font-size: 1.2em;
  margin-top: 1em;
}

.name {
  font-weight: bold;
  text-transform: capitalize;
  font-size: 1.1em;
  border-bottom: 1px solid #cfcfcf;
}

.desc {
  font-style: italic;
}

.campaign {
  margin-bottom: 1em;
}
</style>
