<template>
  <v-container style="background-color: white">
    <v-row>
      <v-col
        v-for="attributeToFilter in attributesToFilter"
        :key="attributeToFilter"
        cols="12"
        sm="4"
      >
        <v-select
          dense
          :items="headerFilters[attributeToFilter]"
          v-model="selectedHeaders[attributeToFilter]"
          @change="updateSearch"
          multiple
          chips
          small-chips
          deletable-chips
          flat
          clearable
          hide-details
          :placeholder="$t('employees.all')"
          :loading="employeeTableIsLoading"
          :disabled="employeeTableIsLoading"
          class="no-border"
        >
          <template v-slot:prepend>
            <div
              class="text-body-1 mr-2 grey--text text--darken-2 text-no-wrap"
            >
              <v-icon small>mdi-filter</v-icon>
              {{ $t(`employees.${attributeToFilter}`) }}
            </div>
          </template>
          <template v-slot:prepend-item>
            <v-list-item disabled>
              <v-list-item-content>
                <v-list-item-title class="text-body-1">
                  {{ $t(`employees.${attributeToFilter}`) }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
          <template v-slot:selection="{ item, index }">
            <v-chip v-if="index === 0">
              <span class="text-body-1">{{ item.text }}</span>
            </v-chip>
            <v-tooltip bottom v-if="index === 1"
              ><template v-slot:activator="{ on, attrs }">
                <div
                  v-on="on"
                  v-bind="attrs"
                  class="grey--text text--darken-2 text-body-1"
                >
                  {{
                    $tc(
                      "employees.plusXOthers",
                      selectedHeaders[attributeToFilter].length - 1
                    )
                  }}
                </div></template
              ><span>{{
                getRemainingAttributes(selectedHeaders[attributeToFilter])
              }}</span></v-tooltip
            ></template
          >
          <template v-slot:item="{ item, on, attrs }">
            <v-list-item v-on="on" v-bind="attrs">
              <v-list-item-icon>
                <v-icon>
                  {{
                    selectedHeaders[attributeToFilter].includes(item.value)
                      ? "mdi-check"
                      : ""
                  }}
                </v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title class="text-left text-body-1">{{
                  item.text
                }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
        </v-select></v-col
      >
    </v-row>
    <v-row align="center">
      <v-col cols="12" sm="5" v-if="filterType >= 0">
        <op-toggle-buttons
          v-model="employeeFilter"
          :buttons="customFilters[filterType]"
          :disabled="employeeTableIsLoading"
        ></op-toggle-buttons>
      </v-col>
      <v-col class="d-flex align-center">
        <v-select
          dense
          :items="hideableColumns"
          v-model="columnSelection"
          @change="updateColumnSelection"
          item-text="text"
          return-object
          multiple
          chips
          small-chips
          deletable-chips
          flat
          clearable
          hide-details
          :placeholder="$t('employees.headers.default')"
          :loading="employeeTableIsLoading"
          class="no-border"
          v-if="hideableColumns.length > 0"
        >
          <template v-slot:prepend>
            <div
              class="text-body-1 mr-2 grey--text text--darken-2 text-no-wrap"
            >
              <v-icon small>mdi-eye</v-icon>
              {{ $t(`employees.headers.title`) }}
            </div>
          </template>
          <template v-slot:prepend-item>
            <v-list-item disabled>
              <v-list-item-content>
                <v-list-item-title class="text-body-1">
                  {{ $t(`employees.headers.title`) }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
          <template v-slot:selection="{ item, index }">
            <v-chip v-if="index < selectedColumnCount">
              <span class="text-body-1">{{ item.text }}</span>
            </v-chip>
            <v-tooltip bottom v-if="index === selectedColumnCount"
              ><template v-slot:activator="{ on, attrs }">
                <div
                  v-on="on"
                  v-bind="attrs"
                  class="grey--text text--darken-2 text-body-1"
                >
                  {{
                    $tc(
                      "employees.plusXOthers",
                      columnSelection.length - selectedColumnCount
                    )
                  }}
                </div></template
              ><span>{{
                getRemainingAttributes(
                  columnSelection.map((col) => col.text),
                  selectedColumnCount
                )
              }}</span></v-tooltip
            ></template
          >
          <template v-slot:item="{ item, on, attrs }">
            <v-list-item v-on="on" v-bind="attrs">
              <v-list-item-icon>
                <v-icon>
                  {{ columnSelection.includes(item) ? "mdi-check" : "" }}
                </v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title class="text-left text-body-1">{{
                  item.text
                }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
        </v-select>
      </v-col>
      <v-col cols="12" sm="2" class="d-flex justify-end"
        ><div
          class="text-h4"
          v-html="$tc('employees.totalNumber', totalEmployees)"
        ></div
      ></v-col>
    </v-row>
  </v-container>
</template>
<script>
import lodash from "lodash"

export default {
  name: "op-employee-table-filters",
  props: {
    employeeTableIsLoading: {
      type: Boolean,
      required: true,
    },
    totalEmployees: {
      type: Number,
      required: true,
    },
    filterType: {
      type: Number,
      default() {
        return -1
      },
    },
    filteringOptions: {
      type: Object,
    },
    hideableColumns: {
      type: Array,
      default() {
        return []
      },
    },
    selectedColumnCount: {
      type: Number,
      default() {
        return 1
      },
    },
  },
  data() {
    return {
      attributesToFilter: [ "entity", "lang", "active"], //"job",
      headerFilters: {},
      selectedHeaders: {
        position: [],
        job: [],
        entity: [],
        lang: [],
        active: [],
      },
      latsSelectedHeaders: null,
      employeeFilter: "all",
      lastEmployeeFilter: undefined,
      columnSelection: [],
      customFilters: [
        [
          { value: "all", label: this.$t("employees.typeFilter.all") },
          {
            value: "clicked",
            label: this.$t("employees.typeFilter.clicks"),
          },
          {
            value: "completed",
            label: this.$t("employees.typeFilter.data"),
          },
        ],
        [
          { value: "all", label: this.$t("employees.typeFilter.all") },
          { value: "missing", label: this.$t("employees.typeFilter.missing") },
          {
            value: "failure",
            label: this.$t("employees.typeFilter.failure"),
          },
          {
            value: "success",
            label: this.$t("employees.typeFilter.success"),
          },
        ],
      ],
    }
  },
  watch: {
    employeeFilter(newE, oldE) {
      this.updateSearch()
    },
    filteringOptions() {
      if (this.filteringOptions) {
        this.headerFilters = {
          position: [],
          job: this.filteringOptions.jobs
            .map((j) => {
              return { value: j, text: lodash.capitalize(j) }
            })
            .concat([{ value: "none", text: this.$t("employees.noJob") }]),
          entity: this.filteringOptions?.entities
            .map((e) => {
              return { value: e, text: lodash.capitalize(e) }
            })
            .concat([{ value: "none", text: this.$t("employees.noEntity") }]),
          lang: this.filteringOptions.lang
            .map((l) => {
              return { value: l, text: lodash.capitalize(l) }
            })
            .concat([{ value: "none", text: this.$t("employees.noLang") }]),
          active: this.filteringOptions.active
            .map((s) => {
              return { value: s, text: lodash.capitalize(s) }
            })
        }
      }
    },
    hideableColumns: {
      handler() {
        if (this.hideableColumns) {
          this.columnSelection = this.hideableColumns.filter(
            (col) => col.default === true
          )
        }
      },
      immediate: true,
    },
  },
  methods: {
    updateSearch() {
      if (
        !lodash.eq(this.lastSelectedHeaders, this.selectedHeaders) ||
        !lodash.eq(this.lastEmployeeFilter, this.employeeFilter)
      ) {
        this.lastSelectedHeaders = lodash.cloneDeep(this.selectedHeaders)
        this.lastEmployeeFilter = this.employeeFilter
        this.$emit("updateFilters", {
          selectedHeaders: this.selectedHeaders,
          employeeFilter: this.employeeFilter,
        })
      }
    },
    updateColumnSelection() {
      this.$emit(
        "updateColumnSelection",
        this.columnSelection.sort((a, b) => {
          return (
            this.hideableColumns.findIndex((el) => el === a) -
            this.hideableColumns.findIndex((el) => el === b)
          )
        })
      )
    },
    getRemainingAttributes(attributes, displayCount = 1) {
      return attributes
        .slice(displayCount)
        .map((attribute) => lodash.capitalize(attribute))
        .join(", ")
    },
  },
}
</script>
