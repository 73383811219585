<template>
  <v-container>
    <v-col>
      <!-- FIRST TAB -->
      <v-row v-if="firstTab" class="mx-2">
        <v-col cols="12" md="3" class="mt-14">
          <v-tooltip slot="append" bottom>
            <template v-slot:activator="{ on }">
              <v-btn v-on="on" class="button" small @click="reset()">
                <v-icon class="ma-2" color="blue">mdi-refresh</v-icon>
              </v-btn>
            </template>
            <span>{{ $t("view.button.reset") }}</span>
          </v-tooltip>

          <v-form v-if="localTags && localTags.email" class="mt-8">
            <div
              v-for="(value, key, index) in localTags.email.tags"
              :key="index"
            >
              <span class="subtitle"
                >{{ localTags.email.tags[key].name }} ({{
                  localTags.email.tags[key].type
                }})</span
              >
              <v-text-field
                v-model="localTags.email.tags[key].value"
                @input="onInputChange()"
                name="item.name"
              ></v-text-field>
            </div>
          </v-form>

          <v-alert
            v-else
            :elevation="4"
            class="mt-2"
            type="info"
            title="Alert title"
            variant="tonal"
            >{{ $t("view.companies.template_contains_no_tags") }}
          </v-alert>

          <v-btn
            elevation="2"
            dark
            color="green"
            @click="validate()"
            class="ma-4 text-none rounded-lg"
            >{{ $t("view.button.validate") }}
          </v-btn>
          <v-btn elevation="2" @click="cancel()" class="text-none rounded-lg">{{
            $t("view.button.cancel")
          }}</v-btn>
        </v-col>

        <v-col cols="12" md="9" class="mt-8">
          <span class="logoTab" @click="toggleTab()">
            <img src="../../../assets/logoEmail.png" alt="logo email"
          /></span>
          <span class="logoTab" @click="toggleTab()"
            ><img src="../../../assets/landingPage.png" alt="logo landing page"
          /></span>
          <v-card :elevation="4" class="mt-2">
            <v-responsive :min-height="600" :aspect-ratio="16 / 9">
              <iframe
                :srcdoc="emailTemplate"
                style="
                  border-style: none;
                  background-color: #fff;
                  width: 100%;
                  height: 100%;
                "
              ></iframe>
            </v-responsive>
          </v-card>
        </v-col>
      </v-row>

      <!-- SECOND TAB -->
      <v-row v-else class="mx-2">
        <v-col cols="12" md="3" class="mt-14">
          <v-tooltip slot="append" bottom>
            <template v-slot:activator="{ on }">
              <v-btn v-on="on" class="button" small @click="reset()">
                <v-icon class="ma-2" color="blue">mdi-refresh</v-icon>
              </v-btn>
            </template>
            <span>{{ $t("view.button.reset") }}</span>
          </v-tooltip>

          <v-form v-if="localTags && localTags.landingPage" class="mt-8">
            <v-text-field
              v-for="(value, key, index) in localTags.landingPage.tags"
              :key="index"
              :label="key"
              v-model="localTags.landingPage.tags[key].value"
              @input="onInputChange()"
              name="item.name"
            ></v-text-field>
          </v-form>
          <v-alert
            v-else
            :elevation="4"
            class="mt-2"
            type="info"
            :title="$t('Alert')"
            variant="tonal"
            >{{ $t("view.companies.template_contains_no_tags") }}
          </v-alert>

          <v-btn
            elevation="2"
            dark
            color="green"
            @click="validate()"
            small
            class="ma-4"
            >{{ $t("view.button.validate") }}
          </v-btn>
          <v-btn elevation="2" @click="cancel()" small>{{
            $t("view.button.cancel")
          }}</v-btn>
        </v-col>
        <v-col cols="12" md="9" class="mt-8">
          <span class="logoTab" @click="toggleTab()">
            <img src="../../../assets/logoEmail.png" alt="logo email"
          /></span>
          <span class="logoTab" @click="toggleTab()"
            ><img src="../../../assets/landingPage.png" alt="logo landing page"
          /></span>
          <v-card :elevation="4" class="mt-2">
            <v-responsive :min-height="600" :aspect-ratio="16 / 9">
              <iframe
                :srcdoc="landingPageTemplate"
                style="
                  border-style: none;
                  background-color: #fff;
                  width: 100%;
                  height: 100%;
                "
              ></iframe>
            </v-responsive>
          </v-card>
        </v-col>
      </v-row>
    </v-col>
    <v-col v-if="latestCompilationErrorMessage">
      <v-alert
        :elevation="4"
        class="mt-2"
        type="error"
        :title="$t('Alert')"
        variant="tonal"
        >{{ latestCompilationErrorMessage }}
      </v-alert>
    </v-col>
  </v-container>
</template>

<script>
import companiesPhishingMixin from "@/mixins/companies.phishing.mixin"
import _ from "lodash"

export default {
  mixins: [companiesPhishingMixin],
  name: "phishing-template-edition",
  props: {
    templateTags: {
      default: null,
    },
    exerciseId: null,
    companyUuid: null,
  },
  data() {
    return {
      localTags: null,
      emailTemplate: null,
      landingPageTemplate: null,
      firstTab: true,
      oldTags: null,
      storedTags: null,
      resetedTags: false,
      latestCompilationErrorMessage: null,
    }
  },
  watch: {
    templateTags: function (newVal, oldVal) {
      this.localTags = JSON.parse(JSON.stringify(this.templateTags))
      this.refreshTemplate()
    },
    exerciseId: function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.localTags = JSON.parse(JSON.stringify(this.templateTags))
        this.storedTags = JSON.parse(JSON.stringify(this.templateTags))
        this.refreshTemplate()
      }
    },
  },
  mounted() {
    // on regarde d'abord si il y a des tags dans le local storage
    this.localTags = JSON.parse(JSON.stringify(this.templateTags))
    this.refreshTemplate()
  },
  methods: {
    onInputChange() {
      this.refreshTemplate()
    },

    reset() {
      this.localTags = JSON.parse(JSON.stringify(this.templateTags))
      this.resetedTags = true
      this.refreshTemplate()
    },
    /*
     * Refresh the template with or without tabs
     */
    refreshTemplate: _.debounce(async function () {
      let templates = await this.getCompanyExerciseCompiledWithTemplateTags(
        this.exerciseId,
        this.localTags
      )

      this.latestCompilationErrorMessage =
        !templates && this.latestError && this.latestError.message
          ? this.latestError.message
          : null

      if (!templates) {
        templates = await this.getCompanyExerciseCompiledTemplates(
          this.exerciseId
        )
      }
      if (templates) {
        this.landingPageTemplate = null
        this.emailTemplate = null
        this.landingPageTemplate = templates.landingPage.html
        this.emailTemplate = templates.email.html

        this.waitForIframeLoad()
      }
    }, 300), // debounce 300ms so that it doesn't get called twice
    waitForIframeLoad() {
      const iframe = document.querySelector("iframe")
      iframe.onload = () => {
        this.injectCSSToIframe()
      }
    },
    injectCSSToIframe() {
      const css =
        "[data-oppens-tag-name] { " +
        "padding: 0 5px !important; " +
        "color: #000 !important; " +
        "background-color: #ffc900 !important; " +
        "}"

      const iframe = document.querySelector("iframe")
      const iframeDoc = iframe.contentDocument || iframe.contentWindow.document

      const style = iframeDoc.createElement("style")
      style.textContent = css

      iframeDoc.head.appendChild(style)
    },

    getTags() {
      return this.localTags
    },
    toggleTab() {
      this.firstTab = !this.firstTab
    },
    storeTags(tags) {
      this.storedTags = JSON.parse(JSON.stringify(tags)) //_.clone(tags)
    },
    getStoredTags() {
      return JSON.parse(JSON.stringify(this.storedTags)) //_.clone(this.storedTags)
    },
    validate() {
      this.storeTags(this.localTags)
      this.$emit("close")
    },

    /**
     * Check if there are tags in the local storage to init the local tags
     * If not, init the local tags with the template tags
     * If reset, tags must be always init with the template tags, even if you click on cancel after reset
     */
    cancel() {
      if (this.resetedTags) {
        this.localTags = JSON.parse(JSON.stringify(this.templateTags))
        this.refreshTemplate()
      } else {
        let localStoragedTags = this.getStoredTags()
        if (localStoragedTags) {
          this.localTags = localStoragedTags
          this.refreshTemplate()
        } else {
          this.localTags = JSON.parse(JSON.stringify(this.templateTags))
        }
      }
      this.resetedTags = false
      this.$emit("close")
    },
  },
}
</script>

<style scoped>
.oppens-tag-text {
  background-color: #ffc900;
}

.subtitle {
  color: #5e6163;
  font-size: 0.9rem;
}

.logoTab {
  cursor: pointer;
  margin-right: 10px;
}

.button {
  width: 20px;
}
</style>
