<template>
  <div>
    <div class="d-flex justify-end mb-4">
      <v-btn
        color="orange"
        class="text-none rounded-lg"
        dark
        elevation="0"
        small
        @click="exportData"
      >
        <v-icon left>mdi-download</v-icon>
        {{ $t("export") }}
      </v-btn>
      <v-btn
        color="blue"
        class="text-none rounded-lg ml-2"
        dark
        elevation="0"
        small
        @click="importCSV"
      >
        <v-icon left>mdi-upload</v-icon>
        {{ $t("employees.import.alerts") }}
      </v-btn>
      <v-dialog v-model="downloadDialogIsOpened" :max-width="500">
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon outlined small class="ml-2" v-bind="attrs" v-on="on">
            <v-icon size="14">mdi-help</v-icon>
          </v-btn>
        </template>
        <v-card class="pa-12">
          <v-card-text>{{ $t("employees.import.uploadHint") }}</v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              @click="closeDownloadDialog()"
              class="text-none rounded-lg"
              outlined
              light
              elevation="0"
            >
              {{ $t("close") }}
            </v-btn>
            <v-btn
              @click="downloadCSV()"
              color="primary"
              class="text-none rounded-lg"
              dark
              elevation="0"
            >
              {{ $t("employees.import.downloadTemplate") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
    <div class="piecharts" v-if="sentSeries">
      <div class="chartBloc">
        <doughnut-chart
          v-if="sentSeries"
          :chartData="sentSeries"
          :centerTxt="sentSeries.centerTxt"
          :centerTxtColor="sentSeries.centerTxtColor"
          title="$t('view.learning_phishing.emails_sent')"
        ></doughnut-chart>
      </div>

      <div class="chartBloc">
        <doughnut-chart
          v-if="openedSeries"
          :chartData="openedSeries"
          :centerTxt="openedSeries.centerTxt"
          :centerTxtColor="openedSeries.centerTxtColor"
          :title="openedSeries.title"
        ></doughnut-chart>
      </div>

      <div class="chartBloc">
        <doughnut-chart
          v-if="clickedSeries"
          :chartData="clickedSeries"
          :centerTxt="clickedSeries.centerTxt"
          :centerTxtColor="clickedSeries.centerTxtColor"
          :title="clickedSeries.title"
        ></doughnut-chart>
      </div>

      <div class="chartBloc">
        <doughnut-chart
          v-if="submittedSeries"
          :chartData="submittedSeries"
          :centerTxt="submittedSeries.centerTxt"
          :centerTxtColor="submittedSeries.centerTxtColor"
          :title="submittedSeries.title"
        ></doughnut-chart>
      </div>

      <div class="chartBloc">
        <doughnut-chart
          v-if="reportedSeries"
          :chartData="reportedSeries"
          :centerTxt="reportedSeries.centerTxt"
          :centerTxtColor="reportedSeries.centerTxtColor"
          :title="reportedSeries.title"
        ></doughnut-chart>
      </div>
    </div>
    <op-confirmation-dialog ref="confirm" />
    <div v-if="studentResults">
      <v-data-table
        :headers="headers"
        :items="studentResults"
        :items-per-page="5"
        class="elevation-1"
      >
        <template v-slot:[`item.reported`]="{ item }">
          <v-btn
            icon
            @click="item.reported.color !== 'green' && emailReporting(item)"
          >
            <v-icon :color="item.reported.color">
              {{ item.reported.icon }}
            </v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </div>
  </div>
</template>
<script>
import { i18n } from "@/plugins/i18n"
import PhishingMixin from "@/mixins/phishing.mixin"
import { showSnackbar } from "@/services/GlobalActions"

export default {
  name: "phishing-simulation-summary",
  mixins: [PhishingMixin],
  props: {
    results: {
      default: null,
    },
    serverUrl: {
      type: String,
      default: null,
    },
  },
  data: () => ({
    localResults: null,
    studentResults: null,

    headers: [
      {
        text: i18n.t("First name"),
        align: "start",
        sortable: true,
        value: "first_name",
      },
      {
        text: i18n.t("Last name"),
        align: "start",
        sortable: true,
        value: "last_name",
      },
      {
        text: i18n.t("Email"),
        align: "start",
        sortable: true,
        value: "email",
      },
      {
        text: i18n.t("Status"),
        align: "start",
        sortable: true,
        value: "status",
      },
      {
        text: i18n.t("Reported"),
        align: "start",
        sortable: true,
        value: "reported",
      },
    ],
    sentSeries: null,
    openedSeries: null,
    clickedSeries: null,
    submittedSeries: null,
    reportedSeries: null,
    downloadDialogIsOpened: false,
  }),
  computed: {
    companyUuid() {
      return this.$route.params.companyUuid
    },
  },
  mounted() {
    this.processSimulationResults(this.results)
  },
  watch: {
    results: {
      handler(newVal, oldVal) {
        if (newVal !== oldVal) {
          this.processSimulationResults(newVal)
        }
      },
      deep: true, // This ensures we are watching for nested changes in the object
    },
  },
  methods: {
    downloadCSV() {
      const csvContent = "data:text/csv;charset=utf-8," + "email\n"
      const encodedUri = encodeURI(csvContent)
      const link = document.createElement("a")
      link.setAttribute("href", encodedUri)
      link.setAttribute("download", "template.csv")
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    },
    closeDownloadDialog() {
      this.downloadDialogIsOpened = false
    },
    normalizeKeys(employee) {
      return Object.keys(employee).reduce((acc, key) => {
        const normalizedKey = key.toLowerCase().replace(/\s+/g, "_")
        acc[normalizedKey] = employee[key]
        return acc
      }, {})
    },
    async emailReporting(item) {
      if (
        await this.$refs.confirm.open(
          i18n.t("Reporting confirmation"),
          i18n.t("Are you sure you want to report this email?"),
          i18n.t("Cancel")
        )
      ) {
        // le corps de la requête
        const requestBody = {
          employee_tracking_id: item.employee_tracking_id,
        }

        await this.reportEmail(
          this.companyUuid,
          item.phishing_simulation_id,
          requestBody
        )
      }
    },
    processSimulationResults(results) {
      if (!results || !results.results) {
        console.error("no results.results")
        return
      }

      const defaultGrey = "rgb(231, 230, 230)"
      const blue = "rgb(69, 115, 196)"
      const yellow = "rgb(250, 206, 48)"
      const red = "rgb(181, 0, 19)" // saisissent la donnée
      const grey = "rgb(120, 118, 118)" // ouvrent l'email
      const lightBlue = "rgb(121, 214, 236)" // reported

      if (results.stats) {
        const stats = results.stats

        this.sentSeries = {
          labels: [i18n.t("Emails sent"), i18n.t("Unsent emails")],
          title: i18n.t("Emails sent"),
          centerTxt: `${stats.sent}/${stats.total}`,
          centerTxtColor: "#4573c4",
          datasets: [
            {
              label: "label ?",
              data: [stats.sent, stats.total - stats.sent],
              backgroundColor: [blue, defaultGrey],
              hoverOffset: 4,
            },
          ],
        }
        this.openedSeries = {
          labels: [i18n.t("Open emails"), i18n.t("Unopened emails")],
          title: i18n.t("Open emails"),
          centerTxt: `${stats.opened}/${stats.total}`,
          centerTxtColor: "#787676",
          datasets: [
            {
              label: "label ?",
              data: [stats.opened, stats.total - stats.opened],
              backgroundColor: [grey, defaultGrey],
              hoverOffset: 4,
            },
          ],
        }
        this.clickedSeries = {
          labels: [i18n.t("Links clicked"), i18n.t("Unclicked links")],
          title: i18n.t("Links clicked"),
          centerTxt: `${stats.clicked}/${stats.total}`,
          centerTxtColor: "#face30",
          datasets: [
            {
              label: "label ?",
              data: [stats.clicked, stats.total - stats.clicked],
              backgroundColor: [yellow, defaultGrey],
              hoverOffset: 4,
            },
          ],
        }
        this.submittedSeries = {
          labels: [i18n.t("Data submitted"), i18n.t("Data not entered")],
          title: i18n.t("Data submitted"),
          centerTxt: `${stats.submitted_data}/${stats.total}`,
          centerTxtColor: "#b50013",
          datasets: [
            {
              label: "label ?",
              data: [stats.submitted_data, stats.total - stats.submitted_data],
              backgroundColor: [red, defaultGrey],
              hoverOffset: 4,
            },
          ],
        }
        this.reportedSeries = {
          labels: [i18n.t("Data reported"), i18n.t("Data not reported")],
          title: i18n.t("Data reported"),
          centerTxt: `${stats.email_reported}/${stats.total}`,
          centerTxtColor: "#000",
          datasets: [
            {
              label: "label ?",
              data: [stats.email_reported, stats.total - stats.email_reported],
              backgroundColor: [lightBlue, defaultGrey],
              hoverOffset: 4,
            },
          ],
        }
      }

      if (results.results.results) {
        this.studentResults = results.results.results.map((item) => {
          return {
            ...item,
            reported: item.reported
              ? { icon: "mdi-check-circle", color: "green" }
              : { icon: "mdi-alert-circle-outline", color: "grey darken-3" },
          }
        })
      }
    },
    exportData() {
      this.$emit("export")
    },
    importCSV() {
      const input = document.createElement("input")
      input.type = "file"
      input.accept = ".csv"
      input.onchange = (event) => {
        const file = event.target.files[0]
        if (file) {
          this.$papa.parse(file, {
            header: true,
            skipEmptyLines: true,
            complete: (results) => {
              this.processCSV(results.data)
            },
          })
        }
      }
      input.click()
    },
    processCSV(data) {
      const employeeTrackingIdsToReport = []

      data.forEach((row) => {
        const normalizedRow = this.normalizeKeys(row)
        if (!normalizedRow.email) {
          showSnackbar(i18n.t("employees.import.reporting"))
          return
        }
        const studentsToReport = this.studentResults.filter(
          (student) =>
            student.email.toLowerCase() === normalizedRow.email.toLowerCase()
        )

        studentsToReport.forEach((student) => {
          employeeTrackingIdsToReport.push(student.employee_tracking_id)
        })
      })

      if (employeeTrackingIdsToReport.length > 0) {
        this.reportEmail(
          this.companyUuid,
          this.studentResults[0].phishing_simulation_id,
          { employee_tracking_id: employeeTrackingIdsToReport }
        )
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.piecharts {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;

  .chartBloc {
    max-width: 300px;
    width: 18%;

    h4 {
      text-align: center;
    }
  }
}
</style>
