import LocalStorage from "../services/LocalStorage"
import AuthRepository from "../repositories/AuthRepository"
import moment from "moment"
import AuthStore from "../stores/AuthStore"
import UserLogic from "./UserLogic"
import Model from "../models/Model"
import { showSnackbar } from "@/services/GlobalActions"
import UserRepository from "@/repositories/UserRepository"
import DashboardStore from "../stores/DashboardStore"

export default class AuthLogic {
  /**
   * @param body
   * @returns {Promise}
   */
  static register(body) {
    return AuthRepository.register(body)
  }

  /**
   *
   * @param body
   * @returns {Promise<Model>}
   */
  static async login(body) {
    AuthLogic.set2FAIsNeeded(false)
    const response = await AuthRepository.login(body)
    if (response.status_code === 200) {
      const mustSend2faCode = response.responseObject().two_factor
      AuthLogic.set2FAIsNeeded(mustSend2faCode)
      AuthLogic.setTokens(response.responseObject())
      if (!mustSend2faCode) {
        return await this.loadMe()
      }
      return { mustSend2faCode }
    }
  }

  static async loadMe() {
    const response = await AuthRepository.me()
    const user = new Model(response.dataObject())

    if (user.attributes.role === "classic") {
      showSnackbar(
        "Vous n'avez pas les droits suffisants pour accéder à cette plateforme"
      )
      AuthLogic.removeTokens()
      return null
    }

    AuthLogic.setMe(user)
    return user
  }

  /**
   * @description store user in class memory & local storage
   */
  static setMe(user) {
    AuthLogic.computeName(user)
    AuthStore.setMe(user)
    LocalStorage.set("me", user)
  }

  /**
   * @description compute full name & initials
   */
  static computeName(user) {
    const firstName = user.getAttribute("first_name")
    const lastName = user.getAttribute("last_name")
    const email = user.getAttribute("email_address")

    if (firstName && lastName) {
      user.setAttribute("full_name", firstName + " " + lastName)
      user.setAttribute(
        "initials",
        firstName.substr(0, 1) + lastName.substr(0, 1)
      )
    } else if (lastName) {
      user.setAttribute("full_name", lastName)
      user.setAttribute("initials", lastName.substr(0, 2))
    } else if (firstName) {
      user.setAttribute("full_name", firstName)
      user.setAttribute("initials", firstName.substr(0, 2))
    } else if (email) {
      let emailSplit = email.split("@")[0]
      user.setAttribute("full_name", emailSplit)
      user.setAttribute("initials", emailSplit.substr(0, 2))
    }
  }

  static async refreshToken(refreshToken) {
    this.deleteRefreshToken()
    const response = await AuthRepository.refreshToken(refreshToken)
    AuthLogic.setTokens(response.responseObject())
    return response.responseObject()
  }

  static deleteRefreshToken() {
    let token = LocalStorage.get("token")
    delete token.refresh_token
    AuthLogic.setTokens(token)
  }

  static async logout() {
    if (this.getTokens()) {
      await AuthRepository.logout()
    }

    this.removeMe()
    this.removeTokens()

    return true
  }

  /**
   * @returns  {Promise<string>}
   */
  static async forgotPassword(email) {
    const response = await AuthRepository.forgotPassword({
      email: email,
    })

    return new Model(response.dataObject())
  }

  static async resetPassword(email) {
    const response = await AuthRepository.resetPassword({
      email: email,
    })

    return new Model(response.dataObject())
  }

  /**
   * @returns {Model}
   */
  static me() {
    return new Model(LocalStorage.get("me"))
  }

  /**
   * @returns void
   */
  static removeMe() {
    LocalStorage.remove("me")
    AuthStore.reset()
    DashboardStore.reset()
  }

  /**
   *
   * @returns {boolean}
   */
  static hasAccessToken() {
    return !!this.getTokens()
  }

  /**
   * @param token
   */
  static setTokens(token) {
    token.expireDate = moment().add(token.expires_in, "seconds")
    LocalStorage.set("token", token)
    AuthStore.setToken(token)
  }

  /**
   * @returns {string}
   */
  static getTokens() {
    return LocalStorage.get("token")
  }

  /**
   * @returns {string}
   */
  static removeTokens() {
    LocalStorage.remove("token")
    AuthStore.state.token = null
  }

  /**
   * @param needs2FA
   */
  static set2FAIsNeeded(needs2FA) {
    LocalStorage.set("needs2FA", needs2FA)
  }

  /**
   * @returns {boolean}
   */
  static get2FAIsNeeded() {
    return LocalStorage.get("needs2FA")
  }

  static async refreshRoles() {
    const response = await UserRepository.getUserRoles()
    const roles = response.responseObject().data
    AuthStore.setRoles(roles)

    return roles
  }
}
