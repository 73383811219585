import LearningLogic from "@/logics/LearningLogic"
import utilsMixin from "@/mixins/utils.mixin"

export default {
  mixins: [utilsMixin],
  data: () => ({
    hatCourses: null,
  }),
  methods: {
    getAvailableHatCourses() {
      return this._withLoading(() =>
        LearningLogic.getAvailableHatCourses(this.companyUuid)
      )
    },

    getAllCompanyCourses() {
      return this._withLoading(async () => {
        return await LearningLogic.getAllCourses(this.companyUuid)
      })
    },

    saveCompanyCourse(data) {
      return this._withLoading(async () =>
        LearningLogic.pushCourse(this.companyUuid, data)
      )
    },

    getOneCourse(companyUuid, courseId) {
      return this._withLoading(() =>
        LearningLogic.getOneCourse(companyUuid, courseId)
      )
    },

    suppressCompanyCourse(data) {
      return this._withLoading(async () =>
        LearningLogic.deleteCourse(this.companyUuid, data.id)
      )
    },

    startCompanyCourse(data) {
      return this._withLoading(async () =>
        LearningLogic.startCourse(this.companyUuid, data.id)
      )
    },

    stopCompanyCourse(data) {
      return this._withLoading(async () =>
        LearningLogic.stopCourse(this.companyUuid, data.id)
      )
    },

    inviteUsersToCompanyCourse(data) {
      return this._withLoading(() =>
        LearningLogic.inviteUsersToCourse(this.companyUuid, data.id)
      )
    },

    remindUsersOfCompanyCourse(data) {
      return this._withLoading(() =>
        LearningLogic.remindUsersOfCourse(this.companyUuid, data.id)
      )
    },

    getCompanyCourseResults(courseId) {
      return this._withLoading(() =>
        LearningLogic.getCourseResults(this.companyUuid, courseId)
      )
    },

    getNewCompanyCourse() {
      return {
        campaign_id: null,
        employee_groups_ids: null,
        has_automatic_invites: false,
        has_automatic_launch: false,
        has_automatic_reminders: true,
        has_automatic_stop: false,
        hat_course_id: null,
        invitation_email_type: "learning-invitation-post-phishing",
        is_live: false,
        is_mandatory: true,
        program_id: null,
        reminder_email_type: "learning-reminder-post-phishing",
        start_date: null,
      }
    },
  },
}
