<template>
  <div v-if="localData">
    <v-card elevation="0" outlined color="white" class="ma-1">
      <v-card-text>
        <v-row>
          <h3 style="width: 100%; color: #1cc8aa" class="mb-2">{{ $t("view.learning_phishing.title") }}</h3>
          <v-text-field
            :label="$t('view.learning_phishing.title')"
            v-model="localData.title"
            class="required"
          >
          </v-text-field>
        </v-row>

        <v-row>
          <h3 style="width: 100%; color: #1cc8aa" class="mb-2">{{ $t("view.learning_phishing.description") }}</h3>
          <v-text-field
            :label="$t('view.learning_phishing.description')"
            v-model="localData.description"
            class="required"
          >
          </v-text-field>
        </v-row>

        <v-row>
          <h3 style="width: 100%; color: #1cc8aa" class="mb-2">
            {{ $t("view.learning_phishing.themes_and_stimuli") }}
          </h3>
          <v-col cols="6">
            <v-select
              v-model="localData.thematics"
              :items="thematics"
              multiple
              item-text="slug"
              item-value="id"
              filled
              :label="$t('view.learning_phishing.selection_of_themes')"
            ></v-select>
          </v-col>

          <v-col cols="6">
            <v-select
              v-model="localData.stimuli"
              :items="stimuli"
              multiple
              item-text="slug"
              item-value="id"
              filled
              :label="$t('view.learning_phishing.selection_of_stimuli')"
            ></v-select>
          </v-col>
        </v-row>

        <v-row>
          <h3 style="width: 100%; color: #1cc8aa" class="mb-2">
            {{ $t("view.learning_phishing.cover_image") }}
          </h3>
          <v-text-field
            :label="$t('Image')"
            v-model="localData.image"></v-text-field>
        </v-row>

        <v-row>
          <h3 style="width: 100%; color: #1cc8aa" class="mb-2">
            {{ $t("view.learning_phishing.duration_in_minutes") }}
          </h3>
          <v-text-field
            :label="$t('view.learning_phishing.duration_minutes')"
            v-model="localData.duration"
            type="number"
            class="required"
          >
          </v-text-field>
        </v-row>

        <v-row>
          <h3 style="width: 100%; color: #1cc8aa" class="mb-2">
            {{ $t("view.companies.gamification_parameters") }}
          </h3>
          <v-col cols="4">
            <v-text-field
              :label="$t('Stars on Success')"
              v-model="localData.stars_on_success"
              type="number"
              step="0.2"
            ></v-text-field>
          </v-col>

          <v-col cols="4">
            <v-text-field
              :label="$t('Stars on Failure')"
              v-model="localData.stars_on_failure"
              type="number"
              step="0.2"
            ></v-text-field>
          </v-col>

          <v-col cols="4">
            <v-text-field
              :label="$t('Stars Not Taken')"
              v-model="localData.stars_not_taken"
              type="number"
              step="0.2"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn elevation="2" @click="submit()" dark color="green" class="text-none rounded-lg"
        >{{ $t("view.button.save") }}
        </v-btn
        >
        <v-btn elevation="2" @click="close()" class="text-none rounded-lg">{{ $t("view.button.close") }}</v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>
<script>
import oppensLearningMixin from "@/mixins/oppens.learning.mixin.js"
import AdminLogic from "@/logics/AdminLogic"

export default {
  mixins: [oppensLearningMixin],

  name: "oppens-learning-hat-course-edition",

  props: {
    data: {
      default: null,
    },
  },

  data: function () {
    return {
      localData: null,
      stimuli: [],
      thematics: [],
    }
  },

  async mounted() {
    const data = JSON.parse(JSON.stringify(this.data))

    if (data.stimuli) {
      data.stimuli = data.stimuli.map((stimulus) => stimulus.id)
    }

    if (data.thematics) {
      data.thematics = data.thematics.map((thematic) => thematic.id)
    }

    this.localData = data
    this.stimuli = await AdminLogic.getAllStimuli()
    this.thematics = await AdminLogic.getAllThematics()
  },

  computed: {
    form() {
      return this.$refs.form
    },
  },

  methods: {
    async submit() {
      this.$emit("save", this.localData)
    },

    close() {
      this.$emit("close")
    },
  },
}
</script>
