import _ from "lodash"

class Model {
  constructor(data) {
    this.attributes = {
      created_at: null,
      updated_at: null,
      id: null,
    }

    this.id = null
    this.uuid = null
    this.included = []
    this.type = null
    this.rawData = {
      created_at: null,
      updated_at: null,
      id: null,
    }

    this.relationships = {}

    this.replace(data)
  }

  replace(data) {
    if (data) {
      if (data.attributes) {
        this.setAttributes(data.attributes)
      }
      if (data.id) {
        this.setId(data.id)
      }
      if (data.uuid) {
        this.setUuid(data.uuid)
      }
      if (data.type) {
        this.setType(data.type)
      }
      if (data.attributes) {
        this.setRawData(data.attributes)
      }
      if (data.included) {
        this.setIncluded(data.included)
      }
      if (data.relationships) {
        this.setRelationships(data.relationships)
      }
    }
  }

  /**
   * @return {Object} Return owning Model attributes data
   */
  getRawData() {
    return this.rawData
  }

  /**
   * @return {Object} Return owning Model attributes data
   */
  getAttributes() {
    return this.attributes
  }

  /**
   * @return {Object} Return  Model attributes without any empty / null or / 0 values
   */
  getNonNullorEmptyAttributes() {
    let obj = _.clone(this.attributes)
    Object.keys(obj).forEach((key) => {
      if (!obj[key]) {
        delete obj[key]
      }
    })
    return obj
  }

  /**
   * @return {Object} Return owning Model attributes data
   */
  getAttribute(key) {
    return this.attributes[key]
  }

  /**
   * @param key
   * @returns {*}
   */
  getRelation(key) {
    return this.included.filter((item) => {
      return item.type === key
    })
  }

  /**
   * Setter for the ID owning Model identifier resource
   */
  setId(id) {
    if (typeof id !== "undefined") {
      this.id = id
      this.attributes["id"] = id
    }
  }

  /**
   * Setter for the ID owning Model identifier resource
   */
  setUuid(uuid) {
    if (typeof uuid !== "undefined") {
      this.uuid = uuid
      this.attributes["uuid"] = uuid
    }
  }

  /**
   * Setter for the ID owning Model identifier resource
   */
  setType(type) {
    if (typeof type !== "undefined") this.type = type
  }

  updateAttributes(attributes) {
    if (typeof attributes == "undefined") {
      return
    }

    for (const field in attributes) {
      if (_.has(this.attributes, field)) {
        this.attributes[field] = attributes[field]
      }
    }
  }

  updateAttribute(name, value) {
    if (value && _.has(this.attributes, name)) {
      this.attributes[name] = value
    }
  }

  setAttribute(name, value) {
    if (value) {
      this.attributes[name] = value
    }
  }

  setAttributes(attributes) {
    if (typeof attributes !== "undefined") this.attributes = attributes
  }

  setRawData(attributes) {
    if (typeof attributes !== "undefined") this.rawData = attributes
  }

  setRelationships(relationships) {
    if (typeof relationships !== "undefined") this.relationships = relationships
  }

  getRelationship(key) {
    if (
      this.relationships &&
      this.relationships[key] &&
      this.relationships[key].data
    ) {
      return this.relationships[key].data
    }
  }

  setIncluded(included) {
    if (typeof included !== "undefined") {
      this.included = included.map((item) => new Model(item))
    }
  }
}

export default Model
