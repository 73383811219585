<template>
  <v-row no-gutters class="announcement-bar" :class="alertClass">
    <v-col cols="12">
      <div class="announcement-content">
        <marquee behavior="scroll" direction="left" scrollamount="4">
          <span class="announcement-date">{{ formattedDate }}</span>
          <span class="announcement-message">{{ announcementMessage }}</span>
        </marquee>
      </div>
    </v-col>
  </v-row>
</template>

<style scoped lang="scss">
.announcement-bar {
  width: 100%;
  padding: 15px 20px;
  text-align: center;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s ease, color 0.3s ease;
  &.normal {
    background-color: #1cc8ab9a;
    color: #ffffff;
  }
  &.warningalert {
    background-color: #ffe0e0;
    color: #d32f2f;
  }
}
.announcement-content {
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
}
marquee {
  display: flex;
  align-items: center;
  height: 30px;
}
.announcement-message {
  font-size: 18px;
  font-weight: 500;
  display: inline-block;
  }
  .announcement-date {
  margin-right: 20px;
  font-size: 14px;
  font-weight: 300;
  display: inline-block;
  opacity: 0.7;
  margin-left: 10px;
}
</style>

<script>
import moment from 'moment';
import 'moment/locale/fr';
import 'moment/locale/en-gb'; 

export default {
  name: "op-announcement-alert",
  props: {
    type: {
      type: String,
      required: true
    },
    metadata: {
      type: Object,
      required: true
    },
    updatedAt: {
      type: String,
      required: true
    },
    language: {
      type: String,
      required: true
    }
  },
  computed: {
    alertClass() {
      return this.type === "warning" ? "warningalert" : "normal";
    },
    announcementMessage() {
      return this.metadata[this.language] || this.metadata['en'];
    },
    formattedDate() {
      moment.locale(this.metadata[this.language]|| "en"); 
      return moment(this.updatedAt).format('LL, LT');
    }
  }
}
</script>
