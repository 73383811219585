import Request from "../services/Request"
import { API_VERSION } from "./ApiVersion"

export default class PhishingExerciseCatalogRepository {
  static getAllCatalogs() {
    return Request.make("GET", `/${API_VERSION}/phishing-exercise-catalogs`)
  }

  static createCatalog(body = {}) {
    return Request.make(
      "POST",
      `/${API_VERSION}/phishing-exercise-catalogs`,
      body
    )
  }

  static updateCatalog(catalogId, body = {}) {
    return Request.make(
      "PATCH",
      `/${API_VERSION}/phishing-exercise-catalogs/${catalogId}`,
      body
    )
  }

  static deleteCatalog(catalogId) {
    return Request.make(
      "DELETE",
      `/${API_VERSION}/phishing-exercise-catalogs/${catalogId}`
    )
  }

  //----------------------- Exercises in Catalog -----------------------

  static getCatalogExercises(catalogId) {
    return Request.make(
      "GET",
      `/${API_VERSION}/phishing-exercise-catalogs/${catalogId}/exercises`
    )
  }

  static addPhishingExerciseToCatalog(catalogId, exerciseId) {
    return Request.make(
      "POST",
      `/${API_VERSION}/phishing-exercise-catalogs/${catalogId}/exercises/${exerciseId}`
    )
  }

  static removePhishingExerciseFromCatalog(catalogId, exerciseId) {
    return Request.make(
      "DELETE",
      `/${API_VERSION}/phishing-exercise-catalogs/${catalogId}/exercises/${exerciseId}`
    )
  }

  //----------------------- Company related to Catalog -----------------------

  static getCompanyCatalogs(companyUuid) {
    return Request.make(
      "GET",
      `/${API_VERSION}/phishing-exercise-catalogs/companies/${companyUuid}`
    )
  }

  static addCompanyToCatalog(companyUuid, catalogId) {
    return Request.make(
      "POST",
      `/${API_VERSION}/phishing-exercise-catalogs/companies/${companyUuid}/${catalogId}`
    )
  }

  static removeCompanyFromCatalog(companyUuid, catalogId) {
    return Request.make(
      "DELETE",
      `/${API_VERSION}/phishing-exercise-catalogs/companies/${companyUuid}/${catalogId}`
    )
  }
}
