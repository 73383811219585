import HatCourseCatalogRepository from "../repositories/HatCourseCatalogsRepository"

export default class HatCourseCatalogsLogic {
  //----------------------- Catalog CRUD -----------------------

  static async getAllCatalogs() {
    const response = await HatCourseCatalogRepository.getAllCatalogs()
    let list = response.responseObject()
    return list.data
  }

  static async createCatalog(body) {
    const response = await HatCourseCatalogRepository.createCatalog(body)
    return response.responseObject().data
  }

  static async updateCatalog(catalogId, body) {
    const response = await HatCourseCatalogRepository.updateCatalog(
      catalogId,
      body
    )
    return response.responseObject().data
  }

  static async deleteCatalog(catalogId) {
    const response = await HatCourseCatalogRepository.deleteCatalog(catalogId)
    return response.responseObject()
  }

  //----------------------- Courses in Catalog -----------------------

  static async getCatalogCourses(catalogId) {
    const response = await HatCourseCatalogRepository.getCatalogCourses(
      catalogId
    )
    return response.responseObject().data
  }

  static async addHatCourseToCatalog(catalogId, courseId) {
    const response = await HatCourseCatalogRepository.addHatCourseToCatalog(
      catalogId,
      courseId
    )
    return response.responseObject().data
  }

  static async removeHatCourseFromCatalog(catalogId, courseId) {
    const response =
      await HatCourseCatalogRepository.removeHatCourseFromCatalog(
        catalogId,
        courseId
      )
    return response.responseObject()
  }

  //----------------------- Company related to Catalog -----------------------

  static async getCompanyCatalogs(companyUuid) {
    const response = await HatCourseCatalogRepository.getCompanyCatalogs(
      companyUuid
    )
    return response.responseObject().data
  }

  static async addCompanyToCatalog(companyUuid, catalogId) {
    const response = await HatCourseCatalogRepository.addCompanyToCatalog(
      companyUuid,
      catalogId
    )
    return response.responseObject().data
  }

  static async removeCompanyFromCatalog(companyUuid, catalogId) {
    const response = await HatCourseCatalogRepository.removeCompanyFromCatalog(
      companyUuid,
      catalogId
    )
    return response.responseObject()
  }
}
