<template>
  <v-dialog
    v-model="dialog"
    :max-width="options.width"
    :style="{ zIndex: options.zIndex }"
    @keydown.esc="cancel"
  >
    <v-card>
      <v-toolbar dark :color="options.color" dense flat>
        <v-toolbar-title class="text-body-2 font-weight-bold black--text">
          {{ $t("view.companies.group_creator_configuration") }}
        </v-toolbar-title>
      </v-toolbar>

      <v-card-text>
        <v-row>
          <v-col cols="6">
            <v-text-field
              v-model="rules.type"
              :label="$t('view.learning_phishing.type')"
              disabled
            ></v-text-field>
          </v-col>
          <v-col cols="6">
            <v-text-field v-model="rules.prefix" :label="$t('view.companies.prefix')"></v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="6">
            <v-text-field
              v-model="rules.nbGroups"
              :label="$t('view.companies.number_of_groups')" type="number"
            ></v-text-field>
          </v-col>
          <v-col cols="6">
            <v-checkbox
              v-model="rules.groupEmployeesByLang"
              :label="$t('view.companies.group_employees_by_language')"
            ></v-checkbox>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="6">
            <v-select
              v-model="rules.employeesSelectionType"
              :items="employeeSelectionTypes"
              :label="$t('view.companies.type_of_employee_selection')"
            ></v-select>

            <v-text-field
              v-if="rules.employeesSelectionType === 'sql'"
              v-model="rules.employeesSelectionSQL"
              :label="$t('SQL Query')"
            ></v-text-field>
          </v-col>
          <v-col cols="6">
            <v-select
              v-if="rules.employeesSelectionType === 'lang'"
              v-model="rules.employeesSelectionLang"
              :items="availableLangs"
              :label="$t('view.learning_phishing.select_a_language')"
              multiple
              chips
              small-chips
            ></v-select>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions class="pt-3">
        <v-spacer></v-spacer>
        <v-btn
          v-if="!options.noconfirm"
          color="grey"
          text
          class="body-2 font-weight-bold text-none rounded-lg"
          @click.native="cancel"
        >{{ $t("view.companies.give_up") }}
        </v-btn>
        <v-btn
          color="green"
          class="body-2 font-weight-bold text-none rounded-lg"
          outlined
          @click.native="save"
        >{{ $t("view.button.save") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import moment from "moment"
import {showSnackbar} from "@/services/GlobalActions"
import {i18n} from "@/plugins/i18n";
import AuthStore from "@/stores/AuthStore"

export default {
  name: "op-multiple-groups",

  data() {
    return {
      options: {
        color: "grey lighten-3",
        width: 600,
        zIndex: 200,
        noconfirm: false,
      },
      dialog: false,
      resolve: null,
      reject: null,
      rules: {
        type: "multiple",
        prefix: null,
        nbGroups: 0,
        groupEmployeesByLang: false,
        employeesSelectionType: "all",
        employeesSelectionSQL: null,
        employeesSelectionLang: [],
      },
      employeeSelectionTypes: ["all"], // , "lang" add "sql" when you are system admin
      availableLangs: ["fr", "en", "ro", "de"], // Add more languages if needed
      isSystemAdmin: false,
    }
  },
  async mounted() {
    this.isSystemAdmin = await AuthStore.hasSystemRole("system-admin");
    if(this.isSystemAdmin){
      // Add SQL selection type for system admin
      this.employeeSelectionTypes.push("sql");
    }
  },
  methods: {
    open() {
      this.rules = {
        type: "multiple",
        prefix: null,
        nbGroups: 2,
        groupEmployeesByLang: false,
        employeesSelectionType: "all", //all, sql, lang
        employeesSelectionSQL: "",
        employeesSelectionLang: [], //array of langs ('fr','en')
      }

      this.rules.prefix = moment().format("YYYY-MM-DD")

      this.dialog = true

      return new Promise((resolve, reject) => {
        this.resolve = resolve
        this.reject = reject
      })
    },

    save() {
      if (!this.validConfiguration(this.rules)) {
        console.error("unvalid rules")
        return
      }

      this.resolve(this.rules)
      this.dialog = false
    },

    cancel() {
      this.resolve(null)
      this.dialog = false
    },

    validConfiguration(rules) {
      if (rules.nbGroups < 2 || isNaN(rules.nbGroups) || rules.nbGroups > 50) {
        showSnackbar(i18n.t('The number of groups must be between 2 and 50'))
        return false
      }

      if ("sql" === rules.employeesSelectionType) {
        showSnackbar(i18n.t('The SQL selection type is not yet available'))
        return false
      }

      if ("lang" === rules.employeesSelectionType) {
        showSnackbar(i18n.t('The LANG selection type is not yet available'))
        return false
      }

      return true
    },
  },
}
</script>
