<template>
  <v-container v-if="user">
    <v-card>
      <v-card-title class="d-flex justify-space-between mb-6">
        <span>{{ user.first_name }} {{ user.last_name }}</span>
        <span>{{ user.email }} </span>
        <span>
          <v-icon
            @click="addRole()"
            class="ml-2"
            elevation="2"
            icon
            color="green"
          >mdi-plus-box</v-icon
          >
          <v-icon @click="close()" class="ml-2" elevation="2" icon color="blue"
          >mdi-close-box</v-icon
          >
        </span>
      </v-card-title>

      <v-card-subtitle></v-card-subtitle>

      <v-card-text>
        <v-container v-if="edition" color="white">
          <v-progress-linear
            v-if="uploading"
            indeterminate
            color="yellow darken-2"
          ></v-progress-linear>

          <v-btn-toggle mandatory>
            <v-btn @click="isPerimeter = true">
              <span>{{ $t("view.companies.perimeter_roles") }}</span>
            </v-btn>

            <v-btn @click="isPerimeter = false">
              <span>{{ $t("view.companies.company_roles") }}</span>
            </v-btn>
          </v-btn-toggle>

          <div class="box" v-if="isPerimeter">
            <h3>{{ $t("view.companies.add_role_within_a_company_perimeter") }}</h3>

            <v-row class="mt-5">
              <v-col cols="4">
                <v-select
                  v-model="selectedPerimeter"
                  :items="perimeters"
                  item-text="name"
                  return-object
                  :label="$t('profile.perimeter')"
                  outlined
                  dense
                  clearable
                ></v-select>
              </v-col>

              <v-col cols="4">
                <v-select
                  v-model="selectedPerimeterRole"
                  :items="roles"
                  item-text="name"
                  return-object
                  :label="$t('view.users.profile.role')"
                  outlined
                  dense
                  clearable
                ></v-select>
              </v-col>

              <v-col cols="4">
                <v-btn @click="addPerimeterRole()" color="green" dark
                >{{ $t("view.button.add") }}
                </v-btn
                >
              </v-col>
            </v-row>

            <div class="subbox">
              <v-data-table
                :headers="perimeterRolesHeaders"
                :items="perimeterRoles"
                item-key="index"
              >
                <template v-slot:[`item.actions`]="{ item }">
                  <v-icon small @click="deletePerimeterRole(item)">
                    mdi-delete
                  </v-icon
                  >
                </template>
              </v-data-table>
            </div>

            <v-btn @click="validatePerimeterRoles()" color="blue" dark
            >{{ $t("view.button.validate") }}
            </v-btn
            >
            <v-btn @click="closeEdition()" color="grey" dark>{{ $t("view.button.close") }}</v-btn>
          </div>

          <div class="box" v-else>
            <h3>{{ $t("view.companies.add_role_within_a_company") }}</h3>

            <v-row class="mt-5">
              <v-col cols="4">
                <v-select
                  v-model="selectedCompany"
                  :items="companies"
                  item-text="business_name"
                  return-object
                  :label="$t('view.users.profile.company')"
                  outlined
                  dense
                  clearable
                ></v-select>
              </v-col>

              <v-col cols="4">
                <v-select
                  v-model="selectedCompanyRole"
                  :items="roles"
                  item-text="name"
                  return-object
                  :label="$t('view.users.profile.role')"
                  outlined
                  dense
                  clearable
                ></v-select>
              </v-col>

              <v-col cols="4">
                <v-btn @click="addCompanyRole()" color="green" dark
                >{{ $t("view.button.add") }}
                </v-btn
                >
              </v-col>
            </v-row>

            <div class="subbox">
              <v-data-table
                :headers="companyRolesHeaders"
                :items="companyRoles"
                item-key="index"
              >
                <template v-slot:[`item.actions`]="{ item }">
                  <v-icon small @click="deleteRole(item)"> mdi-delete</v-icon>
                </template>
              </v-data-table>

              <v-btn @click="validateCompanyRoles()" color="blue" dark
              >{{ $t("view.button.validate") }}
              </v-btn
              >
              <v-btn @click="closeEdition()" color="grey" dark>{{ $t("view.button.close") }}</v-btn>
            </div>
          </div>
        </v-container>

        <v-container v-else>
          <v-data-table
            :headers="roleHeaders"
            :items="userRoles"
            item-key="role.id"
          >
            <template v-slot:[`item.actions`]="{ item }">
              <v-icon small @click="deleteRole(item)"> mdi-delete</v-icon>
            </template>
          </v-data-table>
        </v-container>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import _ from "lodash"
import accessControlMixin from "@/mixins/accessControl.mixin"
import {i18n} from "@/plugins/i18n";

export default {
  name: "oppens-user-access-control-edition",

  mixins: [accessControlMixin],

  props: {
    user: {
      default: null,
    },
    roles: {
      default: null,
    },
    perimeters: {
      default: null,
    },
    companies: {
      default: null,
    },
  },

  data: () => ({
    isPerimeter: true,
    localUser: null,
    roleHeaders: [
      {
        text: i18n.t('Role'),
        align: "start",
        sortable: true,
        filterable: true,
        value: "name",
      },
      {
        text: i18n.t('Slug'),
        align: "start",
        sortable: true,
        filterable: true,
        value: "slug",
      },
      {
        text: i18n.t('Type'),
        align: "start",
        sortable: true,
        filterable: true,
        value: "typeStr",
      },
      {
        text: i18n.t('Perimeter'),
        align: "start",
        sortable: true,
        filterable: true,
        value: "perimeter",
      },
      {
        text: i18n.t('Company'),
        align: "start",
        sortable: true,
        filterable: true,
        value: "company",
      },
      {
        text: i18n.t('Actions'),
        value: "actions",
        sortable: false
      },
    ],
    companyRolesHeaders: [
      {
        text: i18n.t('Company'),
        align: "start",
        sortable: true,
        filterable: true,
        value: "company.business_name",
      },
      {
        text: i18n.t('Role'),
        align: "start",
        sortable: true,
        filterable: true,
        value: "role.name",
      },
      {
        text: i18n.t('Actions'),
        value: "actions",
        sortable: false
      }
    ],
    perimeterRolesHeaders: [
      {
        text: i18n.t('Perimeter'),
        align: "start",
        sortable: true,
        filterable: true,
        value: "perimeter.name",
      },
      {
        text: i18n.t('Role'),
        align: "start",
        sortable: true,
        filterable: true,
        value: "role.name",
      },
      {
        text: i18n.t('Actions'),
        value: "actions",
        sortable: false
      }
    ],
    edition: false,
    uploading: false,
    userRoles: [],
    selectedCompanyRole: null,
    selectedCompany: null,
    companyRoles: [],
    selectedPerimeterRole: null,
    selectedPerimeter: null,
    perimeterRoles: [],
  }),

  mounted() {
    this.userRoles = []
    this.localUser = JSON.parse(JSON.stringify(this.user))
    this.refreshUserRoles()
  },

  methods: {
    async refreshUserRoles() {
      const refreshedUser = await this.getUserRoles(this.user.uuid)
      let index = 0
      this.userRoles = []
      this.edition = false

      refreshedUser.accessControl.perimetersRoles.forEach((item) => {
        index++
        const role = {
          key: index,
          id: item.role.id,
          name: item.role.name,
          slug: item.role.slug,
          perimeter: item.perimeter.name,
          perimeterId: item.perimeter.id,
          typeStr: i18n.t('Perimeter role'),
          type: "perimeter",
        }
        this.userRoles.push(role)
      })

      refreshedUser.accessControl.directRoles.forEach((item) => {
        index++
        const role = {
          key: index,
          id: item.role.id,
          name: item.role.name,
          slug: item.role.slug,
          company: item.company.business_name,
          company_uuid: item.company.uuid,
          typeStr: i18n.t('Direct role'),
          type: "direct",
        }

        this.userRoles.push(role)
      })
    },

    closeEdition() {
      this.edition = false
    },

    addRole() {
      this.edition = true
    },

    close() {
      this.$emit("close")
    },

    async deleteRole(role) {
      if (role.type === "perimeter") {
        await this.deleteUserRolesInPerimeter(
          [role.id],
          [],
          role.perimeterId,
          this.user.uuid
        )
        await this.refreshUserRoles()
      } else if (role.type === "direct") {
        await this.deleteUserRolesInCompany(
          [role.id],
          [],
          role.company_uuid,
          this.user.uuid
        )
        await this.refreshUserRoles()
      }
    },

    addCompanyRole() {
      const companyRole = {
        key: _.random(0, 999999),
        role: this.selectedCompanyRole,
        company: this.selectedCompany,
        user: this.user.uuid,
      }
      this.companyRoles.push(companyRole)
    },

    deletePerimeterRole(role) {
      this.perimeterRoles = this.perimeterRoles.filter(
        (item) => item.key !== role.key
      )
    },

    addPerimeterRole() {
      if (!this.selectedPerimeterRole || !this.selectedPerimeter) {
        return
      }

      const perimeterRole = {
        key: _.random(0, 999999),
        role: this.selectedPerimeterRole,
        perimeter: this.selectedPerimeter,
        user: this.user.uuid,
      }

      this.perimeterRoles.push(perimeterRole)
    },

    async validateCompanyRoles() {
      if (this.companyRoles.length <= 0) {
        return
      }

      this.uploading = true
      let map = {}

      this.companyRoles.forEach((item) => {
        if (!map[item.company.uuid]) {
          map[item.company.uuid] = {
            companyUuid: item.company.uuid,
            roleIds: [],
          }
        }
        map[item.company.uuid]["roleIds"].push(item.role.id)
      })

      for (const [key, value] of Object.entries(map)) {
        await this.addUserRolesInCompany(
          value.roleIds,
          [],
          value.companyUuid,
          this.user.uuid
        )
      }

      await this.refreshUserRoles()

      this.uploading = false
      this.companyRoles = []
      this.selectedCompanyRole = null
      this.selectedCompany = null
    },

    async validatePerimeterRoles() {
      if (this.perimeterRoles.length <= 0) {
        return
      }

      this.uploading = true
      let map = {}

      this.perimeterRoles.forEach((item) => {
        if (!map[item.perimeter.id]) {
          map[item.perimeter.id] = {
            perimeterId: item.perimeter.id,
            roleIds: [],
          }
        }
        map[item.perimeter.id]["roleIds"].push(item.role.id)
      })

      for (const [key, value] of Object.entries(map)) {
        await this.addUserRolesInPerimeter(
          value.roleIds,
          [],
          value.perimeterId,
          this.user.uuid
        )
      }

      await this.refreshUserRoles()

      this.uploading = false
      this.perimeterRoles = []
      this.selectedPerimeterRole = null
      this.selectedPerimeter = null
    },
  },
}
</script>

<style scoped>
.box {
  border: 1px solid #ccc;
  padding: 10px;
  margin-bottom: 10px;
  background-color: white;
}

.subbox {
  padding: 10px;
  width: 100%;
}
</style>
