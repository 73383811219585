import AccessControlLogic from "@/logics/AccessControlLogic"
import utilsMixin from "./utils.mixin"

export default {
  mixins: [utilsMixin],
  methods: {
    getAllPerimeters() {
      return this._withLoading(() => AccessControlLogic.getAllPerimeters())
    },

    savePerimeter(perimeter) {
      return this._withLoading(() =>
        AccessControlLogic.savePerimeter(perimeter)
      )
    },

    getAllRoles() {
      return this._withLoading(() => AccessControlLogic.getAllRoles())
    },

    getAllUsersWithPagination(params) {
      return this._withLoading(() =>
        AccessControlLogic.getAllUsersRolesWithPagination(params)
      )
    },

    getUserRoles(uuid) {
      return this._withLoading(() => AccessControlLogic.getUserRoles(uuid))
    },

    addUserRolesInPerimeter(roleIds, roleSlugs, perimeterId, uuid) {
      return this._withLoading(() =>
        AccessControlLogic.addUserRolesInPerimeter(
          roleIds,
          roleSlugs,
          perimeterId,
          uuid
        )
      )
    },

    deleteUserRolesInPerimeter(roleIds, roleSlugs, perimeterId, uuid) {
      return this._withLoading(() =>
        AccessControlLogic.deleteUserRolesInPerimeter(
          roleIds,
          roleSlugs,
          perimeterId,
          uuid
        )
      )
    },

    addUserRolesInCompany(roleIds, roleSlugs, companyUuid, uuid) {
      return this._withLoading(() =>
        AccessControlLogic.addUserRolesInCompany(
          roleIds,
          roleSlugs,
          companyUuid,
          uuid
        )
      )
    },

    deleteUserRolesInCompany(roleIds, roleSlugs, companyUuid, uuid) {
      return this._withLoading(() =>
        AccessControlLogic.deleteUserRolesInCompany(
          roleIds,
          roleSlugs,
          companyUuid,
          uuid
        )
      )
    },
  },
}
