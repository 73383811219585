<template>
  <BarChart
    :chart-options="chartOptions"
    :chart-data="chartData"
    :height="height"
  />
</template>
<script>
import { Bar as BarChart } from "vue-chartjs/legacy"
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  LineElement,
  LinearScale,
  CategoryScale,
  PointElement,
  Filler,
  BarElement
} from "chart.js"
import "chartjs-adapter-luxon"
// import { titleOptions } from "../ChartOptions"

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  LineElement,
  LinearScale,
  CategoryScale,
  PointElement,
  Filler,
  BarElement
)

export default {
  name: "consumption-barChart",
  components: { BarChart },
  props: {
    chartData: {
      type: Object,
      required: true,
    },
    height: {
      type: Number,
      default() {
        return 400
      },
    },
    chartTitle: { 
      type: String,
      default: ''
    },
  },
  data() {
    return {
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        pointLabelFontSize: 5,
        scales: {
          x: {
            grid: {
              display: false,
            },
          },
          y: {
            grid: {
              display: false,
            },
            ticks: {
              stepSize: 1,
            },
            min: 0,
          },
        },
        plugins: {
          filler: {
            propagate: false,
          },
          legend: {
            display: true,
            title: {
              display: true,
              text: this.chartTitle,
              font: {
                size: 24,
                weight: "bold",
              },
              color: "black"
            },
            labels: {
              generateLabels: function (chart) {
                return ""
              },
              textAlign: "right",
              usePointStyle: true,
              pointStyleWidth: 10,
              boxHeight: 7.5,
            },
          },
          // tooltip: {
          //   callbacks: {
          //     label: (context) =>
          //       // this.$t("courses.charts.weeklySessionsTooltip", {
          //       //   label: context.dataset.label,
          //       //   week: context.label,
          //       //   value: context.parsed.y,
          //           // }),
          //       context.dataset.label,
          //     title: () => null,
          //     beforeBody: () => null,
          //     afterBody: () => null,
          //   },
          // },
        },
      },
    }
  },
}
</script>
