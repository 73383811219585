<!-- ProgramPresetEditor.vue -->
<template>
  <div v-if="isDataLoaded">
    <program-form
      v-model="localProgram"
      :groups="groups"
      :hatCourses="hatCourses"
      :exercises="exercises"
      :emailTypes="emailTypes"
      ref="programForm"
    ></program-form>

    <div class="d-flex justify-end mt-2">
      <v-btn
        elevation="2"
        @click="instantiate()"
        dark
        color="green"
        small
        class="me-2"
      >{{ $t("view.button.validate") }}
      </v-btn>
      <v-btn elevation="2" small @click="close()">{{ $t("view.button.close") }}</v-btn>
    </div>
  </div>
</template>

<script>
import _ from "lodash"

import ProgramForm from "./ProgramForm.vue"

export default {
  name: "program-preset-editor",
  components: {
    ProgramForm,
  },
  props: ["preset", "groups", "hatCourses", "exercises", "emailTypes"],
  data: () => ({
    localProgram: null,
    localPreset: null,
    loaded: false,
    campaigns: [],
    tab: null,
  }),
  async mounted() {
    this.localPreset = _.clone(this.preset)
    this.localProgram = _.clone(this.preset)
  },
  methods: {
    close() {
      this.$emit("close")
    },
    instantiate() {
      // Access the child components and retrieve the form data
      const programFormData = this.$refs.programForm.getLocalData()

      if (!this.validateProgram(programFormData)) {
        console.error("Program is not valid")
        return
      }

      this.$emit("save", programFormData)
    },
    validateProgram(program) {
      if (program.configuration.employee_groups_ids.length <= 0) {
        //showSnackbar("Veuillez sélectionner au moins un groupe d'employés")
        //return false
      }

      return true
    },
  },
  computed: {
    isDataLoaded() {
      //TODO display an info if we are missing data (like groups)
      return (
        this.localProgram &&
        this.preset &&
        this.groups &&
        this.hatCourses &&
        this.exercises &&
        this.emailTypes
      )
    },
  },
}
</script>
<style lang="scss">
.v-expansion-panel {
  border: none;
  background-color: transparent !important;
}

.v-expansion-panel::before {
  box-shadow: none;
}
</style>
