import Request from "../services/Request"
import { API_VERSION } from "./ApiVersion"

export default class HatCourseCatalogRepository {
  //----------------------- Catalog CRUD -----------------------

  static getAllCatalogs() {
    return Request.make("GET", `/${API_VERSION}/hat-course-catalogs`)
  }

  static createCatalog(body = {}) {
    return Request.make("POST", `/${API_VERSION}/hat-course-catalogs`, body)
  }

  static updateCatalog(catalogId, body = {}) {
    return Request.make(
      "PATCH",
      `/${API_VERSION}/hat-course-catalogs/${catalogId}`,
      body
    )
  }

  static deleteCatalog(catalogId) {
    return Request.make(
      "DELETE",
      `/${API_VERSION}/hat-course-catalogs/${catalogId}`
    )
  }

  //----------------------- Courses in Catalog -----------------------

  static getCatalogCourses(catalogId) {
    return Request.make(
      "GET",
      `/${API_VERSION}/hat-course-catalogs/${catalogId}/courses`
    )
  }

  static addHatCourseToCatalog(catalogId, courseId) {
    return Request.make(
      "POST",
      `/${API_VERSION}/hat-course-catalogs/${catalogId}/courses/${courseId}`
    )
  }

  static removeHatCourseFromCatalog(catalogId, courseId) {
    return Request.make(
      "DELETE",
      `/${API_VERSION}/hat-course-catalogs/${catalogId}/courses/${courseId}`
    )
  }

  //----------------------- Company related to Catalog -----------------------

  static getCompanyCatalogs(companyUuid) {
    return Request.make(
      "GET",
      `/${API_VERSION}/hat-course-catalogs/companies/${companyUuid}`
    )
  }

  static addCompanyToCatalog(companyUuid, catalogId) {
    return Request.make(
      "POST",
      `/${API_VERSION}/hat-course-catalogs/companies/${companyUuid}/${catalogId}`
    )
  }

  static removeCompanyFromCatalog(companyUuid, catalogId) {
    return Request.make(
      "DELETE",
      `/${API_VERSION}/hat-course-catalogs/companies/${companyUuid}/${catalogId}`
    )
  }
}
