import { i18n } from "@/plugins/i18n"

export default {
  data: () => ({
    search: "",
    footerProps: {
      "items-per-page-options": [10, 30, 50, 100, 200, -1],
    },
    headers: [
      {
        text: "ID",
        align: "start",
        sortable: true,
        filterable: true,
        value: "id",
      },
      {
        text: i18n.t('view.learning_phishing.title') ,
        align: "start",
        sortable: true,
        filterable: true,
        value: "title",
      },
      {
        text: i18n.t('view.learning_phishing.description') ,
        align: "start",
        sortable: true,
        filterable: true,
        value: "description",
      },
    ],
  }),
}
