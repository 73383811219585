import moment from "moment"

export default {
  data: () => ({
    loading: false,
    latestError: null,
    latestMessage: null,
  }),
  methods: {
    formatDate(str) {
      return moment(str).format("MMMM D, YYYY h:mm")
    },
    async _withLoading(fn) {
      if (this.loading) {
        console.error("Still loading..")
        return
      }
      this.loading = true
      this.latestError = null
      this.latestMessage = null

      try {
        return await fn()
      } catch (error) {
        if (
          error.response &&
          error.response.data &&
          error.response.data.error
        ) {
          this.latestError = error.response.data.error
        }
      } finally {
        this.loading = false
      }
    },
  },
}
