import AdminLogic from "../logics/AdminLogic"
import CompanyLogic from "../logics/CompanyLogic"
import utilsMixin from "./utils.mixin"

export default {
  mixins: [utilsMixin],
  data: () => ({
    users: null,
  }),
  methods: {
    getAllUsers() {
      return this._withLoading(() => AdminLogic.getAllUsers())
    },

    getCompanyUsers(companyUuid, selectedRoles = null) {
      return this._withLoading(() =>
        CompanyLogic.getCompanyUsers(companyUuid, selectedRoles)
      )
    },

    getEmployeeHierarchy(companyUuid, userUuid) {
      return this._withLoading(() =>
        CompanyLogic.getEmployeeHierarchy(companyUuid, userUuid)
      )
    },

    getUserCompanyRoles(companyUuid, userUuid) {
      return this._withLoading(() =>
        CompanyLogic.getUserCompanyRoles(companyUuid, userUuid)
      )
    },

    getUserPerimeters(user, roleSlug = null) {
      return this._withLoading(() =>
        AdminLogic.getUserPerimeters(user.uuid, roleSlug)
      )
    },
  },
}
