<script>
import CompanyLogic from "@/logics/CompanyLogic"

export default {
  name: "company-sso-edition",
  props: {
    data: {
      default: null,
    },
  },
  data() {
    return {
      loadingSaveConfigurationAzureApi: false,
      loadingSaveConfigurationAzureLearning: false,
      companyData: null,
      apiSSO:{
        url_acs: "",
        url_metadata: "",
        url_for_application_federation_metadata: "",
        message: "",
      },
      learningSSO:{
        url_acs: "",
        url_metadata: "",
        url_for_application_federation_metadata: "",
        message: "",
      },
    }
  },

  async mounted() {
    this.companyData = JSON.parse(JSON.stringify(this.data))
    await this.init()
  },
  watch: {
    data(newValue, oldValue) {
    },
  },
  computed: {
    hasApiSSOValues() {
      return this.isValidUrl(this.apiSSO.url_acs);
    },
    hasLearningSSOValues() {
      return this.isValidUrl(this.learningSSO.url_acs);
    },
  },
  methods: {
    isValidUrl(url) {
      return !!url && url.trim() !== '';
    },
    async init() {

      if (this.companyData.employees_auth_method === "sso" && this.companyData.sso) {
        //this.apiSSO = this.companyData.sso.api;
        //this.learningSSO = this.companyData.sso.learning;
      }

      if (this.companyData.uuid) {
        await this.getConfigurationSsoAzure("api")
        await this.getConfigurationSsoAzure("learning")
      }
    },
    updateLocalData(data, type) {
      if("api" === type){
        this.apiSSO = data;
      }
      else if("learning" === type){
        this.learningSSO = data;
      }
    },

    async getConfigurationSsoAzure(type) {
      let data = await CompanyLogic.getConfigurationSsoAzure(this.companyData.uuid, type)
      this.updateLocalData(data, type)
    },
    async createConfigurationSsoAzure(type) {
      let data = await CompanyLogic.createConfigurationSsoAzure(
        this.companyData.uuid,
        { type: type },
      )
      this.updateLocalData(data, type)
    },

    async storeConfigurationSsoAzure(type) {
      const camelCaseType = type.charAt(0).toUpperCase() + type.slice(1)
      const propertyName = `loadingSaveConfigurationAzure${camelCaseType}`

      this[propertyName] = true

      const url = (type === "api") ? this.apiSSO.url_for_application_federation_metadata : this.learningSSO.url_for_application_federation_metadata;

      let data = await CompanyLogic.storeConfigurationSsoAzure(
        this.companyData.uuid,
        {
          type: type,
          url_for_application_federation_metadata: url
        },
      )

      this[propertyName] = false
    },

    async destroyConfigurationSsoAzure(type) {

      if("api" === type){
        this.apiSSO.url_metadata = ""
        this.apiSSO.url_acs = ""
        this.apiSSO.url_for_application_federation_metadata = ""
      }
      else if("learning" === type){
        this.learningSSO.url_metadata = ""
        this.learningSSO.url_acs = ""
        this.learningSSO.url_for_application_federation_metadata = ""
      }

      await CompanyLogic.destroyConfigurationSsoAzure(this.companyData.uuid, type)
    },

    async validate() {
      return true
    },
    getCompanyData() {

      return {
        sso: {
          api: this.apiSSO,
          learning: this.learningSSO,
        },
      }
    },
  },
}
</script>

<template>
  <div class="flex-container" v-if="companyData">
    <!-- Parameters SSO -->
    <div class="bloc text-center">
      <v-row>
        <v-col cols="12">
          <div class="bloc-title">
            {{ $t("profile.active_directory_credentials.azure_sso_client_parameters") | titlecase }}
          </div>
        </v-col>

        <v-col cols="12">
          <div v-if="hasApiSSOValues">
            <v-card-text class="px-3 pa-0">
              <op-text-field
                name="url_metadata"
                type="text"
                rules=""
                color="green"
                disabled=""
                v-model="apiSSO.url_metadata"
                :label="$t('profile.active_directory_credentials.identifier_to_be_inserted_azure')"
              >
              </op-text-field>
            </v-card-text>

            <v-card-text class="px-3 pa-0">
              <op-text-field
                name="url_acs"
                type="text"
                rules=""
                color="green"
                disabled=""
                v-model="apiSSO.url_acs"
                :label="$t('profile.active_directory_credentials.response_url_to_insert_azure')"
              >
              </op-text-field>
            </v-card-text>

            <v-card-text class="px-3 pa-0">
              <op-text-field
                name="url_for_application_federation_metadata"
                type="text"
                rules=""
                color="green"
                v-model="apiSSO.url_for_application_federation_metadata
                      "
                :label="
                        $t('profile.active_directory_credentials.url_for_application_federation_metadata')
                      "
              >
              </op-text-field>
            </v-card-text>

            <div class="px-3 pa-0">
              <v-btn
                elevation="2"
                @click="storeConfigurationSsoAzure('api')"
                dark
                color="green"
                class="text-none rounded-lg mr-3"
              >{{ $t("view.button.save_azure_SSO_parameters") }}
              </v-btn>

              <span class="ms-2" v-if="apiSSO.message">{{
                  this.apiSSO.message
                }}</span>

              <v-btn
                elevation="2"
                @click="destroyConfigurationSsoAzure('api')"
                dark
                color="red"
                class="float-right text-none rounded-lg mr-3"
              >
                <v-icon>mdi-delete</v-icon>
              </v-btn>

              <div
                v-if="loadingSaveConfigurationAzureApi"
                class="float-end"
              >
                <div class="loading-small"></div>
              </div>
            </div>
          </div>

          <div class="px-3 pa-0" v-else>
            <v-btn
              elevation="2"
              @click="createConfigurationSsoAzure('api')"
              dark
              color="green"
              class="text-none rounded-lg mr-3"
            >{{ $t("view.button.create_my_credentials") }}
            </v-btn>
          </div>
        </v-col>
      </v-row>
    </div>

    <div class="bloc text-center">
      <v-row>
        <v-col cols="12">
          <div class="bloc-title">
            {{ $t("profile.active_directory_credentials.azure_sso_learning_parameters") | titlecase }}
          </div>
        </v-col>

        <v-col cols="12">
          <div v-if="hasLearningSSOValues">

            <v-card-text class="px-3 pa-0">
              <op-text-field
                name="url_metadata"
                type="text"
                rules=""
                color="green"
                disabled=""
                v-model="learningSSO.url_metadata"
                :label="$t('profile.active_directory_credentials.identifier_to_be_inserted_azure')"
              >
              </op-text-field>
            </v-card-text>

            <v-card-text class="px-3 pa-0">
              <op-text-field
                name="url_acs"
                type="text"
                rules=""
                color="green"
                disabled=""
                v-model="learningSSO.url_acs"
                :label="$t('profile.active_directory_credentials.response_url_to_insert_azure')"
              >
              </op-text-field>
            </v-card-text>

            <v-card-text class="px-3 pa-0">
              <op-text-field
                name="url_for_application_federation_metadata"
                type="text"
                rules=""
                color="green"
                v-model="learningSSO.url_for_application_federation_metadata
                      "
                :label="
                        $t('profile.active_directory_credentials.url_for_application_federation_metadata')
                      "
              >
              </op-text-field>
            </v-card-text>

            <div class="px-3 pa-0">
              <v-btn
                elevation="2"
                @click="storeConfigurationSsoAzure('learning')"
                dark
                color="green"
                class="text-none rounded-lg mr-3"
              >
                {{ $t("view.button.save_azure_SSO_parameters") }}
              </v-btn>

              <span
                class="ms-2"
                v-if="learningSSO.message"
              >{{ this.learningSSO.message }}</span
              >

              <v-btn
                elevation="2"
                @click="destroyConfigurationSsoAzure('learning')"
                dark
                color="red"
                class="float-right text-none rounded-lg mr-3"
              >
                <v-icon>mdi-delete</v-icon>
              </v-btn>

              <div
                v-if="loadingSaveConfigurationAzureLearning"
                class="float-end"
              >
                <div class="loading-small"></div>
              </div>
            </div>
          </div>

          <div class="px-3 pa-0" v-else>
            <v-btn
              elevation="2"
              @click="createConfigurationSsoAzure('learning')"
              dark
              color="green"
              class="text-none rounded-lg mr-3"
            >
              {{ $t("view.button.create_my_credentials") }}
            </v-btn>
          </div>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<style scoped>
.flex-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}


.bloc {
  border: 1px solid #efefef;
  border-radius: 5px;
  flex: 1 1 auto;
  width: 250px;
  padding: 20px;
  margin: 10px;

  .bloc-title {
    font-weight: bold;
    text-align: center;
    font-size: 1.5em;
    color: #2a2929;
  }
}

.loading-small {
  width: 30px;
  height: 30px;
  border: 4px solid #f3f3f3;
  border-top: 4px solid #1cc8aa;
  border-radius: 50%;
  animation: spin 1s linear infinite;
  margin-right: 15px;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

</style>
