<template>
  <div class="mainbox">
    <div class="cyberscore" @mouseenter="showDetails" @mouseleave="hideDetails">
      <div class="details-icon">
        <v-icon elevation="2" icon>mdi-google-analytics</v-icon>
      </div>

      <div v-if="showBreakdown && showDetailsFlag" class="overlay">
        <v-card outlined width="100%" height="100%" rounded="lg" class="pa-3">
          <v-row v-if="company?.gamification">
            <v-col cols="12" v-for="theme in themes" :key="theme.code">
              <op-cyberscore-by-theme
                :theme="theme.code"
                :score="company.gamification.thematic_scores[theme.code]"
                :icons="theme.icons"
              />
            </v-col>
          </v-row>
        </v-card>
      </div>
    </div>
  </div>
</template>

<script>
import PhishingIcon300 from "@/assets/learning/badges/badge-phishing-300px.png"
import PhishingIcon100 from "@/assets/learning/badges/badge-phishing-100px.png"
import DataProtectionIcon300 from "@/assets/learning/badges/badge-data-protection-300px.png"
import DataProtectionIcon100 from "@/assets/learning/badges/badge-data-protection-100px.png"
import WorkstationIcon300 from "@/assets/learning/badges/badge-workstation-300px.png"
import WorkstationIcon100 from "@/assets/learning/badges/badge-workstation-100px.png"
import OutOfOfficeIcon300 from "@/assets/learning/badges/badge-out-of-office-300px.png"
import OutOfOfficeIcon100 from "@/assets/learning/badges/badge-out-of-office-100px.png"
import CommunicationToolsIcon300 from "@/assets/learning/badges/badge-communication-tools-300px.png"
import CommunicationToolsIcon100 from "@/assets/learning/badges/badge-communication-tools-100px.png"
import SocialEngineeringIcon300 from "@/assets/learning/badges/badge-social-engineering-300px.png"
import SocialEngineeringIcon100 from "@/assets/learning/badges/badge-social-engineering-100px.png"

export default {
  name: "op-company-cyberscore-bubble",
  props: {
    company: {
      type: Object,
      required: true,
    },
    showBreakdown: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      showDetailsFlag: false,
      themes: [
        {
          code: "phishing",
          icons: {px100: PhishingIcon100, px300: PhishingIcon300},
        },
        {
          code: "out-of-office",
          icons: {px100: OutOfOfficeIcon100, px300: OutOfOfficeIcon300},
        },
        {
          code: "data-protection",
          icons: {px100: DataProtectionIcon100, px300: DataProtectionIcon300},
        },
        {
          code: "communication-tools",
          icons: {
            px100: CommunicationToolsIcon100,
            px300: CommunicationToolsIcon300,
          },
        },
        {
          code: "workstation",
          icons: {px100: WorkstationIcon100, px300: WorkstationIcon300},
        },
        {
          code: "social-engineering",
          icons: {
            px100: SocialEngineeringIcon100,
            px300: SocialEngineeringIcon300,
          },
        },
      ],
    }
  },
  methods: {
    showDetails() {
      this.showDetailsFlag = true
    },
    hideDetails() {
      //this.showDetailsFlag = false
      this.hideTimeout = setTimeout(() => {
        this.showDetailsFlag = false
      }, 200) // Adjust the delay time as needed (in milliseconds)
    },
  },
}
</script>

<style scoped lang="scss">
.mainbox {
  width: 50px;
  border: 1px dashed #cfcfcf;
  text-align: center;
  border-radius: 10px;
}

.cyberscore {
  cursor: pointer;
  position: relative;
  width: 100%;
  text-align: center;

  .details-icon {
    font-size: 25px;
    cursor: pointer;
  }
}

.overlay {
  position: absolute;
  border-radius: 10px;
  top: 0;
  left: 0;
  height: auto;
  z-index: 3;
  justify-content: center;
  align-items: center;
  font-family: monospace !important;
  font-size: 12px;
}
</style>
