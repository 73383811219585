<template>
  <v-app class="mx-auto app">
    <!--  mini-variant expand-on-hover-->
    <v-navigation-drawer app permanent>

      <!-- Oppens Logo -->
      <v-img
        src="@/assets/logo_oppens _rvb.png"
        max-height="100"
        max-width="66%"
        contain
        class="ml-5 my-4"
      ></v-img>

      <!-- Sidebar content -->
      <v-list nav dense>
        <div v-for="(link, i) in links" :key="i">
          <v-list-item
            v-if="!link.subLinks"
            :to="link.route"
            :active-class="color"
            class="v-list-item"
          >
            <v-list-item-icon>
              <v-icon>{{ link.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-title>{{ link.title }}</v-list-item-title>
          </v-list-item>

          <v-list-group
            v-else
            :key="link.title"
            no-action
            :prepend-icon="link.icon"
            :value="false"
            class="black--text"
          >
            <template v-slot:activator>
              <v-list-item-title class="black--text"
                >{{ link.title }}
              </v-list-item-title>
            </template>

            <v-list-item
              v-for="sublink in link.subLinks"
              :to="sublink.route"
              :key="sublink.title"
              :active-class="color"
            >
              <v-list-item-title class="black--text"
                >{{ sublink.title }}
              </v-list-item-title>
            </v-list-item>
          </v-list-group>
        </div>
      </v-list>

      <v-menu
        offset-y
        offset-x
        transition="slide-x-transition"
        bottom
        right
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            icon
            x-small
            class="notification-bell"
            v-bind="attrs"
            v-on="on"
          >
            <v-badge
              color="red"
              v-if="unreadNotificationsCount > 0"
              :content="unreadNotificationsCount"
              overlap
            >
              <v-icon>mdi-bell</v-icon>
            </v-badge>

            <v-icon v-else>mdi-bell</v-icon>
          </v-btn>
        </template>

        <!-- Contenu du menu des notifications -->
        <v-list class="fixed-notification-list" v-if="notifications.length>0">
          <v-subheader class="text-h5 text-center font-weight-bold my-3" block>Notifications</v-subheader>
          <v-list-item
            v-for="notification in notifications"
            :key="notification.id"
          >
            <v-list-item-icon>
              <v-avatar :class="getNotificationClass(notification?.data?.type)" size="36">
                <v-icon v-if="notification?.data?.icon">{{notification?.data?.icon}}</v-icon>
                <v-icon v-else>mdi-information-variant</v-icon>
              </v-avatar>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>{{ notification?.data?.company }}</v-list-item-title>
              <v-list-item-subtitle
                class="multi-line-message"
                v-if="notification.data.payload"
              >{{ notification?.data?.payload.message }}</v-list-item-subtitle>
            </v-list-item-content>

            <v-list-item-action>
              <v-btn icon @click="markNotificationAsRead(notification)">
                <v-icon>mdi-check</v-icon>
              </v-btn>
            </v-list-item-action>
          </v-list-item>
          <v-list-item>
            <v-btn @click="markAllAsRead" class="no-uppercase" block>
              Tout lire
            </v-btn>
          </v-list-item>
        </v-list>
      </v-menu>

    </v-navigation-drawer>
      <v-main>
      <transition name="fade">
        <router-view :permissions="roles"></router-view>
      </transition>
    </v-main>

    <v-footer :padless="padless">
      <v-card flat tile width="100%" class="grey lighten-1 text-center">
        <v-divider></v-divider>
        <v-card-text class="black--text">
          {{ new Date().getFullYear() }} — <strong>Oppens Admin</strong>
        </v-card-text>
      </v-card>
    </v-footer>
  </v-app>
</template>

<script>
import AuthStore from "@/stores/AuthStore"
import UserLogic from "@/logics/UserLogic"

export default {
  data: () => ({
    model: null,
    drawer: true,
    mini: false,
    color: "#ccc",
    items: ["default", "absolute", "fixed"],
    padless: false,
    variant: "default",
    roles: null,
    isSystemAdmin: false,
    isLearningSystemAdmin: false,
    isPhishingSystemAdmin: false,
    isAccessControlSystemAdmin: false,
    notificationsMenu: false,
    notifications: [], // Liste des notifications
    unreadNotificationsCount: 0, // Nombre de notifications non lues
  }),

  methods: {
    showNotifications() {
      console.log("showNotifications")

      if(this.notifications.length == 0){
        this.notificationsMenu = false;
      }else{
        this.notificationsMenu = !this.notificationsMenu;
      }
    },
    async markNotificationAsRead(notification) {
      await UserLogic.markNotificationAsRead(notification.id);
      await this.fetchNotifications();
    },
    async fetchNotifications() {
      const response = await UserLogic.getNotifications(true);
      if(response && response.length >= 0){
        this.notifications = response;
        this.unreadNotificationsCount = response.length;
      }
      return null
    },
    async markAllAsRead() {
      await UserLogic.markAllAsRead();
      await this.fetchNotifications();
    },
    getNotificationClass(type) {
      switch (type) {
        case 'error':
          return 'notification-error';
        case 'success':
          return 'notification-success';
        default:
          return 'notification-default';
      }
    },
  },

  async mounted() {
    this.isSystemAdmin = await AuthStore.hasSystemRole("system-admin")
    this.isLearningSystemAdmin = await AuthStore.hasSystemRole("learning-system-admin")
    this.isPhishingSystemAdmin = await AuthStore.hasSystemRole("phishing-system-admin")
    this.isAccessControlSystemAdmin = await AuthStore.hasSystemRole("access-control-system-admin")

    await this.fetchNotifications();
    setInterval(this.fetchNotifications, 60000); // Toutes les 60 secondes
  },

  computed: {
    user() {
      return this.$authStore.state.me
    },

    links() {
      const links = [
        {
          title: this.$i18n.t("navigation.dashboard"),
          icon: "mdi-home-outline",
          route: {
            name: "dashboard",
          },
        },

        {
          title: this.$i18n.t("navigation.companies"),
          icon: "mdi-domain",
          route: {
            name: "companies",
          },
        },
        {
          title: this.$i18n.t("navigation.users"),
          icon: "mdi-account-group",
          route: {
            name: "users",
          },
        },

        {
          title: this.$i18n.t("navigation.access-control"),
          icon: "mdi-security",
          canView: this.isAccessControlSystemAdmin,
          route: {
            name: "access-control-perimeters",
          },
        },
        {
          title: this.$i18n.t("navigation.news"),
          icon: "mdi-newspaper",
          canView: this.isLearningSystemAdmin,
          route: {
            name: "news",
          },
        },
        {
          title: this.$i18n.t("navigation.learning.learning"),
          icon: "mdi-desktop-classic",
          canView: this.isLearningSystemAdmin,
          subLinks: [
            {
              title: this.$i18n.t("navigation.learning.catalogs"),
              route: {
                name: "hat-course-catalogs",
              },
            },
            {
              title: this.$i18n.t("navigation.learning.assets"),
              route: {
                name: "oppens-learning-assets",
              },
            },
            {
              title: this.$i18n.t("navigation.learning.hatCourses"),
              route: {
                name: "oppens-learning-hat-courses",
              },
            },
            {
              title: this.$i18n.t("navigation.learning.courses"),
              route: {
                name: "oppens-learning-courses",
              },
            },
            {
              title: this.$i18n.t("navigation.learning.answers"),
              route: {
                name: "oppens-learning-answers",
              },
            },
            {
              title: this.$i18n.t("navigation.learning.slides"),
              route: {
                name: "oppens-learning-slides",
              },
            },
          ],
        },
        {
          title: this.$i18n.t("navigation.phishing.phishing"),
          icon: "mdi-shield-half-full",
          canView: this.isPhishingSystemAdmin,
          subLinks: [
            {
              title: this.$i18n.t("navigation.phishing.catalogs"),
              route: {
                name: "phishing-exercise-catalogs",
              },
            },
            {
              title: this.$i18n.t("navigation.phishing.assets"),
              route: {
                name: "phishing-assets",
              },
            },
            {
              title: this.$i18n.t("navigation.phishing.exercises"),
              route: {
                name: "phishing-exercises",
              },
            },
            {
              title: this.$i18n.t("navigation.phishing.emailTemplates"),
              route: {
                name: "phishing-email-templates",
              },
            },

            {
              title: this.$i18n.t("navigation.phishing.landingPages"),
              route: {
                name: "phishing-landing-pages",
              },
            },
            {
              title: this.$i18n.t("navigation.phishing.servers"),
              route: {
                name: "phishing-servers",
              },
            },
            {
              title: this.$i18n.t("navigation.phishing.sendingProfiles"),
              route: {
                name: "phishing-sending-profiles",
              },
            },
          ],
        },
        {
          title: this.$i18n.t("navigation.system.system"),
          icon: "mdi-chart-bar-stacked",
          canView: this.isSystemAdmin,
          subLinks: [
            {
              title: this.$i18n.t("navigation.system.monitoring"),
              route: {
                name: "system-monitoring",
              },
            },

            {
              title: "Uptime servers",
              route: {
                name: "uptime-servers",
              },
            },

            {
              title: this.$i18n.t("navigation.system.notifications"),
              route: {
                name: "system-notifications-templates",
              },
            },
            {
              title: this.$i18n.t("navigation.system.emailHeadersFooters"),
              route: {
                name: "system-headers-footers-templates",
              },
            },
            {
              title: this.$i18n.t("navigation.system.announcement"),
              route: {
                name: "system-announcement",
              },
            },
          ],
        },
        {
          title: "Consommation",
          icon: "mdi-star",
          route: {
            name: "oppens-consumption-credit",
          },
        },
        {
          title: this.$i18n.t("navigation.logout"),
          icon: "mdi-power",
          route: {
            name: "logout",
          },
        },
      ]

      return links.filter((link) => {
        if (Object.prototype.hasOwnProperty.call(link, "canView")) {
          return link.canView
        }
        // If the link doesn't have a canView property, always show it
        return true
      })
    },
  },
}
</script>

<style scoped>
::v-deep .v-list-item--active .mdi:before {
  color: #1cc8aa !important;
}

::v-deep .v-list-item--link:before {
  background-color: transparent;
}
</style>

<style>

.app {
  height: 100%;
  width: 100%;
}

.container {
  background: rgb(243 243 243);
}

.page-title {
  font-size: 0.9rem;
  color: #505050;
  padding-left: 10px;
  margin-top: 10px;
  margin-bottom: 30px;
}

.required label::after {
  content: "*";
  color: red;
}
</style>
<style scoped>

.notification-bell {
  position: absolute;
  top: 25px;
  right: 25px;
}

.notification-menu {
  position: absolute;
  top: 60px;
  right: 25px;
}

.no-uppercase {
  text-transform: none; /* Désactive la mise en majuscule */
}

.notification-error {
  background-color: #f8d7da;
  color: #721c24;
}

.notification-success {
  background-color: #d4edda;
  color: #155724;
}

.notification-default {
  background-color: #f8f9fa;
  color: #6c757d;
}

.text-h5 {
  font-size: 1.5rem !important;
}

.text-center {
  text-align: center !important;
}

.fixed-notification-list {
  max-height: 300px;
  max-width: 400px;
  overflow-y: auto;
  min-height: 200px;
}

.multi-line-message {
  white-space: normal;
  word-wrap: break-word;
}

</style>
