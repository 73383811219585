<template>
  <v-container v-if="commands">
    <v-row>
      <v-col
        cols="12"
        md="6"
        lg="4"
        v-for="command in commands"
        :key="command.command"
      >
        <v-card class="mb-2" color="green" dark>
          <v-card-title>
            <div class="command-title">
              <span class="title">{{ command.command }}</span>
              <span class="subtitle">{{ command.last_run }}</span>
            </div>
          </v-card-title>

          <v-card-text>
            <div>{{ $t('Duration') }} (ms)</div>

            <v-sparkline
              :value="command.durations_ms_values"
              color="rgba(255, 255, 255, .7)"
              line-color="#1dc8aa"
              height="100"
              padding="24"
              stroke-linecap="round"
              smooth
            ></v-sparkline>

            <div class="mb-2">{{ $t('Memory Usage (MB)') }}</div>

            <v-sparkline
              :value="command.memory_usages_values"
              line-color="#00bfa5"
              height="100"
              padding="24"
              stroke-linecap="round"
              smooth
            ></v-sparkline>

            <div>{{ $t('Last Duration') }} : {{ command.last_duration_ms }} ms</div>
            <div>{{ $t('Max Duration') }} : {{ command.max_duration_ms }} ms</div>
            <div>{{ $t('Average Duration') }}: {{ command.avg_duration_ms }} ms</div>
            <div>
              {{ $t('Average Memory Usage') }}: {{ command.avg_memory_usage_mb }} MB
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "op-batch-commands-stats",
  props: {
    stats: {
      type: Array,
      required: true,
    },
  },
  data: () => ({
    commands: null,
  }),

  mounted() {
    if (!this.stats) {
      return
    }

    this.commands = this.stats.map((stat) => ({
      command: stat.command,
      last_run: stat.last_run,
      avg_memory_usage_mb: stat.avg_memory_usage_mb,
      avg_duration_ms: stat.avg_duration_ms,
      max_duration_ms: stat.max_duration_ms,
      last_duration_ms: stat.last_duration_ms,
      memory_usages_values: Object.values(stat.timeline).map(
        (m) => m.memory_usage_mb
      ),
      memory_usages_labels: Object.keys(stat.timeline).reduce((acc, m) => {
        acc[m] = ""
        return acc
      }, {}),
      durations_ms_values: Object.values(stat.timeline).map(
        (m) => m.duration_ms
      ),
      durations_ms_labels: Object.keys(stat.timeline).reduce((acc, m) => {
        acc[m] = ""
        return acc
      }, {}),
    }))
  },
}
</script>
<style scoped lang="scss">
.command-title {
  min-height: 100px;
  width: 100%;
  text-align: center;

  .subtitle {
    font-size: 15px;
    color: rgb(73 73 73 / 70%);
    display: block;
    font-weight: bold;
    text-align: center;
    font-family: monospace;
  }
}
</style>
